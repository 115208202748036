/* eslint-disable react-refresh/only-export-components */
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, Auth } from "../utils/auth";

const AuthContext = createContext<Auth>(auth);

export const AuthProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const [loaded, setLoaded] = useState(false);

  // Initialize auth on component mount
  useEffect(() => {
    auth.init().then(() => setLoaded(true));
  }, []);

  // Set up token refresh interval
  useEffect(() => {
    const interval = setInterval(() => {
      if (auth.isAuthenticated) {
        auth.refreshAccessToken();
      }
    }, 5000); // Check every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider value={auth}>
      {loaded && children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): Auth => useContext(AuthContext);
