import React from "react";
import { Title, TitleProps, useMantineTheme } from "@mantine/core";

interface StyledSectionTitleProps extends Omit<TitleProps, "children"> {
  children: React.ReactNode;
}

const StyledSectionTitle: React.FC<StyledSectionTitleProps> = ({
  children,
  order = 3,
  mb = "lg",
  ...props
}) => {
  const theme = useMantineTheme();

  return (
    <Title
      order={order}
      mb={mb}
      style={{
        color: theme.colors.tsgBlue[7],
        borderBottom: `2px solid ${theme.colors.tsgBlue[3]}`,
        paddingBottom: 10,
      }}
      {...props}
    >
      {children}
    </Title>
  );
};

export default StyledSectionTitle;
