import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Paper,
  Text,
  SimpleGrid,
  Group,
  Button,
  Pagination,
  Table,
  rem,
  Anchor,
} from "@mantine/core";
import StyledPageTitle from "../components/StyledPageTitle";
import StyledSectionTitle from "../components/StyledSectionTitle";
import { IconEdit, IconPlus } from "@tabler/icons-react";

import { JobFilter, JobFragment, useGetCustomerQuery } from "../graphql";
import DateDisplay from "../components/DateDisplay";
import TableHeaderCell from "../components/TableHeaderCell";
import useListVariables from "../hooks/useListVariables";
import FinishJobsButton from "../components/FinishJobsButton";
import DeleteButton from "../components/DeleteButton";
import { usePermissions } from "../contexts/PermissionsContext";

const CustomersDetailsPage: React.FC = () => {
  const permissions = usePermissions();
  const listVariables = useListVariables<JobFilter>({
    defaultSort: {
      field: "createdAt",
      order: "DESC",
    },
  });
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const getCustomer = useGetCustomerQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: `${params.id}`,
      filter: listVariables.filter,
      sort: listVariables.sort,
      pagination: listVariables.pagination,
    },
  });

  const customer = getCustomer.data?.getCustomer;
  const jobs = customer?.jobs;

  const handleDuplicateJob = (job: JobFragment) => () => {
    const search = new URLSearchParams();
    job.customerId && search.set("customerId", job.customerId);
    job.name && search.set("name", job.name);
    job.stringName && search.set("stringName", job.stringName);
    job.racket && search.set("racket", job.racket);
    job.tension && search.set("tension", job.tension);
    job.gauge && search.set("gauge", job.gauge);
    navigate(`/app/jobs/create?${search.toString()}`);
  };

  return (
    <>
      <Group justify="space-between" mb="sm">
        <StyledPageTitle>Customer Details</StyledPageTitle>
        <Group>
          <Button
            rightSection={<IconEdit />}
            display={!permissions.can("updateCustomer") ? "hidden" : undefined}
            onClick={() => navigate(`/app/customers/${params.id}/update`)}
          >
            Update
          </Button>
          <DeleteButton
            id={params.id}
            onCompleted={() => navigate("/app/customers")}
            type="customer"
          />
        </Group>
      </Group>
      <Paper>
        <SimpleGrid cols={{ sm: 1, md: 2, lg: 3 }}>
          <Text>
            <strong>First Name:</strong> {customer?.firstName}
          </Text>
          <Text>
            <strong>Last Name:</strong> {customer?.lastName}
          </Text>
          <Text>
            <strong>Member Number:</strong> {customer?.memNumber}
          </Text>
          <Text>
            <strong>Email:</strong> {customer?.email}
          </Text>
          <Text>
            <strong>Cell Phone:</strong> {customer?.cellPhone}
          </Text>
          <Text>
            <strong>Home Phone:</strong> {customer?.homePhone}
          </Text>
          <Text>
            <strong>Work Phone:</strong> {customer?.workPhone}
          </Text>
          <Text>
            <strong>Address:</strong> {customer?.address}
          </Text>
          <Text>
            <strong>City:</strong> {customer?.city}
          </Text>
          <Text>
            <strong>Zip:</strong> {customer?.zip}
          </Text>
          <Text>
            <strong>Gender:</strong> {customer?.gender}
          </Text>
          <Text>
            <strong>Birthday:</strong>{" "}
            <DateDisplay value={customer?.dateOfBirth} />
          </Text>
          <Text>
            <strong>Updated At:</strong>{" "}
            <DateDisplay value={customer?.updatedAt} />
          </Text>
          <Text>
            <strong>Created At:</strong>{" "}
            <DateDisplay value={customer?.createdAt} />
          </Text>
        </SimpleGrid>
      </Paper>
      <Paper mt="md">
        <Group justify="space-between">
          <StyledSectionTitle>Jobs</StyledSectionTitle>
          <Button
            color="blue"
            rightSection={<IconPlus />}
            onClick={() => navigate(`/app/jobs/create?customerId=${params.id}`)}
          >
            Create Fresh Job
          </Button>
        </Group>

        <Table.ScrollContainer minWidth={500}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <TableHeaderCell
                  label="Name"
                  field="name"
                  vars={listVariables}
                />
                <TableHeaderCell
                  label="Status"
                  field="status"
                  vars={listVariables}
                />
                <TableHeaderCell
                  label="String"
                  field="stringName"
                  vars={listVariables}
                />
                <TableHeaderCell
                  label="Racket"
                  field="racket"
                  vars={listVariables}
                />
                <TableHeaderCell
                  label="Tension"
                  field="tension"
                  vars={listVariables}
                />
                <TableHeaderCell
                  label="Gauge"
                  field="gauge"
                  vars={listVariables}
                />
                <TableHeaderCell
                  label="Received On"
                  field="createdAt"
                  vars={listVariables}
                />
                <Table.Th>Finished By</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {jobs?.data.map((job) => (
                <Table.Tr key={job.id}>
                  <Table.Td>
                    <Anchor onClick={() => navigate(`/app/jobs/${job.id}`)}>
                      {job.name}
                    </Anchor>
                  </Table.Td>
                  <Table.Td>{job.status}</Table.Td>
                  <Table.Td>{job.stringName}</Table.Td>
                  <Table.Td>{job.racket}</Table.Td>
                  <Table.Td>{job.tension}</Table.Td>
                  <Table.Td>{job.gauge}</Table.Td>
                  <Table.Td>
                    <DateDisplay value={job.receivedAt || job.createdAt} />
                  </Table.Td>
                  <Table.Td>
                    {job.finishedBy?.firstName} {job.finishedBy?.lastName}
                    <FinishJobsButton
                      hidden={job.status === "FINISHED"}
                      size="xs"
                      jobIds={[job.id]}
                      onComplete={() => getCustomer.refetch()}
                    />
                  </Table.Td>
                  <Table.Td>
                    <Button
                      size="xs"
                      color="blue"
                      variant="subtle"
                      onClick={handleDuplicateJob(job)}
                    >
                      Duplicate Job
                    </Button>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
        <Group justify="space-evenly">
          <Pagination
            hidden={
              !jobs?.total || jobs?.total < listVariables.pagination.perPage
            }
            mt={rem(20)}
            value={listVariables.pagination.page}
            onChange={listVariables.setPage}
            total={(jobs?.total || 0) / listVariables.pagination.perPage}
          />
        </Group>
      </Paper>
    </>
  );
};

export default CustomersDetailsPage;
