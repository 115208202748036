import React from "react";
import {
  Paper,
  Text,
  SimpleGrid,
  TextInput,
  Button,
  PasswordInput,
  ColorInput,
  Checkbox,
  Stack,
  Divider,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  showSuccessNotification,
  showErrorNotification,
} from "../utils/showNotification";
import StyledPageTitle from "../components/StyledPageTitle";
import StyledSectionTitle from "../components/StyledSectionTitle";
import {
  useGetMeQuery,
  useUpdateEmployeeSettingsMutation,
  useChangeUserPasswordMutation,
  useUpdateUserNotificationPreferencesMutation,
} from "../graphql";

interface PersonalDetailsFormData {
  firstName: string;
  lastName: string;
  email: string;
  color: string;
}

interface PasswordFormData {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface NotificationPreferencesFormData {
  notificationTypesOptedOut: string[];
}

// Notification types with human-readable labels
const NOTIFICATION_TYPES = [
  {
    value: "job_created",
    label: "New Job Created",
    employeeRoles: ["ACCOUNT_ADMIN", "ACCOUNT_OWNER", "EMPLOYEE"],
  },
  {
    value: "job_finished",
    label: "Job Finished",
    employeeRoles: ["ACCOUNT_ADMIN", "ACCOUNT_OWNER", "EMPLOYEE"],
  },
  {
    value: "employee_clocked_in",
    label: "Employee Clocked In",
    employeeRoles: ["ACCOUNT_ADMIN", "ACCOUNT_OWNER"],
  },
  {
    value: "employee_clocked_out",
    label: "Employee Clocked Out",
    employeeRoles: ["ACCOUNT_ADMIN", "ACCOUNT_OWNER"],
  },
];

const UserProfilePage: React.FC = () => {
  const { data, loading, refetch } = useGetMeQuery();
  const employee = data?.me;

  const [updateEmployeeSettings, updateEmployeeSettingsResponse] =
    useUpdateEmployeeSettingsMutation({
      onCompleted: () => {
        showSuccessNotification("Profile updated successfully");
        refetch();
      },
      onError: (error) => {
        showErrorNotification(`Failed to update profile: ${error.message}`);
      },
    });

  const [changeUserPassword, changeUserPasswordResponse] =
    useChangeUserPasswordMutation({
      onCompleted: () => {
        showSuccessNotification("Password changed successfully");
        passwordForm.reset();
      },
      onError: (error) => {
        showErrorNotification(`Failed to change password: ${error.message}`);
      },
    });

  const [updateNotificationPreferences, updateNotificationPreferencesResponse] =
    useUpdateUserNotificationPreferencesMutation({
      onCompleted: () => {
        showSuccessNotification(
          "Notification preferences updated successfully"
        );
        refetch();
      },
      onError: (error) => {
        showErrorNotification(
          `Failed to update notification preferences: ${error.message}`
        );
      },
    });

  const personalDetailsForm = useForm<PersonalDetailsFormData>({
    initialValues: {
      firstName: employee?.firstName || "",
      lastName: employee?.lastName || "",
      email: employee?.email || "",
      color: employee?.color || "#2e2e2e",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const passwordForm = useForm<PasswordFormData>({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validate: {
      newPassword: (value) =>
        value.length < 8 ? "Password must be at least 8 characters" : null,
      confirmPassword: (value, values) =>
        value !== values.newPassword ? "Passwords do not match" : null,
    },
  });

  const notificationPreferencesForm = useForm<NotificationPreferencesFormData>({
    initialValues: {
      notificationTypesOptedOut:
        // @ts-expect-error object is not typed
        employee?.user?.attributes?.notification_types_opted_out || [],
    },
  });

  // Update notification preferences form when data is loaded
  React.useEffect(() => {
    // @ts-expect-error object is not typed
    if (employee?.user?.attributes?.notification_types_opted_out) {
      notificationPreferencesForm.setValues({
        notificationTypesOptedOut:
          // @ts-expect-error object is not typed
          employee.user.attributes.notification_types_opted_out,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  const handlePersonalDetailsSubmit = (values: PersonalDetailsFormData) => {
    if (!employee) return;

    console.log("Submitting profile update:", {
      id: employee.id,
      email: values.email,
      color: values.color,
      preferences: employee.preferences,
    });

    updateEmployeeSettings({
      variables: {
        input: {
          id: employee.id,
          email: values.email,
          color: values.color,
          preferences: employee.preferences,
        },
      },
    });
  };

  const handlePasswordSubmit = (values: PasswordFormData) => {
    console.log("Submitting password change");

    changeUserPassword({
      variables: {
        input: {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        },
      },
    });
  };

  const handleNotificationPreferencesSubmit = (
    values: NotificationPreferencesFormData
  ) => {
    updateNotificationPreferences({
      variables: {
        input: {
          notificationTypesOptedOut: values.notificationTypesOptedOut,
        },
      },
    });
  };

  const toggleNotificationType = (type: string) => {
    const currentOptedOut = [
      ...notificationPreferencesForm.values.notificationTypesOptedOut,
    ];
    const index = currentOptedOut.indexOf(type);

    if (index === -1) {
      // Add to opted out list
      currentOptedOut.push(type);
    } else {
      // Remove from opted out list
      currentOptedOut.splice(index, 1);
    }

    notificationPreferencesForm.setFieldValue(
      "notificationTypesOptedOut",
      currentOptedOut
    );
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <>
      <StyledPageTitle>User Profile</StyledPageTitle>

      <Paper withBorder shadow="md" p="xl" mb="xl" radius="md">
        <StyledSectionTitle>Personal Information</StyledSectionTitle>

        <form
          onSubmit={personalDetailsForm.onSubmit(handlePersonalDetailsSubmit)}
        >
          <SimpleGrid cols={2} mb="md">
            <TextInput
              label="First Name"
              placeholder="First Name"
              {...personalDetailsForm.getInputProps("firstName")}
              disabled
            />
            <TextInput
              label="Last Name"
              placeholder="Last Name"
              {...personalDetailsForm.getInputProps("lastName")}
              disabled
            />
            <TextInput
              label="Email"
              placeholder="Email"
              required
              {...personalDetailsForm.getInputProps("email")}
            />
            <ColorInput
              label="Profile Color"
              format="hex"
              withEyeDropper={false}
              disallowInput
              withPicker={false}
              swatches={[
                "#2e2e2e",
                "#868e96",
                "#fa5252",
                "#e64980",
                "#be4bdb",
                "#7950f2",
                "#4c6ef5",
                "#228be6",
                "#15aabf",
                "#12b886",
                "#40c057",
                "#82c91e",
                "#fab005",
                "#fd7e14",
              ]}
              {...personalDetailsForm.getInputProps("color")}
            />
          </SimpleGrid>

          {updateEmployeeSettingsResponse.error && (
            <Text color="red" mb="md">
              {updateEmployeeSettingsResponse.error.message}
            </Text>
          )}

          <Button
            mt="lg"
            type="submit"
            loading={updateEmployeeSettingsResponse.loading}
            color="tsgGreen.6"
          >
            Save Changes
          </Button>
        </form>
      </Paper>

      <Paper withBorder shadow="md" p="xl" mb="xl" radius="md">
        <StyledSectionTitle>Notification Preferences</StyledSectionTitle>
        <Text size="sm" color="dimmed" mb="md">
          Select which notifications you want to receive. By default, you will
          receive all notifications.
        </Text>

        <form
          onSubmit={notificationPreferencesForm.onSubmit(
            handleNotificationPreferencesSubmit
          )}
        >
          <Stack gap="xs">
            {NOTIFICATION_TYPES.filter((type) =>
              type.employeeRoles?.includes(employee?.role || "")
            ).map((type) => (
              <Checkbox
                key={type.value}
                label={type.label}
                checked={
                  !notificationPreferencesForm.values.notificationTypesOptedOut.includes(
                    type.value
                  )
                }
                onChange={() => toggleNotificationType(type.value)}
                mb="xs"
              />
            ))}
          </Stack>

          <Divider my="md" />

          {updateNotificationPreferencesResponse.error && (
            <Text color="red" mb="md">
              {updateNotificationPreferencesResponse.error.message}
            </Text>
          )}

          <Button
            mt="lg"
            type="submit"
            loading={updateNotificationPreferencesResponse.loading}
            color="tsgGreen.6"
          >
            Save Notification Preferences
          </Button>
        </form>
      </Paper>

      <Paper withBorder shadow="md" p="xl" radius="md">
        <StyledSectionTitle>Change Password</StyledSectionTitle>

        <form onSubmit={passwordForm.onSubmit(handlePasswordSubmit)}>
          <SimpleGrid>
            <PasswordInput
              label="Current Password"
              placeholder="Enter your current password"
              required
              {...passwordForm.getInputProps("currentPassword")}
            />
            <PasswordInput
              label="New Password"
              placeholder="Enter your new password"
              required
              {...passwordForm.getInputProps("newPassword")}
            />
            <PasswordInput
              label="Confirm New Password"
              placeholder="Confirm your new password"
              required
              {...passwordForm.getInputProps("confirmPassword")}
            />
          </SimpleGrid>

          {changeUserPasswordResponse.error && (
            <Text color="red" mb="md">
              {changeUserPasswordResponse.error.message}
            </Text>
          )}

          <Button
            mt="lg"
            type="submit"
            loading={changeUserPasswordResponse.loading}
            color="tsgGreen.6"
          >
            Change Password
          </Button>
        </form>
      </Paper>
    </>
  );
};

export default UserProfilePage;
