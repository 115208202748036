import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
  TextInput,
  Button,
  Title,
  Text,
  Anchor,
  Stack,
  Alert,
  ThemeIcon,
} from "@mantine/core";
import { IconAlertCircle, IconCheck } from "@tabler/icons-react";

const ForgotPasswordPage: React.FC = () => {
  const auth = useAuth();
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const success = await auth.requestPasswordReset(email);
      if (success) {
        setIsSubmitted(true);
      } else {
        setError("Failed to request password reset. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isSubmitted) {
    return (
      <>
        <Title ta="center" fw={900} mb="lg">
          Check your email
        </Title>

        <Stack align="center" gap="md">
          <ThemeIcon color="green" size="xl" radius="xl">
            <IconCheck size="1.5rem" />
          </ThemeIcon>

          <Text size="sm" c="dimmed" ta="center">
            If an account exists with the email {email}, we've sent instructions
            to reset your password. Please check your email.
          </Text>

          <Anchor component={Link} to="/auth/login">
            Return to login
          </Anchor>
        </Stack>
      </>
    );
  }

  return (
    <>
      <Title ta="center" fw={900} mb="sm">
        Reset your password
      </Title>
      <Text c="dimmed" size="sm" ta="center" mb="lg">
        Enter your email address and we'll send you a link to reset your
        password.
      </Text>

      <form onSubmit={handleSubmit}>
        {error && (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Error"
            color="red"
            mb="md"
          >
            {error}
          </Alert>
        )}

        <Stack>
          <TextInput
            label="Email"
            placeholder="your@email.com"
            required
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />

          <Button type="submit" fullWidth loading={isLoading}>
            Send reset link
          </Button>
        </Stack>
      </form>

      <Text ta="center" mt="md">
        Remember your password?{" "}
        <Anchor component={Link} to="/auth/login">
          Back to login
        </Anchor>
      </Text>
    </>
  );
};

export default ForgotPasswordPage;
