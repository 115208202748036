import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Paper,
  Text,
  SimpleGrid,
  Group,
  Button,
  Badge,
  Anchor,
} from "@mantine/core";
import StyledPageTitle from "../components/StyledPageTitle";
import { IconEdit } from "@tabler/icons-react";

import { useGetReservationQuery } from "../graphql";
import DateDisplay from "../components/DateDisplay";
import DeleteButton from "../components/DeleteButton";
import { usePermissions } from "../contexts/PermissionsContext";
import moment from "moment";

const ReservationsDetailsPage: React.FC = () => {
  const permissions = usePermissions();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const getReservation = useGetReservationQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: `${params.id}`,
    },
  });

  const reservation = getReservation.data?.getReservation;

  // Get status color
  const getStatusColor = (status: string) => {
    switch (status) {
      case "CONFIRMED":
        return "green";
      case "PENDING":
        return "yellow";
      case "CANCELLED":
        return "red";
      case "COMPLETED":
        return "blue";
      default:
        return "gray";
    }
  };

  return (
    <>
      <Group justify="space-between" mb="sm">
        <StyledPageTitle>Reservation Details</StyledPageTitle>
        <Group>
          <Button
            rightSection={<IconEdit />}
            display={
              !permissions.can("updateReservation") ? "hidden" : undefined
            }
            onClick={() => navigate(`/app/reservations/${params.id}/update`)}
          >
            Update
          </Button>
          <DeleteButton
            id={params.id}
            onCompleted={() => navigate("/app/reservations")}
            type="reservation"
          />
        </Group>
      </Group>
      <Paper>
        <SimpleGrid cols={{ sm: 1, md: 2, lg: 3 }}>
          <Text>
            <strong>Status:</strong>{" "}
            <Badge color={getStatusColor(reservation?.status || "")}>
              {reservation?.status}
            </Badge>
          </Text>
          <Text>
            <strong>Start Time:</strong>{" "}
            <DateDisplay value={reservation?.startTime} format="LLL" />
          </Text>
          <Text>
            <strong>End Time:</strong>{" "}
            <DateDisplay value={reservation?.endTime} format="LLL" />
          </Text>
          <Text>
            <strong>Duration:</strong>{" "}
            {reservation?.startTime && reservation?.endTime
              ? `${moment(reservation.endTime).diff(
                  moment(reservation.startTime),
                  "minutes"
                )} minutes`
              : ""}
          </Text>
          <Text>
            <strong>Court:</strong>{" "}
            {reservation?.court ? (
              <Anchor
                onClick={() => navigate(`/app/courts/${reservation.court.id}`)}
              >
                {reservation.court.name}
              </Anchor>
            ) : (
              "N/A"
            )}
          </Text>
          <Text>
            <strong>Customer:</strong>{" "}
            {reservation?.customer ? (
              <Anchor
                onClick={() =>
                  navigate(`/app/customers/${reservation.customer?.id}`)
                }
              >
                {reservation.customer.firstName} {reservation.customer.lastName}
              </Anchor>
            ) : (
              "N/A"
            )}
          </Text>
          <Text>
            <strong>Created By:</strong>{" "}
            {reservation?.createdBy ? (
              <Anchor
                onClick={() =>
                  navigate(`/app/employees/${reservation.createdBy.id}`)
                }
              >
                {reservation.createdBy.firstName}{" "}
                {reservation.createdBy.lastName}
              </Anchor>
            ) : (
              "N/A"
            )}
          </Text>
          <Text>
            <strong>Part of Series:</strong>{" "}
            {reservation?.series ? (
              <Anchor
                onClick={() =>
                  navigate(`/app/reservations/series/${reservation.series?.id}`)
                }
              >
                {reservation.series.name || "Unnamed Series"}
              </Anchor>
            ) : (
              "No"
            )}
          </Text>
          <Text>
            <strong>Notes:</strong> {reservation?.notes || "N/A"}
          </Text>
          <Text>
            <strong>Created At:</strong>{" "}
            <DateDisplay value={reservation?.createdAt} />
          </Text>
          <Text>
            <strong>Updated At:</strong>{" "}
            <DateDisplay value={reservation?.updatedAt} />
          </Text>
        </SimpleGrid>
      </Paper>
    </>
  );
};

export default ReservationsDetailsPage;
