import React from "react";
import { Paper } from "@mantine/core";
import EmployeeForm from "../components/EmployeeForm";
import { useNavigate, useParams } from "react-router-dom";
import { useGetEmployeeQuery } from "../graphql";
import StyledPageTitle from "../components/StyledPageTitle";

const EmployeesUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const getEmployee = useGetEmployeeQuery({
    variables: { id: `${params.id}` },
  });

  const employee = getEmployee.data?.getEmployee;

  return (
    <>
      <StyledPageTitle>Update Employee</StyledPageTitle>
      <Paper>
        {employee && (
          <EmployeeForm
            employee={employee}
            onSubmit={(id) => navigate(`/app/employees/${id}`)}
          />
        )}
      </Paper>
    </>
  );
};

export default EmployeesUpdatePage;
