import React from "react";
import { Title, Text } from "@mantine/core";
import { useSearchParams } from "react-router-dom";
import { useSetupPlanMutation } from "../../graphql";

const SignUpSuccessPage: React.FC = () => {
  const [queryParams] = useSearchParams();
  const accountId = queryParams.get("accountId");
  const planId = queryParams.get("planId");

  const [setupBilling, response] = useSetupPlanMutation();

  React.useEffect(() => {
    if (
      typeof accountId === "string" &&
      typeof planId === "string" &&
      !response.called
    ) {
      setupBilling({ variables: { input: { accountId, planId } } });
    }
  }, [accountId, planId, setupBilling, response]);

  if (!accountId || !planId) {
    return (
      <Text c="red" ta="center">
        Invalid account or plan ID. Please check your link.
      </Text>
    );
  }

  if (response.error) {
    return (
      <Text c="red" ta="center">
        An error occurred while setting up your account. Please try again later.
      </Text>
    );
  }
  if (response.loading) {
    return <Text ta="center">Setting up your account, please wait...</Text>;
  }

  return (
    <>
      <Title ta="center">Congrats!</Title>
      <Text mt="xl" ta="center">
        Please check your email inbox for a link to confirm your account!
      </Text>
    </>
  );
};

export default SignUpSuccessPage;
