import { modals } from "@mantine/modals";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import PaymentMethodForm from "../components/PaymentMethodForm";
import config from "../utils/config";
import useBillingSetupIntent from "./useBillingSetupIntent";
import { Loader } from "@mantine/core";

interface Args {
  onCancel?: () => void | Promise<void>;
}

const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

function usePaymentMethodModal(args: Args = {}) {
  const billingSetupIntent = useBillingSetupIntent();
  const clientSecret = billingSetupIntent.setupIntent?.client_secret;

  const openPaymentMethodModal = (paymentMethodId?: string) => {
    modals.open({
      title: paymentMethodId ? "Update Payment Method" : "Add Payment Method",
      size: "lg",
      children: clientSecret ? (
        <Elements options={{ clientSecret }} stripe={stripePromise}>
          <PaymentMethodForm
            cols={1}
            paymentMethodId={paymentMethodId}
            returnToUrl={`${window.location.origin}/app/settings`}
          />
        </Elements>
      ) : (
        <Loader />
      ),
      onClose: args.onCancel,
    });
  };

  return { openPaymentMethodModal };
}

export default usePaymentMethodModal;
