import { Box, Button, Center, Group, Title } from "@mantine/core";
import React from "react";
import type { XYCoord } from "react-dnd";
import { useDrop } from "react-dnd";
import {
  CourtFragment,
  useListCourtsQuery,
  useUpdateCourtMutation,
} from "../graphql";

import DraggableCourt from "./DraggableCourt";
import useCourtModal from "../hooks/useCourtModal";

const FacilityMap: React.FC = () => {
  const listCourtsResult = useListCourtsQuery();
  const [updateCourt] = useUpdateCourtMutation();
  const courts = React.useMemo(
    () => listCourtsResult.data?.listCourts.data || [],
    [listCourtsResult.data]
  );
  const { openCourtModal } = useCourtModal({
    onComplete: () => {},
    onCancel: () => {},
  });

  const moveBox = async (court: CourtFragment, x: number, y: number) => {
    // Find the most up-to-date court data to ensure we have the correct rotation
    const currentCourt = courts.find((c) => c.id === court.id) || court;

    const newCourt = {
      id: currentCourt.id,
      name: currentCourt.name,
      surface: currentCourt.surface,
      coverage: currentCourt.coverage,
      color: currentCourt.color,
      order: currentCourt.order,
      location: {
        x: x,
        y: y,
        rotation: currentCourt.location.rotation,
      },
    };

    // Create a proper complete optimistic response to ensure rotation is preserved
    const fullCourt = courts.find((c) => c.id === court.id) || court;

    await updateCourt({
      variables: {
        input: newCourt,
      },
      optimisticResponse: {
        updateCourt: {
          ...fullCourt,
          location: {
            ...fullCourt.location,
            x: x,
            y: y,
            rotation: fullCourt.location.rotation,
          },
        },
      },
    });
  };

  // Create a ref to store courts for tracking in the drop handler
  const courtsRef = React.useRef(courts);

  // Keep the courts ref updated
  React.useEffect(() => {
    courtsRef.current = courts;
  }, [courts]);

  const [, drop] = useDrop(
    () => ({
      accept: "court",
      drop: async (court: CourtFragment, monitor) => {
        // Get fresh court data with updated rotation
        const latestCourts = courtsRef.current;
        const latestCourt =
          latestCourts.find((c) => c.id === court.id) || court;

        console.log(
          "Court being dropped. Rotation:",
          latestCourt.location.rotation
        );

        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const x = Math.round(court.location.x + delta.x);
        const y = Math.round(court.location.y + delta.y);

        // Use the latest court data to ensure rotation is preserved
        await moveBox(latestCourt, x, y);
        return undefined;
      },
    }),
    [moveBox]
  );

  return (
    <>
      <Group justify="space-between">
        <Title order={1}>Facility Map</Title>
        <Button onClick={() => openCourtModal()}>New Court</Button>
      </Group>
      <Center>
        <Box
          ref={drop}
          mih="1000px"
          miw="800px"
          w="100%"
          pos="relative"
          style={{
            borderColor: "black",
            borderStyle: "solid",
            borderWidth: "1px",
          }}
        >
          {courts.map((court) => (
            <DraggableCourt
              key={court.id}
              court={court}
              draggable
              onEditClick={() => openCourtModal(court)}
            />
          ))}
        </Box>
      </Center>
    </>
  );
};

export default FacilityMap;
