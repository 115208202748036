import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CloseButton,
  Group,
  Pagination,
  Paper,
  Skeleton,
  Table,
  TextInput,
  rem,
} from "@mantine/core";
import StyledPageTitle from "../components/StyledPageTitle";
import { IconMap, IconPlus, IconSearch } from "@tabler/icons-react";

import {
  CourtFilter,
  useListCourtsQuery,
  CourtSurface,
  CourtCoverage,
} from "../graphql";

// Extended type to include the order field until GraphQL types are regenerated
type CourtWithOrder = {
  id: string;
  name: string;
  surface?: CourtSurface | null;
  coverage?: CourtCoverage | null;
  color?: string | null;
  order?: number | null;
  createdAt: string;
  updatedAt: string;
  accountId: string;
  location: {
    x: number;
    y: number;
    rotation: number;
  };
};
import DateDisplay from "../components/DateDisplay";
import TableHeaderCell from "../components/TableHeaderCell";
import useListVariables from "../hooks/useListVariables";
import { usePermissions } from "../contexts/PermissionsContext";

const headers = [
  { label: "Order", field: "order" },
  { label: "Name", field: "name" },
  { label: "Surface", field: "surface" },
  { label: "Coverage", field: "coverage" },
  { label: "Last Updated", field: "updatedAt" },
];

const CourtsPage: React.FC = () => {
  const permissions = usePermissions();
  const navigate = useNavigate();
  const listVariables = useListVariables<CourtFilter>({
    defaultSort: { field: "order", order: "ASC" },
  });

  const courts = useListCourtsQuery({
    variables: {
      pagination: listVariables.pagination,
      sort: listVariables.sort,
      filter: listVariables.filter,
    },
  });

  return (
    <>
      <Group justify="space-between" mb="sm">
        <StyledPageTitle>Courts</StyledPageTitle>
        <Group>
          <TextInput
            w={300}
            leftSection={<IconSearch />}
            placeholder="Search"
            value={listVariables.filter?.q || ""}
            onChange={(e) =>
              listVariables.setFilter({
                q: e.currentTarget.value || undefined,
              })
            }
            rightSection={
              listVariables.filter?.q && (
                <CloseButton
                  onClick={() => listVariables.setFilter({ q: undefined })}
                />
              )
            }
          />
          <Button
            onClick={() => navigate("/app/courts/map")}
            rightSection={<IconMap />}
          >
            Facility Map
          </Button>
          <Button
            onClick={() => navigate("/app/courts/create")}
            rightSection={<IconPlus />}
            display={!permissions.can("createCourt") ? "hidden" : undefined}
          >
            Create
          </Button>
        </Group>
      </Group>
      <Paper w="100%">
        <Table.ScrollContainer minWidth={500}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                {headers.map((header) => (
                  <TableHeaderCell
                    key={header.field}
                    label={header.label}
                    field={header.field}
                    vars={listVariables}
                  />
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {courts.data?.listCourts.data.map((court) => (
                <Table.Tr
                  key={court.id}
                  data-testid="court-table-row"
                  onClick={() => navigate(`/app/courts/${court.id}`)}
                  style={{ cursor: "pointer" }}
                >
                  <Table.Td>
                    {(court as CourtWithOrder).order !== null &&
                    (court as CourtWithOrder).order !== undefined
                      ? (court as CourtWithOrder).order
                      : "-"}
                  </Table.Td>
                  <Table.Td>{court.name}</Table.Td>
                  <Table.Td>{court.surface}</Table.Td>
                  <Table.Td>{court.coverage}</Table.Td>
                  <Table.Td>
                    <DateDisplay value={court.updatedAt} />
                  </Table.Td>
                </Table.Tr>
              ))}
              {courts.loading &&
                Array.from({ length: listVariables.pagination.perPage }).map(
                  (_, index) => (
                    <Table.Tr key={index}>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <Table.Td key={index}>
                          <Skeleton height={22} radius="none" />
                        </Table.Td>
                      ))}
                    </Table.Tr>
                  )
                )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
        <Group justify="space-evenly">
          <Pagination
            hidden={
              !courts.data?.listCourts.total ||
              courts.data?.listCourts.total < listVariables.pagination.perPage
            }
            mt={rem(20)}
            value={listVariables.pagination.page}
            onChange={listVariables.setPage}
            total={
              (courts.data?.listCourts.total || 0) /
              listVariables.pagination.perPage
            }
          />
        </Group>
      </Paper>
    </>
  );
};

export default CourtsPage;
