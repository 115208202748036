import React from "react";
import { Paper } from "@mantine/core";
import ReservationForm from "../components/ReservationForm";
import { useNavigate, useParams } from "react-router-dom";
import { useGetReservationQuery } from "../graphql";
import StyledPageTitle from "../components/StyledPageTitle";

const ReservationsUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const getReservation = useGetReservationQuery({
    variables: { id: `${params.id}` },
  });

  const reservation = getReservation.data?.getReservation;

  return (
    <>
      <StyledPageTitle>Update Reservation</StyledPageTitle>
      <Paper>
        {reservation && (
          <ReservationForm
            reservation={reservation}
            onSubmit={(id) => navigate(`/app/reservations/${id}`)}
          />
        )}
      </Paper>
    </>
  );
};

export default ReservationsUpdatePage;
