import React from "react";
import { Paper } from "@mantine/core";
import PayrollReportForm from "../components/PayrollReportForm";
import StyledPageTitle from "../components/StyledPageTitle";

const ReportsPage: React.FC = () => {
  return (
    <>
      <StyledPageTitle>Reports</StyledPageTitle>
      <Paper w="100%">
        <PayrollReportForm />
      </Paper>
    </>
  );
};

export default ReportsPage;
