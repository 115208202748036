import React from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Paper,
  rem,
  Image,
  Group,
  Box,
  useMantineTheme,
} from "@mantine/core";
import { useAuth } from "../contexts/AuthContext";

const AuthLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const theme = useMantineTheme();

  if (auth.isAuthenticated) {
    navigate("/app");
    return null;
  }

  const containerWidth = location.pathname === "/signup" ? "md" : "xs";

  return (
    <Box
      mih="100vh"
      mt="-32px"
      bg={`linear-gradient(135deg, ${theme.colors.tsgBlue[0]} 0%, ${theme.colors.tsgBlue[3]} 100%)`}
    >
      <Container mt="xl" size={containerWidth} style={{ overflow: "auto" }}>
        <Group justify="center">
          <Image
            w={rem(300)}
            maw="80%"
            my="xl"
            src="/img/frontpageLogo.png"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
        </Group>
        <Paper
          p="xl"
          radius="md"
          mb="xl"
          withBorder
          style={{
            boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
            background: theme.white,
            borderColor: theme.colors.tsgBlue[1],
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              transform: "translateY(-5px)",
              boxShadow: "0 15px 35px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          <Outlet />
        </Paper>
      </Container>
    </Box>
  );
};

export default AuthLayout;
