import React from "react";
import { Paper } from "@mantine/core";
import EmployeeForm from "../components/EmployeeForm";
import { useNavigate } from "react-router-dom";
import StyledPageTitle from "../components/StyledPageTitle";

const EmployeesCreatePage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <StyledPageTitle>Create Employee</StyledPageTitle>
      <Paper>
        <EmployeeForm onSubmit={(id) => navigate(`/app/employees/${id}`)} />
      </Paper>
    </>
  );
};

export default EmployeesCreatePage;
