import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Text,
  Button,
  Group,
  Image,
  Grid,
  Paper,
  useMantineTheme,
  Title,
  List,
  Box,
} from "@mantine/core";
import {
  IconCheck,
  IconBallTennis,
  IconCalendar,
  IconUsersGroup,
} from "@tabler/icons-react";
import classes from "./HomePage.module.css";

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();

  return (
    <div className={classes.wrapper}>
      {/* Hero Section */}
      <Container size="lg" className={classes.inner}>
        <Grid gutter={50}>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <div className={classes.heroContent}>
              <Title className={classes.title} order={1}>
                Serve up
                <Text
                  component="span"
                  variant="gradient"
                  gradient={{
                    from: theme.colors.tsgBlue[6],
                    to: theme.colors.tsgBlue[4],
                  }}
                  inherit
                >
                  {" "}
                  success
                </Text>
                ,<br />
                One{" "}
                <Text
                  component="span"
                  variant="gradient"
                  gradient={{
                    from: theme.colors.tsgGreen[6],
                    to: theme.colors.tsgGreen[4],
                  }}
                  inherit
                >
                  string
                </Text>{" "}
                at a time
              </Title>

              <Text className={classes.description} size="xl">
                Your all-in-one cloud solution for effortlessly managing string
                jobs, court reservations, and employee schedules. Simplify
                operations and boost efficiency in your tennis shop today!
              </Text>

              <Group className={classes.controls} gap="md">
                <Button
                  size="xl"
                  className={classes.control}
                  variant="gradient"
                  gradient={{
                    from: theme.colors.tsgGreen[6],
                    to: theme.colors.tsgGreen[4],
                  }}
                  onClick={() => navigate("/signup")}
                >
                  Get started
                </Button>
                <Button
                  size="xl"
                  variant="outline"
                  color="tsgBlue"
                  className={classes.control}
                  onClick={() => navigate("/features")}
                >
                  View features
                </Button>
              </Group>
            </div>
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, md: 6 }}
            className={classes.heroImageColumn}
          >
            <div className={classes.heroImage}>
              <Image
                src="/img/shop-clothes.png"
                alt="Tennis shop management"
                className={classes.mainImage}
              />
            </div>
          </Grid.Col>
        </Grid>
      </Container>

      {/* Features Section */}
      <Box
        mt={80}
        py={50}
        // style={{
        //   background: `linear-gradient(180deg, ${theme.white} 0%, ${theme.colors.tsgBlue[0]} 100%)`,
        // }}
      >
        <Container size="lg">
          <Title order={2} ta="center" mb={50} className={classes.sectionTitle}>
            Streamline Your Tennis Shop Management
          </Title>

          <Grid>
            <Grid.Col span={{ base: 12, md: 4 }}>
              <Paper
                withBorder
                p="xl"
                radius="md"
                className={classes.featureCard}
              >
                <IconBallTennis
                  size={40}
                  color={theme.colors.tsgBlue[6]}
                  stroke={1.5}
                  style={{ marginBottom: theme.spacing.md }}
                />
                <Title order={3} mb="md">
                  String Job Tracking
                </Title>
                <Text size="md">
                  Manage customer racket stringing jobs with ease. Track
                  progress, set priorities, and never miss a deadline.
                </Text>
                <List
                  spacing="xs"
                  size="sm"
                  mt="md"
                  center
                  icon={
                    <IconCheck
                      style={{ color: theme.colors.tsgGreen[6] }}
                      size={16}
                    />
                  }
                >
                  <List.Item>Customer string preferences</List.Item>
                  <List.Item>Job status tracking</List.Item>
                  <List.Item>Automated notifications</List.Item>
                </List>
              </Paper>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 4 }}>
              <Paper
                withBorder
                p="xl"
                radius="md"
                className={classes.featureCard}
              >
                <IconCalendar
                  size={40}
                  color={theme.colors.tsgBlue[6]}
                  stroke={1.5}
                  style={{ marginBottom: theme.spacing.md }}
                />
                <Title order={3} mb="md">
                  Court Reservations
                </Title>
                <Text size="md">
                  Effortlessly manage court bookings, avoid scheduling
                  conflicts, and maximize court utilization for better revenue.
                </Text>
                <List
                  spacing="xs"
                  size="sm"
                  mt="md"
                  center
                  icon={
                    <IconCheck
                      style={{ color: theme.colors.tsgGreen[6] }}
                      size={16}
                    />
                  }
                >
                  <List.Item>Visual court calendar</List.Item>
                  <List.Item>Recurring bookings</List.Item>
                  <List.Item>Online customer booking</List.Item>
                </List>
              </Paper>
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 4 }}>
              <Paper
                withBorder
                p="xl"
                radius="md"
                className={classes.featureCard}
              >
                <IconUsersGroup
                  size={40}
                  color={theme.colors.tsgBlue[6]}
                  stroke={1.5}
                  style={{ marginBottom: theme.spacing.md }}
                />
                <Title order={3} mb="md">
                  Staff Management
                </Title>
                <Text size="md">
                  Track employee hours, assign tasks, and optimize your
                  workforce for better customer service and shop operations.
                </Text>
                <List
                  spacing="xs"
                  size="sm"
                  mt="md"
                  center
                  icon={
                    <IconCheck
                      style={{ color: theme.colors.tsgGreen[6] }}
                      size={16}
                    />
                  }
                >
                  <List.Item>Shift scheduling</List.Item>
                  <List.Item>Time tracking</List.Item>
                  <List.Item>Task assignments</List.Item>
                </List>
              </Paper>
            </Grid.Col>
          </Grid>

          <Group justify="center" mt={50}>
            <Button
              size="lg"
              variant="filled"
              color="tsgBlue.6"
              onClick={() => navigate("/signup")}
            >
              Start Free Trial
            </Button>
          </Group>
        </Container>
      </Box>
    </div>
  );
};

export default HomePage;
