import React from "react";
import {
  CreateCustomerInput,
  Gender,
  NtrpRating,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
} from "../graphql";
import { useForm } from "@mantine/form";
import { Button, Select, SimpleGrid, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { showSuccessNotification } from "../utils/showNotification";

interface Props {
  onSubmit: (id: string) => void;
  customer?: CreateCustomerInput & { id: string };
}

const NTRP_RATINGS: { value: NtrpRating; label: string }[] = [
  { value: "LEVEL_1_0", label: "1.0" },
  { value: "LEVEL_1_5", label: "1.5" },
  { value: "LEVEL_2_0", label: "2.0" },
  { value: "LEVEL_2_5", label: "2.5" },
  { value: "LEVEL_3_0", label: "3.0" },
  { value: "LEVEL_3_5", label: "3.5" },
  { value: "LEVEL_4_0", label: "4.0" },
  { value: "LEVEL_4_5", label: "4.5" },
  { value: "LEVEL_5_0", label: "5.0" },
  { value: "LEVEL_5_5", label: "5.5" },
  { value: "LEVEL_6_0", label: "6.0" },
  { value: "LEVEL_6_5", label: "6.5" },
  { value: "LEVEL_7_0", label: "7.0" },
];

const GENDER: { value: Gender; label: string }[] = [
  { value: "MALE", label: "Male" },
  { value: "FEMALE", label: "Female" },
];

const CustomerForm: React.FC<Props> = ({ onSubmit, customer }) => {
  const [createCustomer, createCustomerResponse] = useCreateCustomerMutation({
    refetchQueries: ["ListCustomers"],
    onCompleted: () => {
      showSuccessNotification("Customer created successfully");
    },
  });
  const [updateCustomer, updateCustomerResponse] = useUpdateCustomerMutation({
    refetchQueries: ["ListCustomers"],
    onCompleted: () => {
      showSuccessNotification("Customer updated successfully");
    },
  });
  const form = useForm<CreateCustomerInput>({
    initialValues: {
      firstName: customer?.firstName || "",
      middleInitial: customer?.middleInitial || "",
      lastName: customer?.lastName || "",
      email: customer?.email || "",
      cellPhone: customer?.cellPhone || "",
      homePhone: customer?.homePhone || "",
      workPhone: customer?.workPhone || "",
      address: customer?.address || "",
      city: customer?.city || "",
      zip: customer?.zip || "",
      ntrpRating: customer?.ntrpRating,
      gender: customer?.gender,
    },
  });

  const handleSubmit = async (values: CreateCustomerInput) => {
    if (customer) {
      await updateCustomer({
        variables: { input: { ...values, id: customer.id } },
      });
      onSubmit(customer.id);
    } else {
      const result = await createCustomer({ variables: { input: values } });
      if (result.data?.createCustomer) {
        onSubmit(result.data.createCustomer.id);
      }
    }
  };

  const errorMessage =
    createCustomerResponse.error?.message ||
    updateCustomerResponse.error?.message;

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <SimpleGrid>
          <TextInput
            data-test-id="customer-firstName-input"
            label="First Name"
            {...form.getInputProps("firstName")}
          />
          <TextInput
            data-test-id="customer-middleInitial-input"
            label="Middle Initial"
            {...form.getInputProps("middleInitial")}
          />
          <TextInput
            data-test-id="customer-lastName-input"
            label="Last Name"
            {...form.getInputProps("lastName")}
          />
          <TextInput
            data-test-id="customer-email-input"
            label="Email"
            type="email"
            {...form.getInputProps("email")}
          />
          <TextInput
            data-test-id="customer-cellPhone-input"
            label="Cell Phone"
            {...form.getInputProps("cellPhone")}
          />
          <TextInput
            data-test-id="customer-homePhone-input"
            label="Home Phone"
            {...form.getInputProps("homePhone")}
          />
          <TextInput
            data-test-id="customer-workPhone-input"
            label="Work Phone"
            {...form.getInputProps("workPhone")}
          />
          <TextInput
            data-test-id="customer-address-input"
            label="Address"
            {...form.getInputProps("address")}
          />
          <TextInput
            data-test-id="customer-city-input"
            label="City"
            {...form.getInputProps("city")}
          />
          <TextInput
            data-test-id="customer-zip-input"
            label="Zip"
            {...form.getInputProps("zip")}
          />
          <Select
            data-test-id="customer-ntrpRating-input"
            label="NTRP Rating"
            {...form.getInputProps("ntrpRating")}
            data={NTRP_RATINGS}
          />
          <Select
            data-test-id="customer-gender-input"
            label="Gender"
            {...form.getInputProps("gender")}
            data={GENDER}
          />
          <DateInput
            data-test-id="customer-dateOfBirth-input"
            label="Birthday"
            {...form.getInputProps("dateOfBirth")}
            clearable
            defaultLevel="year"
          />
        </SimpleGrid>
        {errorMessage && (
          <Text mt="md" c="red">
            {errorMessage}
          </Text>
        )}
        <Button
          data-test-id="btn-save-customer"
          fullWidth
          mt="md"
          type="submit"
        >
          Save
        </Button>
      </form>
    </>
  );
};

export default CustomerForm;
