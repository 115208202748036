import React from "react";
import { Group, Paper, Box } from "@mantine/core";

import AccountSettingsForm from "../components/AccountSettingsForm";
import StyledPageTitle from "../components/StyledPageTitle";
import StyledSectionTitle from "../components/StyledSectionTitle";
import {
  useGetMyAccountQuery,
  useGetMyAccountBillingInfoQuery,
} from "../graphql";
import useSubscribeToPlanModal from "../hooks/useSubscribeToPlanModal";
import PlanSelector from "../components/PlanSelector";
import ManageBillingPanel from "../components/ManageBillingPanel";

const SettingsPage: React.FC = () => {
  const getMyAccount = useGetMyAccountQuery();
  const billingInfo = useGetMyAccountBillingInfoQuery();
  const { openSubscribeToPlanModal } = useSubscribeToPlanModal();

  const account = getMyAccount.data?.myAccount;
  const currentSubscription =
    billingInfo.data?.myAccount?.billingInfo?.currentSubscription;

  return (
    <>
      <StyledPageTitle>Account Settings</StyledPageTitle>

      <Paper withBorder shadow="md" p="xl" mb="xl" radius="md">
        <StyledSectionTitle>Tennis Shop Information</StyledSectionTitle>

        {account && <AccountSettingsForm account={account} />}
      </Paper>

      <Paper withBorder shadow="md" p="xl" mb="xl" radius="md">
        <Group justify="space-between">
          <StyledSectionTitle>Billing Settings</StyledSectionTitle>
        </Group>
        <ManageBillingPanel />
      </Paper>

      <Paper withBorder shadow="md" p="xl" radius="md">
        <StyledSectionTitle>Plan</StyledSectionTitle>
        <Box>
          <PlanSelector
            currentPlanId={currentSubscription?.plan?.product}
            currentPriceId={currentSubscription?.plan?.id}
            onPlanSelect={(planId) => openSubscribeToPlanModal(planId)}
          />
        </Box>
      </Paper>
    </>
  );
};

export default SettingsPage;
