import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
  useMantineTheme,
  List,
  Button,
  Box,
  Group,
  Badge,
  Grid,
  ThemeIcon,
  Image,
} from "@mantine/core";
import {
  IconAward,
  IconTrophy,
  IconCircleCheck,
  IconCurrencyDollar,
  IconStarFilled,
} from "@tabler/icons-react";
import classes from "./FeaturesPage.module.css";

const plans = [
  {
    title: "Basic Plan",
    description:
      "The Basic Plan is tailored for smaller tennis shops or those just starting out. It focuses on providing the essential tools needed for efficient operation without overwhelming users with complex features they may not need. This plan includes:",
    features: [
      "Customer Tennis Racket String Job History: Track the stringing history for each customer to offer personalized service and recommendations.",
      "Employee Hours Tracker: A simple solution for recording and managing employee work hours and shifts.",
      "Basic Reporting: Access to standard reports on sales, string jobs completed, and basic employee productivity metrics. This could help in understanding the shop's performance on a fundamental level.",
    ],
    icon: IconAward,
    monthly: 15,
    yearly: 150,
  },
  {
    title: "Pro Plan",
    description:
      "The Pro Plan is designed for established shops that require a broader set of tools and more detailed analytics to optimize their operations. In addition to all the features included in the Basic Plan, the Pro Plan offers:",
    features: [
      "Advanced Employee String Job Tracker: Detailed tracking of each string job by employee, including time taken and materials used, facilitating accurate payroll and performance incentives.",
      "Court Reservations: Integration of a court reservation system, allowing customers to book courts directly through the shop, which could also include features like reservation management, cancellation policies, and advanced booking options.",
      "Enhanced Reporting: Advanced reporting capabilities, offering deeper insights into financial performance, detailed customer behavior analytics, employee efficiency, inventory management, and more. Custom reports and analytics could also be a part of this package.",
      "Inventory Management: For shops that also sell tennis gear and equipment, this feature would help track stock levels, manage orders, and analyze sales trends.",
      "Custom SMS Notifications: Beyond basic notifications, this could include marketing messages, reminders for upcoming reservations or promotions, and personalized greetings or offers.",
    ],
    icon: IconTrophy,
    monthly: 30,
    yearly: 300,
  },
];

const PricingPage: React.FC = () => {
  const navigate = useNavigate();

  const theme = useMantineTheme();
  const planCards = plans.map((plan, index) => (
    <Card
      key={plan.title}
      shadow="md"
      radius="lg"
      padding="xl"
      style={{
        borderTop: `4px solid ${
          index === 0 ? theme.colors.tsgBlue[6] : theme.colors.tsgGreen[6]
        }`,
        position: "relative",
        overflow: "visible",
      }}
    >
      {index === 1 && (
        <Badge
          color="tsgGreen"
          size="lg"
          radius="sm"
          variant="filled"
          style={{
            position: "absolute",
            top: -15,
            right: 20,
            fontWeight: 700,
            padding: "8px 16px",
          }}
        >
          <Group gap={5}>
            <IconStarFilled size={16} />
            MOST POPULAR
          </Group>
        </Badge>
      )}

      <Box style={{ flexGrow: 1 }}>
        <Group mb="md">
          <ThemeIcon
            size={80}
            radius="md"
            variant="light"
            style={{
              background: `linear-gradient(135deg, ${
                index === 0 ? theme.colors.tsgBlue[0] : theme.colors.tsgGreen[0]
              } 0%, ${
                index === 0 ? theme.colors.tsgBlue[1] : theme.colors.tsgGreen[1]
              } 100%)`,
              color:
                index === 0
                  ? theme.colors.tsgBlue[6]
                  : theme.colors.tsgGreen[6],
              marginBottom: theme.spacing.sm,
            }}
          >
            <plan.icon
              style={{ width: rem(40), height: rem(40) }}
              stroke={1.5}
            />
          </ThemeIcon>

          <Box>
            <Text
              fz="xl"
              fw={700}
              className={classes.cardTitle}
              c={
                index === 0 ? theme.colors.tsgBlue[7] : theme.colors.tsgGreen[7]
              }
            >
              {plan.title}
            </Text>
          </Box>
        </Group>

        <Box
          style={{
            backgroundColor:
              index === 0 ? theme.colors.tsgBlue[0] : theme.colors.tsgGreen[0],
            padding: theme.spacing.lg,
            borderRadius: theme.radius.md,
            marginBottom: theme.spacing.lg,
            textAlign: "center",
          }}
        >
          <Text
            fz="2.5rem"
            fw={700}
            c={index === 0 ? theme.colors.tsgBlue[7] : theme.colors.tsgGreen[7]}
          >
            ${plan.monthly}
          </Text>
          <Text fw={500} c="dimmed">
            per month
          </Text>

          <Text size="sm" c="dimmed" mt="xs">
            or ${plan.yearly}/year (save ${plan.monthly * 12 - plan.yearly})
          </Text>
        </Box>

        <Text fz="sm" my="lg" style={{ lineHeight: 1.6 }}>
          {plan.description}
        </Text>

        <Text
          fw={600}
          mb="md"
          c={index === 0 ? theme.colors.tsgBlue[7] : theme.colors.tsgGreen[7]}
        >
          Included features:
        </Text>

        <List
          spacing="sm"
          size="sm"
          icon={
            <ThemeIcon
              size={20}
              radius="xl"
              color={index === 0 ? "tsgBlue.6" : "tsgGreen.6"}
            >
              <IconCircleCheck size={14} />
            </ThemeIcon>
          }
        >
          {plan.features.map((item, idx) => (
            <List.Item key={`${idx}`}>{item}</List.Item>
          ))}
        </List>
      </Box>

      <Box mt="xl">
        <Button
          fullWidth
          size="lg"
          onClick={() => navigate("/signup")}
          color={index === 0 ? "tsgBlue.6" : "tsgGreen.6"}
          variant={index === 0 ? "outline" : "filled"}
        >
          Get Started
        </Button>
      </Box>
    </Card>
  ));

  return (
    <>
      {/* Pricing hero section */}
      <Box
        mb="xl"
        style={{
          background: `linear-gradient(135deg, ${theme.colors.tsgBlue[7]} 0%, ${theme.colors.tsgBlue[5]} 100%)`,
          borderRadius: theme.radius.md,
          color: "white",
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <Container size="lg">
          <Grid align="center">
            <Grid.Col span={{ base: 12, md: 7 }}>
              <Title
                order={1}
                className={classes.title}
                style={{ color: "white" }}
              >
                Simple, Transparent{" "}
                <span style={{ color: theme.colors.tsgGreen[4] }}>Pricing</span>
              </Title>
              <Text
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                  maxWidth: "600px",
                  fontSize: "1.1rem",
                  lineHeight: 1.6,
                }}
                mt="md"
              >
                Tennis Shop Guru offers flexible plans to fit your shop's needs
                and budget. Choose the option that's right for you and upgrade
                anytime as your business grows.
              </Text>

              <Group mt="xl" gap="md">
                <Badge
                  size="lg"
                  radius="md"
                  color="tsgGreen.4"
                  variant="filled"
                >
                  No Setup Fees
                </Badge>
                <Badge
                  size="lg"
                  radius="md"
                  color="tsgGreen.4"
                  variant="filled"
                >
                  Cancel Anytime
                </Badge>
                <Badge
                  size="lg"
                  radius="md"
                  color="tsgGreen.4"
                  variant="filled"
                >
                  Free Support
                </Badge>
              </Group>
            </Grid.Col>
            <Grid.Col
              span={{ base: 12, md: 5 }}
              style={{ textAlign: "center" }}
            >
              <Image
                src="/img/rackets.png"
                alt="Tennis rackets"
                width={250}
                style={{
                  maxWidth: "100%",
                  opacity: 0.9,
                }}
                visibleFrom="md"
              />
            </Grid.Col>
          </Grid>
        </Container>
      </Box>

      {/* Pricing cards */}
      <Container size="lg" pb="xl">
        <SimpleGrid cols={{ base: 1, md: 2 }} spacing="xl" mb={50}>
          {planCards}
        </SimpleGrid>

        <Box style={{ textAlign: "center", marginTop: "3rem" }}>
          <Text size="lg" fw={500} mb="md">
            Need a custom solution for your tennis shop?
          </Text>
          <Button
            size="lg"
            onClick={() => navigate("/contact")}
            leftSection={<IconCurrencyDollar size={20} />}
            variant="outline"
            color="tsgBlue.6"
          >
            Contact Us for Custom Pricing
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default PricingPage;
