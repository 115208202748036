import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Group,
  Anchor,
  Stack,
  Alert,
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Check for verification status in URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const verified = params.get("verified");

    if (verified === "true") {
      setSuccess("Email verified successfully. You can now log in.");
    } else if (verified === "false") {
      setError(
        "Email verification failed. Please try again or contact support."
      );
    }
  }, [location.search]);

  // If already authenticated, redirect to app
  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/app");
    }
  }, [auth.isAuthenticated, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const success = await auth.login(email, password, keepSignedIn);
      if (success) {
        const returnPath = auth.getReturnPath();
        navigate(returnPath);
      } else {
        setError("Invalid email or password");
      }
    } catch (err) {
      setError("An error occurred during login. Please try again.");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Title ta="center" fw={900} mb="lg">
        Sign in to your account
      </Title>

      <form onSubmit={handleSubmit}>
        {error && (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Authentication error"
            color="red"
            mb="md"
          >
            {error}
          </Alert>
        )}

        {success && (
          <Alert title="Success" color="green" mb="md">
            {success}
          </Alert>
        )}

        <Stack>
          <TextInput
            label="Email"
            placeholder="your@email.com"
            data-test-id="email-input"
            required
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />

          <PasswordInput
            label="Password"
            placeholder="Your password"
            data-test-id="password-input"
            required
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />

          <Group justify="space-between">
            <Checkbox
              label="Keep me signed in"
              data-test-id="keep-signed-in-checkbox"
              checked={keepSignedIn}
              onChange={(e) => setKeepSignedIn(e.currentTarget.checked)}
            />

            <Anchor component={Link} to="/auth/forgot-password" size="sm">
              Forgot password?
            </Anchor>
          </Group>

          <Button
            type="submit"
            fullWidth
            loading={isLoading}
            data-test-id="login-button"
          >
            Sign in
          </Button>
        </Stack>
      </form>

      <Text ta="center" mt="md">
        Don&apos;t have an account?{" "}
        <Anchor component={Link} to="/signup">
          Sign up
        </Anchor>
      </Text>
    </>
  );
};

export default LoginPage;
