import React from "react";
import { Paper } from "@mantine/core";
import CourtForm from "../components/CourtForm";
import { useNavigate } from "react-router-dom";
import StyledPageTitle from "../components/StyledPageTitle";

const CourtsCreatePage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <StyledPageTitle mb="sm">Create Court</StyledPageTitle>
      <Paper>
        <CourtForm onSubmit={(id) => navigate(`/app/courts/${id}`)} />
      </Paper>
    </>
  );
};

export default CourtsCreatePage;
