import React from "react";
import {
  CreateEmployeeInput,
  useInviteEmployeeMutation,
  useUpdateEmployeeMutation,
} from "../graphql";
import { useForm } from "@mantine/form";
import {
  Button,
  ColorInput,
  JsonInput,
  Select,
  SimpleGrid,
  Text,
  TextInput,
} from "@mantine/core";
import { showSuccessNotification } from "../utils/showNotification";

type FormData = Omit<CreateEmployeeInput, "preferences"> & {
  preferences?: string | null;
};

interface Props {
  onSubmit: (id: string) => void;
  employee?: CreateEmployeeInput & { id: string };
}

const ROLE = [
  { value: "ACCOUNT_OWNER", label: "Account Owner" },
  { value: "ACCOUNT_ADMIN", label: "Account Admin" },
  { value: "EMPLOYEE", label: "Employee" },
];

const STATUS = [
  { value: "INVITED", label: "Invited" },
  { value: "ACTIVE", label: "Active" },
  { value: "DEACTIVATED", label: "Deactivated" },
];

const EmployeeForm: React.FC<Props> = ({ onSubmit, employee }) => {
  const [inviteEmployee, inviteEmployeeResponse] = useInviteEmployeeMutation({
    refetchQueries: ["ListEmployees"],
    onCompleted: () => {
      showSuccessNotification("Employee created invited");
    },
  });
  const [updateEmployee, updateEmployeeResponse] = useUpdateEmployeeMutation({
    refetchQueries: ["ListEmployees"],
    onCompleted: () => {
      showSuccessNotification("Employee updated successfully");
    },
  });
  const form = useForm<FormData>({
    initialValues: {
      firstName: employee?.firstName || "",
      lastName: employee?.lastName || "",
      email: employee?.email || "",
      role: employee?.role || "EMPLOYEE",
      status: employee?.status || "INVITED",
      color: employee?.color || "#2e2e2e",
      preferences: JSON.stringify(employee?.preferences),
    },
  });

  const handleSubmit = async ({ preferences, status, ...values }: FormData) => {
    if (employee) {
      await updateEmployee({
        variables: {
          input: {
            ...values,
            status,
            id: employee.id,
            preferences: JSON.parse(preferences || "{}"),
          },
        },
      });
      onSubmit(employee.id);
    } else {
      const result = await inviteEmployee({
        variables: {
          input: {
            ...values,
            preferences: JSON.parse(preferences || "{}"),
          },
        },
      });
      if (result.data?.inviteEmployee) {
        onSubmit(result.data.inviteEmployee.id);
      }
    }
  };

  const errorMessage =
    inviteEmployeeResponse.error?.message ||
    updateEmployeeResponse.error?.message;

  return (
    <div>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <SimpleGrid>
          <TextInput
            label="First Name"
            required
            {...form.getInputProps("firstName")}
          />
          <TextInput
            label="Last Name"
            required
            {...form.getInputProps("lastName")}
          />
          <TextInput
            label="Email"
            type="email"
            required
            {...form.getInputProps("email")}
          />
          <Select
            label="Role"
            required
            data={ROLE}
            {...form.getInputProps("role")}
          />
          <Select
            label="Status"
            required
            data={STATUS}
            {...form.getInputProps("status")}
          />
          <ColorInput
            label="Color"
            format="hex"
            withEyeDropper={false}
            disallowInput
            withPicker={false}
            swatches={[
              "#2e2e2e",
              "#868e96",
              "#fa5252",
              "#e64980",
              "#be4bdb",
              "#7950f2",
              "#4c6ef5",
              "#228be6",
              "#15aabf",
              "#12b886",
              "#40c057",
              "#82c91e",
              "#fab005",
              "#fd7e14",
            ]}
            {...form.getInputProps("color")}
          />

          <JsonInput
            label="Preferences"
            {...form.getInputProps("preferences")}
          />
        </SimpleGrid>
        {errorMessage && (
          <Text mt="md" c="red">
            {errorMessage}
          </Text>
        )}
        <Button id="btn-save-employee" fullWidth mt="md" type="submit">
          Save
        </Button>
      </form>
    </div>
  );
};

export default EmployeeForm;
