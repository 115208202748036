import React from "react";
import { Paper } from "@mantine/core";
import CourtForm from "../components/CourtForm";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCourtQuery } from "../graphql";
import StyledPageTitle from "../components/StyledPageTitle";

const CourtsUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const getCourt = useGetCourtQuery({
    variables: { id: `${params.id}` },
  });

  const court = getCourt.data?.getCourt;

  return (
    <>
      <StyledPageTitle>Update Court</StyledPageTitle>
      <Paper>
        {court && (
          <CourtForm
            court={court}
            onSubmit={(id) => navigate(`/app/courts/${id}`)}
          />
        )}
      </Paper>
    </>
  );
};

export default CourtsUpdatePage;
