import React from "react";
import {
  TextInput,
  Textarea,
  SimpleGrid,
  Group,
  Title,
  Button,
  Text,
  Box,
  Paper,
  Grid,
  Container,
  useMantineTheme,
  Image,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  IconMail,
  IconPhone,
  IconMapPin,
  IconBrandTwitter,
  IconBrandFacebook,
  IconBrandInstagram,
  IconSend,
  IconUser,
} from "@tabler/icons-react";

const ContactPage: React.FC = () => {
  const theme = useMantineTheme();

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validate: {
      name: (value: string) =>
        value.trim().length < 2 ? "Name must be at least 2 characters" : null,
      email: (value: string) =>
        !/^\S+@\S+$/.test(value) ? "Invalid email address" : null,
      subject: (value: string) =>
        value.trim().length === 0 ? "Subject is required" : null,
    },
  });

  return (
    <Container size="lg" py="xl">
      <Grid gutter={50}>
        {/* Left Column - Contact Info */}
        <Grid.Col span={{ base: 12, md: 5 }} order={{ base: 2, md: 1 }}>
          <Box>
            <Title
              order={2}
              mb="md"
              style={{
                color: theme.colors.tsgBlue[7],
                fontFamily: "'Montserrat', var(--mantine-font-family)",
              }}
            >
              Contact Information
            </Title>

            <Text mb="xl" size="lg" c="dimmed">
              Have questions about TennisShopGuru? Reach out to our team and
              we'll get back to you as soon as possible.
            </Text>

            <Box
              style={{
                background: `linear-gradient(135deg, ${theme.colors.tsgBlue[6]} 0%, ${theme.colors.tsgBlue[7]} 100%)`,
                borderRadius: theme.radius.md,
                padding: theme.spacing.xl,
                color: "white",
                marginBottom: theme.spacing.xl,
              }}
            >
              <Group gap="xs" mb="lg">
                <IconMapPin size={20} />
                <Text fw={500}>123 Tennis Court Ave, California, USA</Text>
              </Group>

              <Group gap="xs" mb="lg">
                <IconPhone size={20} />
                <Text fw={500}>+1 (555) 123-4567</Text>
              </Group>

              <Group gap="xs" mb="lg">
                <IconMail size={20} />
                <Text
                  fw={500}
                  component="a"
                  href="mailto:info@tennisshopguru.com"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  info@tennisshopguru.com
                </Text>
              </Group>

              <Group mt={30} gap="md">
                <Box
                  component="a"
                  href="https://twitter.com"
                  target="_blank"
                  style={{
                    width: 36,
                    height: 36,
                    borderRadius: "50%",
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    transition: "background 0.2s",
                  }}
                >
                  <IconBrandTwitter size={18} />
                </Box>
                <Box
                  component="a"
                  href="https://facebook.com"
                  target="_blank"
                  style={{
                    width: 36,
                    height: 36,
                    borderRadius: "50%",
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    transition: "background 0.2s",
                  }}
                >
                  <IconBrandFacebook size={18} />
                </Box>
                <Box
                  component="a"
                  href="https://instagram.com"
                  target="_blank"
                  style={{
                    width: 36,
                    height: 36,
                    borderRadius: "50%",
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    transition: "background 0.2s",
                  }}
                >
                  <IconBrandInstagram size={18} />
                </Box>
              </Group>
            </Box>

            <Image
              src="/img/rackets.png"
              alt="Tennis rackets"
              style={{
                maxWidth: "100%",
                opacity: 0.9,
              }}
              visibleFrom="md"
            />
          </Box>
        </Grid.Col>

        {/* Right Column - Contact Form */}
        <Grid.Col span={{ base: 12, md: 7 }} order={{ base: 1, md: 2 }}>
          <Paper
            withBorder
            shadow="md"
            p={30}
            radius="md"
            style={{
              backgroundColor: theme.white,
              borderColor: theme.colors.tsgBlue[1],
            }}
          >
            <Title
              order={2}
              mb="xl"
              style={{
                color: theme.colors.tsgBlue[7],
                fontFamily: "'Montserrat', var(--mantine-font-family)",
              }}
              ta="center"
            >
              Get in Touch
            </Title>

            <form onSubmit={form.onSubmit(() => {})}>
              <SimpleGrid cols={{ base: 1, sm: 2 }} mb="md">
                <TextInput
                  label="Name"
                  placeholder="Your name"
                  name="name"
                  required
                  radius="md"
                  leftSection={
                    <IconUser size={16} color={theme.colors.tsgBlue[5]} />
                  }
                  styles={{
                    input: {
                      "&:focus": {
                        borderColor: theme.colors.tsgBlue[5],
                      },
                    },
                  }}
                  {...form.getInputProps("name")}
                />
                <TextInput
                  label="Email"
                  placeholder="your.email@example.com"
                  name="email"
                  required
                  radius="md"
                  leftSection={
                    <IconMail size={16} color={theme.colors.tsgBlue[5]} />
                  }
                  styles={{
                    input: {
                      "&:focus": {
                        borderColor: theme.colors.tsgBlue[5],
                      },
                    },
                  }}
                  {...form.getInputProps("email")}
                />
              </SimpleGrid>

              <TextInput
                label="Subject"
                placeholder="How can we help you?"
                mt="md"
                name="subject"
                required
                radius="md"
                styles={{
                  input: {
                    "&:focus": {
                      borderColor: theme.colors.tsgBlue[5],
                    },
                  },
                }}
                {...form.getInputProps("subject")}
              />

              <Textarea
                mt="md"
                label="Message"
                placeholder="Please provide details about your inquiry..."
                maxRows={10}
                minRows={5}
                autosize
                name="message"
                radius="md"
                styles={{
                  input: {
                    "&:focus": {
                      borderColor: theme.colors.tsgBlue[5],
                    },
                  },
                }}
                {...form.getInputProps("message")}
              />

              <Group justify="flex-end" mt="xl">
                <Button
                  type="submit"
                  size="md"
                  radius="md"
                  rightSection={<IconSend size={16} />}
                  color="tsgGreen.6"
                >
                  Send Message
                </Button>
              </Group>
            </form>
          </Paper>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default ContactPage;
