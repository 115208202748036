import React from "react";
import { Box, useMantineTheme } from "@mantine/core";
import { useDrop } from "react-dnd";

const TIME_SLOT_HEIGHT = 100; // Height of each hour slot in pixels
const MINUTES_PER_SLOT = 15; // Snap to 15-minute increments

// DND item types
const ItemTypes = {
  RESERVATION: "reservation",
  NEW_RESERVATION: "new_reservation",
};

// Drag state interface removed - now using React DND

// Interface for drag items
interface ReservationDragItem {
  type: typeof ItemTypes.RESERVATION;
  id: string;
  courtId: string;
  startTime: string;
  endTime: string;
  duration: number; // in minutes
}

interface NewReservationDragItem {
  type: typeof ItemTypes.NEW_RESERVATION;
  courtId: string;
  hour: number;
  minute: number;
}

// Droppable Time Slot Component
const DroppableTimeSlot: React.FC<{
  courtId: string;
  hour: number;
  children: React.ReactNode;
  onDrop: (
    item: ReservationDragItem | NewReservationDragItem,
    courtId: string,
    hour: number,
    minute: number
  ) => void;
  onSlotClick?: (courtId: string, hour: number, minute: number) => void;
  canCreate: boolean;
}> = ({ courtId, hour, children, onDrop, onSlotClick, canCreate }) => {
  const theme = useMantineTheme();
  const dropRef = React.useRef<HTMLDivElement>(null);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [ItemTypes.RESERVATION, ItemTypes.NEW_RESERVATION],
    drop: (item: ReservationDragItem | NewReservationDragItem, monitor) => {
      const clientOffset = monitor.getClientOffset();
      if (clientOffset && dropRef.current) {
        const dropTargetRect = dropRef.current.getBoundingClientRect();
        const y = clientOffset.y - dropTargetRect.top;
        const minute = Math.floor((y / TIME_SLOT_HEIGHT) * 60);
        const snappedMinute =
          Math.floor(minute / MINUTES_PER_SLOT) * MINUTES_PER_SLOT;
        onDrop(item, courtId, hour, snappedMinute);
      }
      return { courtId, hour, minute: 0 };
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  // Connect the drop ref to our element ref
  drop(dropRef);

  const handleClick = (e: React.MouseEvent) => {
    if (onSlotClick && canCreate) {
      const rect = e.currentTarget.getBoundingClientRect();
      const y = e.clientY - rect.top;
      const minute = Math.floor((y / TIME_SLOT_HEIGHT) * 60);
      const snappedMinute =
        Math.floor(minute / MINUTES_PER_SLOT) * MINUTES_PER_SLOT;
      onSlotClick(courtId, hour, snappedMinute);
    }
  };

  return (
    <Box
      ref={dropRef}
      style={{
        height: "100px",
        borderTop: `1px solid ${theme.colors.gray[3]}`,
        padding: "4px",
        position: "relative",
        cursor: canCreate ? "pointer" : "default",
        backgroundColor: isOver ? theme.colors.blue[0] : undefined,
      }}
      onClick={handleClick}
    >
      {children}
    </Box>
  );
};

export default DroppableTimeSlot;
