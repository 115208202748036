const envConfigs = {
  prod: {
    API_HOST: "https://api.tennisshopguru.com",
    REALTIME_API_HOST: "wss://ws.tennisshopguru.com",
    STRIPE_PUBLIC_KEY:
      "pk_live_51R3KqMFR0hZMk2x3u00VMtWRsuQ0p1jiEj1H0X65FN7VIbXiAmjLomh1CbF4JwtaVRMuMZ4b0RkVJXoWSLSsVMwF00XRJXfpgh",
  },
  beta: {
    API_HOST: "https://api-beta.tennisshopguru.com",
    REALTIME_API_HOST: "wss://ws-beta.tennisshopguru.com",
    STRIPE_PUBLIC_KEY:
      "pk_test_51R5UmrFMiqOKRXLW8pEoAm47xBRNCVnpmt7dC9MylkCAuYoW8JuO6I7b8AESyJPOJYjxAi41adsAJqWU2lCnfaXf0020yfvxUY",
  },
  local: {
    API_HOST: "http://localhost:8000",
    REALTIME_API_HOST: "ws://localhost:8000/ws",
    STRIPE_PUBLIC_KEY:
      "pk_test_51R5UmrFMiqOKRXLW8pEoAm47xBRNCVnpmt7dC9MylkCAuYoW8JuO6I7b8AESyJPOJYjxAi41adsAJqWU2lCnfaXf0020yfvxUY",
  },
};

type Env = keyof typeof envConfigs;

const ENV = (import.meta.env.VITE_ENV || "local") as Env;

console.log("import.meta.env.VITE_API_HOST", import.meta.env.VITE_API_HOST);

const config = {
  ENV: ENV,
  SHA: import.meta.env.VITE_SHA || "local",
  API_HOST: import.meta.env.VITE_API_HOST || envConfigs[ENV].API_HOST,
  REALTIME_API_HOST:
    import.meta.env.VITE_REALTIME_API_HOST || envConfigs[ENV].REALTIME_API_HOST,
  STRIPE_PUBLIC_KEY:
    import.meta.env.VITE_STRIPE_PUBLIC_KEY || envConfigs[ENV].STRIPE_PUBLIC_KEY,
};

export default config;
