import React from "react";
import { useDrag } from "react-dnd";
import { ActionIcon, Box, Text, rem } from "@mantine/core";
import { CourtFragment } from "../graphql";
import TennisCourtSVG from "./TennisCourtSVG";
import { IconEdit } from "@tabler/icons-react";

interface Props {
  court: CourtFragment;
  draggable: boolean;
  onEditClick?: (court: CourtFragment) => void;
  scale?: number;
}

const DraggableCourt: React.FC<Props> = ({
  court,
  draggable,
  onEditClick,
  ...props
}) => {
  // Create a ref to maintain rotation during drag operations
  const courtRef = React.useRef(court);

  // Keep the ref updated when the court prop changes
  React.useEffect(() => {
    courtRef.current = court;
  }, [court]);

  // Create a function to get the current court data with rotation
  const getCurrentCourt = React.useCallback(() => {
    return courtRef.current;
  }, []);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "court",
      item: getCurrentCourt,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      isDragging: (monitor) => {
        return monitor.getItem().id === court.id;
      },
      end: (_, monitor) => {
        // If the drag ended without dropping, ensure we maintain the correct state
        if (!monitor.didDrop()) {
          console.log(
            "Drag ended without drop, maintaining rotation:",
            court.location.rotation
          );
        }
      },
    }),
    [court, getCurrentCourt]
  );
  const scale = props.scale || 2;
  const lineColor = "white";

  return (
    <Box
      pos="absolute"
      ref={drag}
      top={court.location.y}
      left={court.location.x}
      h={`calc(60px * ${scale})`}
      w={`calc(120px * ${scale})`}
      bg={court.color || "green"}
      data-testid="court"
      style={{
        opacity: isDragging ? 0.4 : 1,
        cursor: draggable ? "move" : "default",
        transform: `rotate(${court.location.rotation}deg)`,
        transition: "transform 0.2s ease-in-out, opacity 0.2s ease-in-out",
        // Ensure the court is positioned correctly during dragging
        position: "absolute",
        top: court.location.y,
        left: court.location.x,
        // Set a high z-index to ensure it's above other courts when dragging
        zIndex: isDragging ? 1000 : 1,
      }}
    >
      <Box>
        <Text pos="absolute" left={rem(4)} c={lineColor}>
          {court.name}
        </Text>
        {onEditClick && (
          <ActionIcon
            onClick={() => onEditClick(court)}
            pos="absolute"
            variant="transparent"
            color={lineColor}
            size="xs"
            bottom={2}
            right={2}
            style={{
              transform: `rotate(${court.location.rotation}deg)`,
            }}
          >
            <IconEdit size={15} />
          </ActionIcon>
        )}
        <TennisCourtSVG scale={scale} lineColor={lineColor} />
      </Box>
    </Box>
  );
};

export default DraggableCourt;
