import React from "react";
import {
  CloseButton,
  Group,
  Pagination,
  Paper,
  Table,
  TextInput,
  rem,
} from "@mantine/core";
import { AccountFilter, useListAccountsQuery } from "../graphql";
import DateDisplay from "../components/DateDisplay";
import TableHeaderCell from "../components/TableHeaderCell";
import useListVariables from "../hooks/useListVariables";
import { IconSearch } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import StyledPageTitle from "../components/StyledPageTitle";

const headers = [
  { label: "Name", field: "name" },
  { label: "Phone", field: "phone" },
  { label: "Status", field: "status" },
  { label: "Trial End Date", field: "trialEndDate" },
  { label: "Signed Up Date", field: "createdAt" },
];

const AccountsPage: React.FC = () => {
  const navigate = useNavigate();
  const listVariables = useListVariables<AccountFilter>({
    defaultSort: { field: "createdAt", order: "ASC" },
  });
  const accounts = useListAccountsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      pagination: listVariables.pagination,
      sort: listVariables.sort,
      filter: listVariables.filter,
    },
  });

  return (
    <>
      <Group justify="space-between" mb="sm">
        <StyledPageTitle>Accounts</StyledPageTitle>
        <TextInput
          w={300}
          leftSection={<IconSearch />}
          placeholder="Search"
          value={listVariables.filter?.q || ""}
          onChange={(e) =>
            listVariables.setFilter({
              q: e.currentTarget.value || undefined,
            })
          }
          rightSection={
            listVariables.filter?.q && (
              <CloseButton
                onClick={() => listVariables.setFilter({ q: undefined })}
              />
            )
          }
        />
      </Group>
      <Paper w="100%">
        <Table.ScrollContainer minWidth={500}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                {headers.map((header) => (
                  <TableHeaderCell
                    key={header.field}
                    label={header.label}
                    field={header.field}
                    vars={listVariables}
                  />
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {accounts.data?.listAccounts.data.map((account) => (
                <Table.Tr
                  key={account.id}
                  onClick={() => navigate(`/app/accounts/${account.id}`)}
                >
                  <Table.Td>{account.name}</Table.Td>
                  <Table.Td>{account.phone}</Table.Td>
                  <Table.Td>{account.status}</Table.Td>
                  <Table.Td>{account.trialEndDate}</Table.Td>
                  <Table.Td>
                    <DateDisplay value={account.createdAt} />
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
        <Group justify="space-evenly">
          <Pagination
            hidden={
              !accounts.data?.listAccounts.total ||
              accounts.data?.listAccounts.total <
                listVariables.pagination.perPage
            }
            mt={rem(20)}
            value={listVariables.pagination.page}
            onChange={listVariables.setPage}
            total={
              (accounts.data?.listAccounts.total || 0) /
              listVariables.pagination.perPage
            }
          />
        </Group>
      </Paper>
    </>
  );
};

export default AccountsPage;
