import React from "react";
import EmployeeInput from "../components/EmployeeInput";
import { useForm } from "@mantine/form";
import { useAuth } from "../contexts/AuthContext";
import { Button, Checkbox, SimpleGrid } from "@mantine/core";

interface FormData {
  employeeId: string;
  notifyCustomer: boolean;
}

interface Props {
  onSubmit: (data: FormData) => void;
  loading?: boolean;
}

const UpdateJobsStatusForm: React.FC<Props> = ({ onSubmit, loading }) => {
  const { user } = useAuth();
  const form = useForm<FormData>({
    initialValues: {
      employeeId: user?.employee_id || "",
      notifyCustomer: false,
    },
  });

  const handleSubmit = (formData: FormData) => {
    onSubmit(formData);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <SimpleGrid cols={1}>
        <EmployeeInput
          id="input-finish-jobs-employeeId"
          label="Finished by"
          {...form.getInputProps("employeeId")}
          disabled={loading}
        />
        <Checkbox
          id="input-finish-jobs-notifyCustomer"
          {...form.getInputProps("notifyCustomer", { type: "checkbox" })}
          label="Notify customers with SMS message"
          disabled={loading}
        />
        <Button id="btn-finish-jobs-submit" type="submit" loading={loading}>
          Finish Jobs
        </Button>
      </SimpleGrid>
    </form>
  );
};

export default UpdateJobsStatusForm;
