import React from "react";
import { Outlet, useLocation, useNavigate, Link } from "react-router-dom";
import {
  AppShell,
  Container,
  rem,
  Burger,
  Button,
  Group,
  NavLink,
  Center,
  ButtonProps,
  Image,
  Box,
  Text,
  Grid,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconHome,
  IconChartBar,
  IconCreditCard,
  IconUsers,
  IconMail,
  IconLogin,
  IconDashboard,
  IconBrandTwitter,
  IconBrandFacebook,
  IconBrandInstagram,
  IconPhone,
  IconMapPin,
} from "@tabler/icons-react";

import Logo from "../assets/largeColorLogo.png";
import { useAuth } from "../contexts/AuthContext";

const navLinks = [
  {
    link: "/",
    label: "Home",
    id: "home-nav-link",
    icon: <IconHome size={18} stroke={1.5} />,
  },
  {
    link: "/features",
    label: "Features",
    id: "features-nav-link",
    icon: <IconChartBar size={18} stroke={1.5} />,
  },
  {
    link: "/pricing",
    label: "Pricing",
    id: "pricing-nav-link",
    icon: <IconCreditCard size={18} stroke={1.5} />,
  },
  {
    link: "/team",
    label: "Team",
    id: "team-nav-link",
    icon: <IconUsers size={18} stroke={1.5} />,
  },
  {
    link: "/contact",
    label: "Contact",
    id: "contact-nav-link",
    icon: <IconMail size={18} stroke={1.5} />,
  },
];

const PublicLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const [opened, { toggle, close }] = useDisclosure();
  const theme = useMantineTheme();

  const topLinks = navLinks.map((link) => (
    <Button
      key={link.id}
      id={link.id}
      component={Link}
      to={link.link}
      variant={link.link === location.pathname ? "filled" : "subtle"}
      color={link.link === location.pathname ? "tsgBlue" : "tsgBlue"}
      size="sm"
      radius="md"
      leftSection={link.icon}
      styles={() => ({
        root: {
          fontWeight: 600,
          ...(link.link === location.pathname
            ? {
                backgroundColor: theme.colors.tsgBlue[6],
                color: "white",
                "&:hover": {
                  backgroundColor: theme.colors.tsgBlue[7],
                },
              }
            : {
                color: theme.colors.tsgBlue[7],
                "&:hover": {
                  backgroundColor: theme.colors.tsgBlue[0],
                },
              }),
        },
      })}
    >
      {link.label}
    </Button>
  ));

  const navBarLinks = navLinks.map((link) => (
    <NavLink
      key={link.id}
      id={link.id}
      ta="left"
      py="xs"
      pl="md"
      fw={600}
      active
      data-active={link.link === location.pathname}
      component={Link}
      onClick={() => {
        close();
      }}
      to={link.link}
      label={link.label}
      leftSection={link.icon}
      hiddenFrom="sm"
      styles={(theme) => ({
        root: {
          borderRadius: theme.radius.md,
          marginBottom: "4px",

          '&[dataActive="true"]': {
            backgroundColor: theme.colors.tsgBlue[0],
            color: theme.colors.tsgBlue[7],
            fontWeight: 700,
          },

          "&:hover": {
            backgroundColor: "rgba(232, 244, 253, 0.7)",
          },
        },
      })}
    />
  ));

  const AppButton: React.FC<ButtonProps> = (props) =>
    auth.isAuthenticated ? (
      <Button
        id="public-app-button"
        size="sm"
        onClick={() => navigate("/app")}
        color="white"
        variant="filled"
        radius="md"
        leftSection={<IconDashboard size={16} />}
        styles={{
          root: {
            backgroundColor: theme.colors.tsgGreen[6],
            fontWeight: 600,
            "&:hover": {
              backgroundColor: theme.colors.tsgGreen[7],
            },
          },
        }}
        {...props}
      >
        Go to App
      </Button>
    ) : (
      <Button
        id="public-login-button"
        data-test-id="login-button"
        size="sm"
        onClick={() => navigate("/auth/login")}
        color="white"
        variant="filled"
        radius="md"
        leftSection={<IconLogin size={16} />}
        styles={{
          root: {
            backgroundColor: theme.colors.tsgGreen[6],
            fontWeight: 600,
            "&:hover": {
              backgroundColor: theme.colors.tsgGreen[7],
            },
          },
        }}
        {...props}
      >
        Login
      </Button>
    );

  return (
    <>
      <AppShell
        header={{ height: rem(80) }}
        padding="md"
        navbar={{
          width: rem(250),
          breakpoint: "sm",
          collapsed: { mobile: !opened, desktop: true },
        }}
        styles={{
          main: {
            background: theme.colors.tsgBlue[0],
            minHeight: "calc(100vh - 80px)", // Only account for header height
          },
        }}
      >
        <AppShell.Header
          style={{
            background: theme.white,
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.08)",
            borderBottom: `1px solid ${theme.colors.gray[2]}`,
          }}
        >
          <Container size="lg" py="md">
            <Group justify="space-between" align="center" h="100%">
              <Group gap="md">
                <Burger
                  opened={opened}
                  onClick={toggle}
                  hiddenFrom="sm"
                  size="sm"
                  color={theme.colors.tsgBlue[6]}
                />
                <Box style={{ cursor: "pointer" }}>
                  <Image
                    id="homepage-logo"
                    onClick={() => {
                      close();
                      navigate("/");
                    }}
                    w={rem(180)}
                    src={Logo}
                    alt="Tennis Shop Guru"
                  />
                </Box>
              </Group>
              <Group gap="md" visibleFrom="sm">
                {topLinks}
                <AppButton />
              </Group>
            </Group>
          </Container>
        </AppShell.Header>
        <AppShell.Navbar
          style={{
            backgroundColor: theme.white,
            borderRight: `1px solid ${theme.colors.tsgBlue[1]}`,
          }}
        >
          <AppShell.Section grow p="md">
            <Box mb="lg" mt="md">
              <Image src={Logo} w={rem(140)} alt="Tennis Shop Guru" mx="auto" />
            </Box>
            {navBarLinks}
            <Center mt="xl">
              <AppButton m="md" fullWidth hiddenFrom="sm" />
            </Center>
          </AppShell.Section>
        </AppShell.Navbar>
        <AppShell.Main>
          <Container size="lg" py="xl">
            <Outlet />
          </Container>
        </AppShell.Main>
      </AppShell>

      {/* Footer (outside AppShell to make it part of normal document flow) */}
      <Box
        style={{
          borderTop: `1px solid ${theme.colors.gray[2]}`,
          backgroundColor: theme.white,
        }}
      >
        <Container size="lg" py="xl">
          <Box mb="xl">
            <Grid>
              {/* Company Info & Logo */}
              <Grid.Col span={{ base: 12, md: 5 }}>
                <Box mb="md">
                  <Image
                    src={Logo}
                    w={rem(160)}
                    alt="Tennis Shop Guru"
                    mb="sm"
                  />
                  <Text size="sm" c="dimmed" maw={350}>
                    TennisShopGuru helps tennis shop owners efficiently manage
                    court reservations, string jobs, and employee schedules in
                    one simple platform.
                  </Text>
                </Box>
                <Group mt="xl" gap="md">
                  <Box
                    component="a"
                    href="https://twitter.com"
                    target="_blank"
                    style={{
                      color: theme.colors.tsgBlue[6],
                      width: 36,
                      height: 36,
                      borderRadius: "50%",
                      backgroundColor: theme.colors.tsgBlue[0],
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IconBrandTwitter size={18} stroke={1.5} />
                  </Box>
                  <Box
                    component="a"
                    href="https://facebook.com"
                    target="_blank"
                    style={{
                      color: theme.colors.tsgBlue[6],
                      width: 36,
                      height: 36,
                      borderRadius: "50%",
                      backgroundColor: theme.colors.tsgBlue[0],
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IconBrandFacebook size={18} stroke={1.5} />
                  </Box>
                  <Box
                    component="a"
                    href="https://instagram.com"
                    target="_blank"
                    style={{
                      color: theme.colors.tsgBlue[6],
                      width: 36,
                      height: 36,
                      borderRadius: "50%",
                      backgroundColor: theme.colors.tsgBlue[0],
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IconBrandInstagram size={18} stroke={1.5} />
                  </Box>
                </Group>
              </Grid.Col>

              {/* Navigation Links */}
              <Grid.Col span={{ base: 6, md: 3 }}>
                <Text fw={700} mb="md">
                  Navigation
                </Text>
                {navLinks.map((link) => (
                  <Box
                    key={link.id}
                    component={Link}
                    to={link.link}
                    mb="xs"
                    style={{
                      display: "block",
                      color: theme.colors.tsgBlue[7],
                      textDecoration: "none",
                      "&:hover": { color: theme.colors.tsgBlue[6] },
                    }}
                  >
                    {link.label}
                  </Box>
                ))}
              </Grid.Col>

              {/* Contact Information */}
              <Grid.Col span={{ base: 6, md: 4 }}>
                <Text fw={700} mb="md">
                  Contact Us
                </Text>
                <Group gap="xs" mb="xs">
                  <IconMapPin size={18} color={theme.colors.tsgBlue[6]} />
                  <Text size="sm">123 Tennis Court Ave, California, USA</Text>
                </Group>
                <Group gap="xs" mb="xs">
                  <IconPhone size={18} color={theme.colors.tsgBlue[6]} />
                  <Text size="sm">+1 (555) 123-4567</Text>
                </Group>
                <Group gap="xs">
                  <IconMail size={18} color={theme.colors.tsgBlue[6]} />
                  <Text
                    size="sm"
                    component="a"
                    href="mailto:info@tennisshopguru.com"
                    style={{ color: theme.colors.tsgBlue[7] }}
                  >
                    info@tennisshopguru.com
                  </Text>
                </Group>
              </Grid.Col>
            </Grid>
          </Box>

          {/* Copyright Section */}
          <Box
            pt="md"
            style={{
              borderTop: `1px solid ${theme.colors.gray[2]}`,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              gap: theme.spacing.md,
            }}
          >
            <Text size="sm" c="dimmed">
              © {new Date().getFullYear()} TennisShopGuru. All rights reserved.
            </Text>
            <Group gap="md">
              <Text
                size="sm"
                c="dimmed"
                component={Link}
                to="/privacy"
                style={{ textDecoration: "none" }}
              >
                Privacy Policy
              </Text>
              <Text
                size="sm"
                c="dimmed"
                component={Link}
                to="/terms"
                style={{ textDecoration: "none" }}
              >
                Terms of Service
              </Text>
            </Group>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PublicLayout;
