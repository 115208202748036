import React from "react";
import moment from "moment";
import { Text, useMantineTheme, Badge, Card, Flex } from "@mantine/core";
import { useDrag } from "react-dnd";

import { ReservationFragment } from "../graphql";

// DND item types
const ItemTypes = {
  RESERVATION: "reservation",
  NEW_RESERVATION: "new_reservation",
};

// Draggable Reservation Component
const DraggableReservation: React.FC<{
  reservation: ReservationFragment;
  onReservationClick: (id: string) => void;
  canUpdate: boolean;
}> = ({ reservation, onReservationClick, canUpdate }) => {
  const theme = useMantineTheme();

  const getStatusColor = (status: string) => {
    switch (status) {
      case "CONFIRMED":
        return "green";
      case "PENDING":
        return "yellow";
      case "CANCELLED":
        return "red";
      case "COMPLETED":
        return "blue";
      default:
        return "gray";
    }
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.RESERVATION,
    item: {
      type: ItemTypes.RESERVATION,
      id: reservation.id,
      courtId: reservation.courtId,
      startTime: reservation.startTime,
      endTime: reservation.endTime,
      duration: moment(reservation.endTime).diff(
        moment(reservation.startTime),
        "minutes"
      ),
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: canUpdate,
  }));

  return (
    <Card
      ref={drag}
      shadow="sm"
      padding="xs"
      radius="sm"
      withBorder
      style={{
        marginBottom: "4px",
        cursor: canUpdate ? "move" : "pointer",
        backgroundColor: theme.colors[getStatusColor(reservation.status)][1],
        opacity: isDragging ? 0.4 : 0.8,
        position: "relative",
        zIndex: 15,
      }}
      onClick={() => {
        if (!isDragging) {
          onReservationClick(reservation.id);
        }
      }}
    >
      <Flex justify="space-between" align="center" mb={4}>
        <Text size="xs" fw={500}>
          {moment(reservation.startTime).format("h:mma")} -{" "}
          {moment(reservation.endTime).format("h:mma")}
        </Text>
        <Badge size="xs" color={getStatusColor(reservation.status)}>
          {reservation.status}
        </Badge>
      </Flex>
      {reservation.customer && (
        <Text size="xs" lineClamp={1}>
          {reservation.customer.firstName} {reservation.customer.lastName}
        </Text>
      )}
      {reservation.notes && (
        <Text size="xs" c="dimmed" lineClamp={1}>
          {reservation.notes}
        </Text>
      )}
    </Card>
  );
};

export default DraggableReservation;
