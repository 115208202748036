import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Title,
  Text,
  Button,
  Image,
  Stack,
  Box,
  useMantineTheme,
} from "@mantine/core";
import { IconCheck, IconLogin } from "@tabler/icons-react";
import { useConfirmSignUpMutation } from "../../graphql";
import { useAuth } from "../../contexts/AuthContext";

const SignUpConfirmPage: React.FC = () => {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const sub = search.get("sub") || "";
  const theme = useMantineTheme();

  const [confirmSignUp, { loading }] = useConfirmSignUpMutation();

  React.useEffect(() => {
    confirmSignUp({
      variables: {
        input: { sub },
      },
    });
  }, [sub, confirmSignUp]);

  return (
    <Box py="xl">
      <Stack align="center" gap="lg">
        <Box
          w={120}
          h={120}
          style={{
            borderRadius: "50%",
            background: `linear-gradient(135deg, ${theme.colors.tsgBlue[1]}, ${theme.colors.tsgBlue[0]})`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0 2px 15px rgba(0, 0, 0, 0.1)",
          }}
        >
          <IconCheck size={60} stroke={1.5} color={theme.colors.tsgGreen[6]} />
        </Box>

        <Title
          order={1}
          ta="center"
          style={{
            fontFamily: "Montserrat, sans-serif",
            fontWeight: 700,
            color: theme.colors.tsgBlue[7],
          }}
        >
          Welcome to <br />
          Tennis Shop Guru!
        </Title>

        <Text ta="center" size="lg" fw={500} c="dimmed">
          Thank you for confirming your email! You're now ready to streamline
          your tennis shop operations.
        </Text>

        <Image src="/img/rackets.png" alt="Rackets" width={200} my="md" />

        <Button
          loading={loading}
          fullWidth
          mt="md"
          size="xl"
          variant="gradient"
          gradient={{
            from: theme.colors.tsgGreen[5],
            to: theme.colors.tsgGreen[7],
          }}
          leftSection={<IconLogin size={20} />}
          onClick={() => {
            auth.saveReturnPath("/app");
            navigate("/auth/login");
          }}
          radius="md"
        >
          Log In to Your Account
        </Button>

        <Text size="sm" ta="center" c="dimmed" mt="xs">
          You will be redirected to the login page
        </Text>
      </Stack>
    </Box>
  );
};

export default SignUpConfirmPage;
