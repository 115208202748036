import { BusinessHour } from "../components/BusinessHoursSettings";

// Define the GraphQL business hours format
export interface BusinessHoursGraphQL {
  __typename?: "BusinessHours";
  daysOfWeek: Array<number>;
  startTime: string;
  endTime: string;
}

export interface BusinessHoursInput {
  daysOfWeek: Array<number>;
  startTime: string;
  endTime: string;
}

// Map day string to number (0-6)
const dayToNumber: { [key: string]: number } = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

// Map number to day string
const numberToDay: { [key: number]: BusinessHour["day"] } = {
  0: "SUNDAY",
  1: "MONDAY",
  2: "TUESDAY",
  3: "WEDNESDAY",
  4: "THURSDAY",
  5: "FRIDAY",
  6: "SATURDAY",
};

/**
 * Convert from GraphQL format to component format
 */
export function fromGraphQLFormat(
  businessHours: BusinessHoursGraphQL[] | null | undefined
): BusinessHour[] {
  if (!businessHours || !businessHours.length) {
    return [
      { day: "MONDAY", open: "09:00", close: "17:00", isClosed: false },
      { day: "TUESDAY", open: "09:00", close: "17:00", isClosed: false },
      { day: "WEDNESDAY", open: "09:00", close: "17:00", isClosed: false },
      { day: "THURSDAY", open: "09:00", close: "17:00", isClosed: false },
      { day: "FRIDAY", open: "09:00", close: "17:00", isClosed: false },
      { day: "SATURDAY", open: "09:00", close: "17:00", isClosed: true },
      { day: "SUNDAY", open: "09:00", close: "17:00", isClosed: true },
    ];
  }

  // Create default schedule with all days closed
  const result: BusinessHour[] = [
    { day: "MONDAY", open: "09:00", close: "17:00", isClosed: true },
    { day: "TUESDAY", open: "09:00", close: "17:00", isClosed: true },
    { day: "WEDNESDAY", open: "09:00", close: "17:00", isClosed: true },
    { day: "THURSDAY", open: "09:00", close: "17:00", isClosed: true },
    { day: "FRIDAY", open: "09:00", close: "17:00", isClosed: true },
    { day: "SATURDAY", open: "09:00", close: "17:00", isClosed: true },
    { day: "SUNDAY", open: "09:00", close: "17:00", isClosed: true },
  ];

  // Update with data from GraphQL format
  businessHours.forEach((hours) => {
    // Each hours object contains multiple days
    hours.daysOfWeek.forEach((dayNum) => {
      const day = numberToDay[dayNum];
      if (day) {
        const index = result.findIndex((h) => h.day === day);
        if (index !== -1) {
          result[index] = {
            day,
            open: hours.startTime,
            close: hours.endTime,
            isClosed: false,
          };
        }
      }
    });
  });

  return result;
}

/**
 * Convert from component format to GraphQL format
 */
export function toGraphQLFormat(
  businessHours: BusinessHour[]
): BusinessHoursInput[] {
  // Group by open/close time and isClosed status
  const timeGroups: { [key: string]: number[] } = {};

  businessHours.forEach((hour) => {
    if (hour.isClosed) {
      return; // Skip closed days
    }

    const key = `${hour.open}-${hour.close}`;
    if (!timeGroups[key]) {
      timeGroups[key] = [];
    }
    timeGroups[key].push(dayToNumber[hour.day]);
  });

  // Convert to GraphQL format
  return Object.entries(timeGroups).map(([key, days]) => {
    const [startTime, endTime] = key.split("-");
    return {
      daysOfWeek: days,
      startTime,
      endTime,
    };
  });
}
