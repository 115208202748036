import React, { useState } from "react";
import {
  Button,
  Group,
  Box,
  Textarea,
  Select,
  LoadingOverlay,
  Alert,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { IconAlertCircle } from "@tabler/icons-react";
import moment from "moment";

import {
  ReservationFragment,
  useCreateReservationMutation,
  useUpdateReservationMutation,
  useListCourtsQuery,
  useListCustomersQuery,
  ReservationStatus,
} from "../graphql";

interface FormValues {
  courtId: string;
  customerId: string;
  startTime: Date;
  endTime: Date;
  status: ReservationStatus;
  notes: string;
}

interface ReservationFormProps {
  reservation?: ReservationFragment;
  initialValues?: {
    courtId?: string;
    customerId?: string;
    startTime?: string;
    endTime?: string;
  };
  onSubmit: (id: string) => void;
}

const ReservationForm: React.FC<ReservationFormProps> = ({
  reservation,
  initialValues,
  onSubmit,
}) => {
  const [error, setError] = useState<string | null>(null);

  // Get courts and customers for dropdowns
  const courts = useListCourtsQuery({
    variables: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "ASC" },
    },
  });

  const customers = useListCustomersQuery({
    variables: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "lastName", order: "ASC" },
    },
  });

  // Set up mutations
  const [createReservation, { loading: createLoading }] =
    useCreateReservationMutation({
      onCompleted: (data) => {
        onSubmit(data.createReservation.id);
      },
      onError: (error) => {
        setError(error.message);
      },
    });

  const [updateReservation, { loading: updateLoading }] =
    useUpdateReservationMutation({
      onCompleted: (data) => {
        onSubmit(data.updateReservation.id);
      },
      onError: (error) => {
        setError(error.message);
      },
    });

  // Set up form
  const form = useForm<FormValues>({
    initialValues: {
      courtId: reservation?.courtId || initialValues?.courtId || "",
      customerId: reservation?.customerId || initialValues?.customerId || "",
      startTime: reservation?.startTime
        ? new Date(reservation.startTime)
        : initialValues?.startTime
          ? new Date(initialValues.startTime)
          : new Date(),
      endTime: reservation?.endTime
        ? new Date(reservation.endTime)
        : initialValues?.endTime
          ? new Date(initialValues.endTime)
          : moment().add(1, "hour").toDate(),
      status: reservation?.status || "CONFIRMED",
      notes: reservation?.notes || "",
    },
    validate: {
      courtId: (value: string) => (value ? null : "Court is required"),
      startTime: (value: Date) => (value ? null : "Start time is required"),
      endTime: (value: Date, values: FormValues) => {
        if (!value) return "End time is required";
        if (moment(value).isSameOrBefore(values.startTime)) {
          return "End time must be after start time";
        }
        return null;
      },
    },
  });

  const handleSubmit = form.onSubmit((values) => {
    setError(null);

    if (reservation) {
      // Update existing reservation
      updateReservation({
        variables: {
          input: {
            id: reservation.id,
            courtId: values.courtId,
            customerId: values.customerId || null,
            startTime: values.startTime.toISOString(),
            endTime: values.endTime.toISOString(),
            status: values.status,
            notes: values.notes || null,
          },
        },
      });
    } else {
      // Create new reservation
      createReservation({
        variables: {
          input: {
            courtId: values.courtId,
            customerId: values.customerId || null,
            startTime: values.startTime.toISOString(),
            endTime: values.endTime.toISOString(),
            status: values.status,
            notes: values.notes || null,
          },
        },
      });
    }
  });

  return (
    <Box pos="relative">
      <LoadingOverlay visible={createLoading || updateLoading} />
      {error && (
        <Alert
          icon={<IconAlertCircle size={16} />}
          title="Error"
          color="red"
          mb="md"
        >
          {error}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <Select
          label="Court"
          placeholder="Select a court"
          data={
            courts.data?.listCourts.data.map((court) => ({
              value: court.id,
              label: court.name,
            })) || []
          }
          required
          searchable
          mb="md"
          {...form.getInputProps("courtId")}
          disabled={courts.loading}
        />

        <Select
          label="Customer"
          placeholder="Select a customer (optional)"
          data={
            customers.data?.listCustomers.data.map((customer) => ({
              value: customer.id,
              label: `${customer.lastName}, ${customer.firstName}`,
            })) || []
          }
          searchable
          clearable
          mb="md"
          {...form.getInputProps("customerId")}
          disabled={customers.loading}
        />

        <Group grow mb="md">
          <DateTimePicker
            label="Start Time"
            placeholder="Select start time"
            required
            {...form.getInputProps("startTime")}
          />

          <DateTimePicker
            label="End Time"
            placeholder="Select end time"
            required
            {...form.getInputProps("endTime")}
          />
        </Group>

        <Select
          label="Status"
          placeholder="Select status"
          data={[
            { value: "CONFIRMED", label: "Confirmed" },
            { value: "PENDING", label: "Pending" },
            { value: "CANCELLED", label: "Cancelled" },
            { value: "COMPLETED", label: "Completed" },
          ]}
          required
          mb="md"
          {...form.getInputProps("status")}
        />

        <Textarea
          label="Notes"
          placeholder="Add any additional notes"
          mb="md"
          {...form.getInputProps("notes")}
        />

        <Group justify="flex-end">
          <Button type="submit">
            {reservation ? "Update Reservation" : "Create Reservation"}
          </Button>
        </Group>
      </form>
    </Box>
  );
};

export default ReservationForm;
