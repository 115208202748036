import React from "react";
import {
  Box,
  Button,
  Flex,
  Group,
  List,
  Paper,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { IconStarFilled } from "@tabler/icons-react";

import { BillingInterval, useListPlansQuery } from "../graphql";

interface Props {
  onPlanSelect?: (planId: string) => void;
  currentPlanId?: string;
  currentPriceId?: string;
  showInterval?: boolean;
}

const PlanSelector: React.FC<Props> = ({
  onPlanSelect,
  currentPlanId,
  currentPriceId,
  showInterval,
}) => {
  const theme = useMantineTheme();
  const plansResponse = useListPlansQuery();
  const [interval, setInterval] = React.useState<BillingInterval>("MONTHLY");
  const plans = plansResponse.data?.listPlans || [];

  return (
    <>
      <Group justify="center" mb="md">
        {showInterval && (
          <SegmentedControl
            data={[
              { value: "MONTHLY", label: "Monthly" },
              { value: "YEARLY", label: "Yearly" },
            ]}
            value={interval}
            onChange={(value) =>
              setInterval(value === "MONTHLY" ? "MONTHLY" : "YEARLY")
            }
          />
        )}
      </Group>
      <SimpleGrid cols={{ sm: 1, md: plans.length }} spacing="md">
        {plans.map((plan) => {
          const price = plan.prices.find(
            (price) => price.billingInterval === interval
          );
          return (
            <Paper key={plan.id} mih="100%" p="md" shadow="none">
              <Flex direction="column" mih="100%" gap="md">
                <Box>
                  <Group justify="space-between">
                    <Title order={3}>{plan.name}</Title>
                    {currentPlanId && currentPlanId === plan.id && (
                      <Group gap="xs">
                        <Text c="dimmed">Current Plan</Text>
                        <IconStarFilled
                          color={theme.colors.yellow[5]}
                          size={24}
                        />
                      </Group>
                    )}
                  </Group>
                  <Text>{plan.description}</Text>
                </Box>

                <Box style={{ flexGrow: 2 }}>
                  <Title order={4}>Features</Title>
                  <List>
                    {plan.features.map((feature) => (
                      <List.Item key={feature.id}>{feature.name}</List.Item>
                    ))}
                  </List>
                </Box>

                <Stack gap="md">
                  {price?.id === currentPriceId && (
                    <Group gap="xs" mx="auto">
                      <IconStarFilled
                        color={theme.colors.yellow[5]}
                        size={24}
                      />
                      <Text c="dimmed">Current Price</Text>
                    </Group>
                  )}
                  {price && (
                    <Button
                      key={price.id}
                      onClick={() => {
                        onPlanSelect?.(price.id);
                      }}
                      disabled={price.id === currentPriceId}
                    >
                      ${price.amount} / {price.billingInterval.toLowerCase()}
                    </Button>
                  )}
                </Stack>
              </Flex>
            </Paper>
          );
        })}
      </SimpleGrid>
    </>
  );
};

export default PlanSelector;
