import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";

import "./main.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/charts/styles.css";

import { AuthProvider } from "./contexts/AuthContext";
import client from "./graphql/client";
import App from "./App";
import { theme } from "./utils/theme";
import config from "./utils/config";

Sentry.init({
  dsn: "https://648ab9d87287a9bccba55b50c5b66f45@o1094986.ingest.us.sentry.io/4506892823822336",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: config.ENV,
  release: config.SHA,
  enabled: config.ENV !== "local",
});

if (config.ENV !== "local") {
  LogRocket.init("pigh49/tennis-shop-guru", {
    release: config.SHA,
    console: {
      isEnabled: true,
    },
    network: {
      isEnabled: true,
    },
    dom: {
      isEnabled: true,
      textSanitizer: false,
      inputSanitizer: false,
    },
    shouldCaptureIP: true,
  });
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.getCurrentScope().setExtra("sessionURL", sessionURL);
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <AuthProvider>
        <ApolloProvider client={client}>
          <ModalsProvider>
            <Notifications />
            <App />
          </ModalsProvider>
        </ApolloProvider>
      </AuthProvider>
    </MantineProvider>
  </React.StrictMode>
);
