import React from "react";
import {
  SimpleGrid,
  TextInput,
  Text,
  useMantineTheme,
  Button,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  IconMail,
  IconUser,
  IconPhone,
  IconBuildingStore,
} from "@tabler/icons-react";

import { SignUpResult, useSignUpMutation } from "../graphql";
import AdvancedPasswordInput from "./AdvancedPasswordInput";

interface FormData {
  firstName: string;
  lastName: string;
  companyName: string;
  phoneNumber: string;
  email: string;
  password: string;
}

interface Props {
  onSuccess: (result: SignUpResult) => void;
}

const SignUpForm: React.FC<Props> = ({ onSuccess }) => {
  const theme = useMantineTheme();

  const [signUp, { loading, error }] = useSignUpMutation({
    onCompleted: (data) => {
      onSuccess(data.signUp);
    },
  });

  const form = useForm<FormData>({
    initialValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      phoneNumber: "",
      email: "",
      password: "",
    },
  });

  const handleSubmit = (data: FormData) => {
    signUp({
      variables: {
        input: {
          firstName: data.firstName,
          lastName: data.lastName,
          companyName: data.companyName,
          phone: data.phoneNumber,
          email: data.email,
          password: data.password,
        },
      },
    });
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <SimpleGrid cols={{ base: 1, sm: 2 }} mt="xl">
        <TextInput
          disabled={loading}
          label="First Name"
          placeholder="Enter your first name"
          required
          leftSection={<IconUser size={16} color={theme.colors.tsgBlue[5]} />}
          {...form.getInputProps("firstName")}
        />
        <TextInput
          disabled={loading}
          label="Last Name"
          placeholder="Enter your last name"
          required
          leftSection={<IconUser size={16} color={theme.colors.tsgBlue[5]} />}
          {...form.getInputProps("lastName")}
        />
        <TextInput
          disabled={loading}
          label="Company Name"
          placeholder="Your tennis shop name"
          required
          leftSection={
            <IconBuildingStore size={16} color={theme.colors.tsgBlue[5]} />
          }
          {...form.getInputProps("companyName")}
        />
        <TextInput
          disabled={loading}
          label="Phone Number"
          placeholder="(123) 456-7890"
          type="tel"
          required
          leftSection={<IconPhone size={16} color={theme.colors.tsgBlue[5]} />}
          {...form.getInputProps("phoneNumber")}
        />
        <TextInput
          disabled={loading}
          label="Email"
          placeholder="your.email@example.com"
          type="email"
          required
          leftSection={<IconMail size={16} color={theme.colors.tsgBlue[5]} />}
          {...form.getInputProps("email")}
        />
        <AdvancedPasswordInput
          disabled={loading}
          label="Password"
          placeholder="Create a secure password"
          type="password"
          required
          {...form.getInputProps("password")}
        />
      </SimpleGrid>

      {error && (
        <Text c="red.7" mt="md" fw={500} size="sm">
          {error.message}
        </Text>
      )}

      <Button
        type="submit"
        mt="xl"
        fullWidth
        loading={loading}
        disabled={loading}
      >
        Sign Up
      </Button>
    </form>
  );
};

export default SignUpForm;
