import React from "react";
import { Paper } from "@mantine/core";
import AccountForm from "../components/AccountForm";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAccountQuery } from "../graphql";
import StyledPageTitle from "../components/StyledPageTitle";

const AccountsUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const getAccount = useGetAccountQuery({
    variables: { id: `${params.id}` },
  });

  const account = getAccount.data?.getAccount;

  return (
    <>
      <StyledPageTitle>Update Account</StyledPageTitle>
      <Paper>
        {account && (
          <AccountForm
            account={account}
            onSubmit={(id) => navigate(`/app/accounts/${id}`)}
          />
        )}
      </Paper>
    </>
  );
};

export default AccountsUpdatePage;
