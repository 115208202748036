import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const ReportsLayout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  // Handle authentication redirect in useEffect to avoid state updates during render
  React.useEffect(() => {
    if (!auth.isAuthenticated) {
      // Save the return path and navigate to the login page
      auth.saveReturnPath(`${location.pathname}${location.search}`);
      navigate("/auth/login");
    }
  }, [
    auth.isAuthenticated,
    auth,
    location.pathname,
    location.search,
    navigate,
  ]);

  // If not authenticated, render a loading state
  if (!auth.isAuthenticated) {
    return <></>;
  }

  return <Outlet />;
};

export default ReportsLayout;
