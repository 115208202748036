import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  PasswordInput,
  Button,
  Title,
  Text,
  Anchor,
  Stack,
  Alert,
  ThemeIcon,
} from "@mantine/core";
import { IconAlertCircle, IconCheck } from "@tabler/icons-react";
import { useAcceptInvitationMutation } from "../../graphql";

const AcceptInvitePage: React.FC = () => {
  const location = useLocation();
  const [acceptInvitation, acceptInvitationResponse] =
    useAcceptInvitationMutation();
  const [token, setToken] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    // Extract token from URL query parameters
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get("token");
    if (tokenParam) {
      setToken(tokenParam);
    } else {
      setError(
        "Invitation token is missing. Please use the link from your email."
      );
    }
  }, [location.search]);

  const validatePassword = (password: string): boolean => {
    return password.length >= 8;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    // Validate passwords
    if (!validatePassword(password)) {
      setError("Password must be at least 8 characters long");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const success = await acceptInvitation({
        variables: {
          input: {
            token,
            password,
          },
        },
      });

      if (success) {
        setIsSuccess(true);
      } else {
        setError("Failed to accept invitation. The link may have expired.");
      }
    } catch (err: unknown) {
      const errorMessage =
        err instanceof Error
          ? err.message
          : "An error occurred. Please try again.";
      setError(errorMessage);
      console.error(err);
    }
  };

  if (isSuccess) {
    return (
      <>
        <Title ta="center" fw={900} mb="lg">
          Invitation accepted
        </Title>

        <Stack align="center" gap="md">
          <ThemeIcon color="green" size="xl" radius="xl">
            <IconCheck size="1.5rem" />
          </ThemeIcon>

          <Text size="sm" c="dimmed" ta="center">
            Your account has been set up successfully. You can now log in with
            your email and the password you just created.
          </Text>

          <Button component={Link} to="/auth/login">
            Go to login
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <>
      <Title ta="center" fw={900} mb="lg">
        Accept invitation
      </Title>

      <form onSubmit={handleSubmit}>
        {error && (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Error"
            color="red"
            mb="md"
          >
            {error}
          </Alert>
        )}

        <Stack>
          <PasswordInput
            label="New password"
            placeholder="Your new password"
            required
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            description="Password must be at least 8 characters long"
          />

          <PasswordInput
            label="Confirm password"
            placeholder="Confirm your new password"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
          />

          <Button
            type="submit"
            fullWidth
            loading={acceptInvitationResponse.loading}
            disabled={!token}
          >
            Accept invitation
          </Button>
        </Stack>
      </form>

      <Text ta="center" mt="md">
        <Anchor component={Link} to="/auth/login">
          Back to login
        </Anchor>
      </Text>
    </>
  );
};

export default AcceptInvitePage;
