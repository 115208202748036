import React from "react";
import {
  Button,
  CloseButton,
  Group,
  Pagination,
  Paper,
  Skeleton,
  Table,
  TextInput,
  rem,
} from "@mantine/core";
import StyledPageTitle from "../components/StyledPageTitle";
import { EmployeeFilter, useListEmployeesQuery } from "../graphql";
import DateDisplay from "../components/DateDisplay";
import TableHeaderCell from "../components/TableHeaderCell";
import useListVariables from "../hooks/useListVariables";
import { IconPlus, IconSearch } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "../contexts/PermissionsContext";

const headers = [
  { label: "First Name", field: "firstName" },
  { label: "Last Name", field: "lastName" },
  { label: "Email", field: "email" },
  { label: "Role", field: "role" },
  { label: "Status", field: "status" },
  { label: "Last Updated", field: "updatedAt" },
];

const EmployeesPage: React.FC = () => {
  const navigate = useNavigate();
  const permissions = usePermissions();
  const listVariables = useListVariables<EmployeeFilter>({
    defaultSort: { field: "lastName", order: "ASC" },
  });
  const employees = useListEmployeesQuery({
    variables: {
      pagination: listVariables.pagination,
      sort: listVariables.sort,
      filter: listVariables.filter,
    },
  });

  return (
    <>
      <Group justify="space-between" mb="sm">
        <StyledPageTitle>Employees</StyledPageTitle>
        <Group>
          <TextInput
            w={300}
            leftSection={<IconSearch />}
            placeholder="Search"
            value={listVariables.filter?.q || ""}
            onChange={(e) =>
              listVariables.setFilter({
                q: e.currentTarget.value || undefined,
              })
            }
            rightSection={
              listVariables.filter?.q && (
                <CloseButton
                  onClick={() => listVariables.setFilter({ q: undefined })}
                />
              )
            }
          />
          <Button
            rightSection={<IconPlus />}
            onClick={() => navigate("/app/employees/create")}
            display={permissions.can("createEmployee") ? undefined : "none"}
          >
            Create
          </Button>
        </Group>
      </Group>
      <Paper w="100%">
        <Table.ScrollContainer minWidth={500}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                {headers.map((header) => (
                  <TableHeaderCell
                    key={header.field}
                    label={header.label}
                    field={header.field}
                    vars={listVariables}
                  />
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {employees.data?.listEmployees.data.map((employee) => (
                <Table.Tr
                  key={employee.id}
                  onClick={() => navigate(`/app/employees/${employee.id}`)}
                >
                  <Table.Td>{employee.firstName}</Table.Td>
                  <Table.Td>{employee.lastName}</Table.Td>
                  <Table.Td>{employee.email}</Table.Td>
                  <Table.Td>{employee.role}</Table.Td>
                  <Table.Td>{employee.status}</Table.Td>
                  <Table.Td>
                    <DateDisplay value={employee.updatedAt} />
                  </Table.Td>
                </Table.Tr>
              ))}
              {employees.loading &&
                Array.from({ length: listVariables.pagination.perPage }).map(
                  (_, index) => (
                    <Table.Tr key={index}>
                      {Array.from({ length: 6 }).map((_, index) => (
                        <Table.Td key={index}>
                          <Skeleton height={22} radius="none" />
                        </Table.Td>
                      ))}
                    </Table.Tr>
                  )
                )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
        <Group justify="space-evenly">
          <Pagination
            hidden={
              !employees.data?.listEmployees.total ||
              employees.data?.listEmployees.total <
                listVariables.pagination.perPage
            }
            mt={rem(20)}
            value={listVariables.pagination.page}
            onChange={listVariables.setPage}
            total={
              (employees.data?.listEmployees.total || 0) /
              listVariables.pagination.perPage
            }
          />
        </Group>
      </Paper>
    </>
  );
};

export default EmployeesPage;
