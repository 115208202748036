import React from "react";
import {
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
  useMantineTheme,
  Box,
  Image,
  Group,
  ThemeIcon,
} from "@mantine/core";
import {
  IconHistory,
  IconDeviceMobileMessage,
  IconClock,
  IconTallymarks,
  IconBallTennis,
  IconReportAnalytics,
} from "@tabler/icons-react";
import classes from "./FeaturesPage.module.css";

const mockdata = [
  {
    title: "Customer Racket String Job History",
    description:
      "Keep track of your customers' racket stringing preferences and history effortlessly. Tennis Shop Guru allows you to maintain detailed records of string jobs for each customer, ensuring personalized service and accurate recommendations for future stringing.",
    icon: IconHistory,
  },
  {
    title: "Customer SMS Notifications",
    description:
      "Enhance communication with your customers by providing real-time updates on their stringing jobs via SMS notifications. Keep them informed about the status of their racket, ensuring a seamless and transparent experience.",
    icon: IconDeviceMobileMessage,
  },
  {
    title: "Employee Hours Tracker",
    description:
      "Efficiently manage your staff's time with our intuitive employee hours tracker. Easily record work shifts, track attendance, and generate comprehensive time cards, simplifying payroll processing and ensuring accountability.",
    icon: IconClock,
  },
  {
    title: "Employee String Job Tracker",
    description:
      "Empower tennis shop owners with insights into employee performance and productivity. Our string job tracker allows you to monitor the number of string jobs completed by each employee, facilitating fair compensation and optimizing workflow management.",
    icon: IconTallymarks,
  },
  {
    title: "Court Reservations",
    description:
      "Simplify court management and reservation processes with Tennis Shop Guru. Enable customers to book courts online seamlessly, manage reservations efficiently, and maximize court utilization, enhancing overall customer satisfaction and revenue generation.",
    icon: IconBallTennis,
  },
  {
    title: "Reporting",
    description:
      "Gain valuable insights into your tennis shop's performance with robust reporting capabilities. Tennis Shop Guru provides comprehensive reports on stringing jobs, employee productivity, court utilization, and more, empowering you to make informed business decisions and drive growth.",
    icon: IconReportAnalytics,
  },
];

const FeaturesPage: React.FC = () => {
  const theme = useMantineTheme();
  const features = mockdata.map((feature, index) => (
    <Card
      key={feature.title}
      shadow="md"
      radius="lg"
      className={classes.card}
      padding="xl"
      style={{
        borderTop: `4px solid ${theme.colors.tsgBlue[6]}`,
        transform: index % 2 === 0 ? "translateY(-10px)" : "none",
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: theme.shadows.lg,
        },
      }}
    >
      <ThemeIcon
        size={90}
        radius="md"
        variant="light"
        style={{
          background: `linear-gradient(135deg, ${theme.colors.tsgBlue[0]} 0%, ${theme.colors.tsgBlue[1]} 100%)`,
          color: theme.colors.tsgBlue[6],
          marginBottom: theme.spacing.md,
        }}
      >
        <feature.icon
          style={{ width: rem(40), height: rem(40) }}
          stroke={1.5}
        />
      </ThemeIcon>

      <Text
        fz="xl"
        fw={700}
        className={classes.cardTitle}
        mt="md"
        c={theme.colors.tsgBlue[7]}
      >
        {feature.title}
      </Text>
      <Text fz="md" c="dimmed" mt="sm" style={{ lineHeight: 1.6 }}>
        {feature.description}
      </Text>
    </Card>
  ));

  return (
    <>
      {/* Hero section with background */}
      <Box
        style={{
          background: `linear-gradient(135deg, ${theme.colors.tsgBlue[7]} 0%, ${theme.colors.tsgBlue[5]} 100%)`,
          color: "white",
          borderRadius: theme.radius.md,

          paddingTop: "3rem",
          paddingBottom: "3rem",
          marginBottom: theme.spacing.xl,
        }}
      >
        <Container size="lg">
          <Group justify="space-between" align="flex-start">
            <Box style={{ maxWidth: "600px" }}>
              <Title
                order={1}
                className={classes.title}
                style={{ color: "white" }}
              >
                Your Tennis Shop,{" "}
                <span style={{ color: theme.colors.tsgGreen[4] }}>
                  Simplified
                </span>
              </Title>
              <Text
                className={classes.description}
                style={{ color: "rgba(255, 255, 255, 0.8)", maxWidth: "100%" }}
                mt="md"
              >
                Tennis Shop Guru offers a comprehensive suite of features
                designed to streamline operations and enhance customer
                satisfaction for tennis shop owners. Whether you're managing
                customer string jobs, coordinating court reservations, or
                monitoring employee productivity, Tennis Shop Guru has you
                covered.
              </Text>
            </Box>
            <Image
              src="/img/shop-clothes.png"
              alt="Tennis shop"
              maw={300}
              style={{
                borderRadius: theme.radius.md,
                maxWidth: "100%",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              }}
              visibleFrom="md"
            />
          </Group>
        </Container>
      </Box>

      {/* Features grid */}
      <Container size="lg" pb="xl">
        <Title
          order={2}
          ta="center"
          mb="xl"
          style={{
            color: theme.colors.tsgBlue[7],
            position: "relative",
            paddingBottom: theme.spacing.md,
          }}
        >
          Powerful Features For Your Tennis Shop
          <Box
            style={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              width: "120px",
              height: "4px",
              backgroundColor: theme.colors.tsgGreen[6],
              borderRadius: "2px",
            }}
          />
        </Title>

        <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl">
          {features}
        </SimpleGrid>
      </Container>
    </>
  );
};

export default FeaturesPage;
