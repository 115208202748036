import React from "react";
import { Title, TitleProps, useMantineTheme } from "@mantine/core";

interface StyledPageTitleProps extends Omit<TitleProps, "children"> {
  children: React.ReactNode;
}

const StyledPageTitle: React.FC<StyledPageTitleProps> = ({
  children,
  order = 1,
  mb = "md",
  ...props
}) => {
  const theme = useMantineTheme();

  return (
    <Title
      order={order}
      mb={mb}
      style={{
        color: theme.colors.tsgBlue[7],
        fontFamily: "'Montserrat', var(--mantine-font-family)",
      }}
      {...props}
    >
      {children}
    </Title>
  );
};

export default StyledPageTitle;
