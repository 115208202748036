import React, { useState } from "react";
import {
  Box,
  Title,
  Grid,
  Text,
  Switch,
  Paper,
  Divider,
  useMantineTheme,
  TextInput,
} from "@mantine/core";
import { IconClock } from "@tabler/icons-react";

export interface BusinessHour {
  day:
    | "MONDAY"
    | "TUESDAY"
    | "WEDNESDAY"
    | "THURSDAY"
    | "FRIDAY"
    | "SATURDAY"
    | "SUNDAY";
  open: string; // Format: "HH:MM"
  close: string; // Format: "HH:MM"
  isClosed: boolean;
}

interface BusinessHoursSettingsProps {
  value: BusinessHour[];
  onChange: (hours: BusinessHour[]) => void;
}

const DAYS_OF_WEEK = [
  { value: "MONDAY", label: "Monday" },
  { value: "TUESDAY", label: "Tuesday" },
  { value: "WEDNESDAY", label: "Wednesday" },
  { value: "THURSDAY", label: "Thursday" },
  { value: "FRIDAY", label: "Friday" },
  { value: "SATURDAY", label: "Saturday" },
  { value: "SUNDAY", label: "Sunday" },
] as const;

const DEFAULT_HOURS: BusinessHour[] = DAYS_OF_WEEK.map((day) => ({
  day: day.value,
  open: "09:00",
  close: "17:00",
  isClosed: day.value === "SUNDAY" || day.value === "SATURDAY",
}));

const BusinessHoursSettings: React.FC<BusinessHoursSettingsProps> = ({
  value = DEFAULT_HOURS,
  onChange,
}) => {
  const theme = useMantineTheme();
  const [hours, setHours] = useState<BusinessHour[]>(
    value.length ? value : DEFAULT_HOURS
  );

  const updateHours = (updatedHour: Partial<BusinessHour>, index: number) => {
    const newHours = [...hours];
    newHours[index] = { ...newHours[index], ...updatedHour };
    setHours(newHours);
    onChange(newHours);
  };

  // Simple validation for time format (HH:MM)
  const validateTime = (time: string): boolean => {
    const regex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return regex.test(time);
  };

  return (
    <Box>
      <Title order={4} mb="md">
        Business Hours
      </Title>
      <Paper withBorder p="md" radius="md">
        <Grid columns={24}>
          <Grid.Col span={8}>
            <Text fw={700} size="sm" c={theme.colors.tsgBlue[7]}>
              Day
            </Text>
          </Grid.Col>
          <Grid.Col span={7}>
            <Text fw={700} size="sm" c={theme.colors.tsgBlue[7]}>
              Opening Time
            </Text>
          </Grid.Col>
          <Grid.Col span={7}>
            <Text fw={700} size="sm" c={theme.colors.tsgBlue[7]}>
              Closing Time
            </Text>
          </Grid.Col>
          <Grid.Col span={2}>
            <Text fw={700} size="sm" c={theme.colors.tsgBlue[7]}>
              Open
            </Text>
          </Grid.Col>
        </Grid>
        <Divider my="xs" />

        {hours.map((hour, index) => (
          <Grid columns={24} key={hour.day} align="center" my="xs">
            <Grid.Col span={8}>
              <Text>
                {DAYS_OF_WEEK.find((d) => d.value === hour.day)?.label}
              </Text>
            </Grid.Col>

            <Grid.Col span={7}>
              <TextInput
                disabled={hour.isClosed}
                value={hour.open}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  updateHours({ open: value }, index);
                }}
                leftSection={<IconClock size={16} />}
                size="xs"
                placeholder="HH:MM"
                error={
                  !validateTime(hour.open) && !hour.isClosed
                    ? "Use format HH:MM"
                    : null
                }
              />
            </Grid.Col>

            <Grid.Col span={7}>
              <TextInput
                disabled={hour.isClosed}
                value={hour.close}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  updateHours({ close: value }, index);
                }}
                leftSection={<IconClock size={16} />}
                size="xs"
                placeholder="HH:MM"
                error={
                  !validateTime(hour.close) && !hour.isClosed
                    ? "Use format HH:MM"
                    : null
                }
              />
            </Grid.Col>

            <Grid.Col span={2}>
              <Switch
                checked={!hour.isClosed}
                onChange={(e) =>
                  updateHours({ isClosed: !e.currentTarget.checked }, index)
                }
                color="tsgGreen"
              />
            </Grid.Col>
          </Grid>
        ))}
      </Paper>
      <Text size="xs" c="dimmed" mt="xs">
        Set your tennis shop's regular business hours. These hours will be
        displayed to your customers.
      </Text>
    </Box>
  );
};

export default BusinessHoursSettings;
