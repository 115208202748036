import { createTheme } from "@mantine/core";

// TennisShopGuru brand colors based on logo
const tsgBlue = "#0088c2"; // Main blue color for TennisShop text
const tsgLight = "#f5f9fc"; // Light blue-tinted background

export const theme = createTheme({
  primaryColor: "tsgBlue",
  primaryShade: 6,
  fontFamily: "'Montserrat', 'Roboto', sans-serif",
  headings: {
    fontFamily: "'Montserrat', 'Roboto', sans-serif",
    fontWeight: "600",
  },
  colors: {
    // Custom brand colors with variations for Mantine
    tsgBlue: [
      "#e0f4ff",
      "#c0e8ff",
      "#9fd4ec",
      "#7ac0df",
      "#4eabd2",
      "#2c97c5",
      "#0088c2",
      "#0075ad",
      "#006394",
      "#00507b",
    ],
    tsgGreen: [
      "#e6f9ef",
      "#cdf3df",
      "#a6e9c6",
      "#7ed7aa",
      "#57c68e",
      "#3cb371",
      "#28b463",
      "#1d9c54",
      "#148544",
      "#086f35",
    ],
    tsgYellow: [
      "#fff9e0",
      "#fff3c0",
      "#ffe89f",
      "#ffdd7f",
      "#ffd15e",
      "#ffca40",
      "#ffc422",
      "#e6af0e",
      "#cc9900",
      "#a67c00",
    ],
  },
  shadows: {
    md: "0 4px 8px rgba(0, 0, 0, 0.15)",
    xl: "0 8px 16px rgba(0, 0, 0, 0.18)",
  },
  components: {
    SimpleGrid: {
      defaultProps: {
        cols: {
          sm: 1,
          md: 2,
        },
      },
    },
    Title: {
      defaultProps: {
        color: tsgBlue,
      },
      styles: {
        root: {
          fontWeight: 600,
        },
      },
    },
    Paper: {
      defaultProps: {
        shadow: "md",
        p: "lg",
        radius: "md",
        withBorder: true,
        bg: tsgLight,
      },
    },
    Button: {
      defaultProps: {
        variant: "filled",
        color: "tsgGreen.6",
        radius: "md",
      },
      styles: {
        root: {
          fontWeight: 600,
          transition: "transform 0.2s ease, box-shadow 0.2s ease",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        },
      },
    },
    Table: {
      defaultProps: {
        stickyHeader: true,
        highlightOnHover: true,
        withTableBorder: true,
        borderColor: "tsgBlue.2",
      },
      styles: {
        th: {
          backgroundColor: "var(--mantine-color-tsgBlue-0)",
          color: "var(--mantine-color-tsgBlue-8)",
          fontWeight: 600,
        },
      },
    },
    NavLink: {
      defaultProps: {
        color: "tsgBlue.7",
      },
      styles: {
        root: {
          "&[dataActive]": {
            backgroundColor: "var(--mantine-color-tsgBlue-0)",
            borderLeftColor: "var(--mantine-color-tsgBlue-7)",
            fontWeight: 600,
          },
        },
      },
    },
    Card: {
      defaultProps: {
        shadow: "sm",
        padding: "lg",
        radius: "md",
        withBorder: true,
      },
      styles: {
        root: {
          transition: "transform 0.2s ease, box-shadow 0.2s ease",
          "&:hover": {
            transform: "translateY(-4px)",
            boxShadow: "0 5px 12px rgba(0, 0, 0, 0.15)",
          },
        },
      },
    },
    Badge: {
      defaultProps: {
        color: "tsgBlue",
        variant: "light",
      },
    },
    Tabs: {
      defaultProps: {
        color: "tsgBlue",
      },
      styles: {
        tab: {
          transition: "background-color 0.2s ease",
          "&[dataActive]": {
            borderColor: "var(--mantine-color-tsgBlue-6)",
            fontWeight: 600,
          },
        },
      },
    },
    Notification: {
      styles: {
        root: {
          borderRadius: "0.5rem",
        },
      },
    },
  },
});
