import { Select, SelectProps } from "@mantine/core";
import React from "react";
import { useListPlansQuery } from "../graphql";

const PlanPricingInput: React.FC<SelectProps> = (props) => {
  const listPlans = useListPlansQuery();
  const plans = listPlans.data?.listPlans || [];

  const pricingOptions = plans.map((plan) => ({
    group: plan.name,
    items: plan.prices.map((price) => ({
      value: price.id,
      label: `${plan.name} -- $${(price.amount || 0) / 100} / ${
        price.billingInterval
      }`,
    })),
  }));

  return <Select label="Plan" data={pricingOptions} {...props} />;
};

export default PlanPricingInput;
