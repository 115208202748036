import React, { useState } from "react";
import {
  Modal,
  Button,
  Group,
  Select,
  TextInput,
  LoadingOverlay,
  Alert,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { IconAlertCircle } from "@tabler/icons-react";

import {
  useCreateReservationMutation,
  useListCustomersQuery,
} from "../graphql";

interface QuickReservationModalProps {
  opened: boolean;
  onClose: () => void;
  onSuccess: () => void;
  courtId: string;
  courtName: string;
  startTime: Date;
  endTime: Date;
}

const QuickReservationModal: React.FC<QuickReservationModalProps> = ({
  opened,
  onClose,
  onSuccess,
  courtId,
  courtName,
  startTime,
  endTime,
}) => {
  const [error, setError] = useState<string | null>(null);

  // Get customers for dropdown
  const customers = useListCustomersQuery({
    variables: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "lastName", order: "ASC" },
    },
  });

  // Set up mutation
  const [createReservation, { loading }] = useCreateReservationMutation({
    onCompleted: () => {
      onSuccess();
    },
    onError: (error) => {
      setError(error.message);
    },
  });

  // Set up form
  const form = useForm({
    initialValues: {
      customerId: "",
      customerName: "", // For custom customer name input
    },
  });

  const handleSubmit = form.onSubmit((values) => {
    setError(null);

    createReservation({
      variables: {
        input: {
          courtId,
          customerId: values.customerId || null,
          startTime: startTime.toISOString(),
          endTime: endTime.toISOString(),
          status: "CONFIRMED",
          notes: values.customerName
            ? `Customer: ${values.customerName}`
            : null,
        },
      },
    });
  });

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Quick Reservation"
      size="md"
      centered
    >
      <div style={{ position: "relative" }}>
        <LoadingOverlay visible={loading} />
        {error && (
          <Alert
            icon={<IconAlertCircle size={16} />}
            title="Error"
            color="red"
            mb="md"
          >
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <TextInput label="Court" value={courtName} readOnly mb="md" />

          <Group grow mb="md">
            <DateTimePicker label="Start Time" value={startTime} readOnly />

            <DateTimePicker label="End Time" value={endTime} readOnly />
          </Group>

          <Select
            label="Customer"
            placeholder="Select a customer (optional)"
            data={
              customers.data?.listCustomers.data.map((customer) => ({
                value: customer.id,
                label: `${customer.lastName}, ${customer.firstName}`,
              })) || []
            }
            searchable
            clearable
            mb="md"
            {...form.getInputProps("customerId")}
            disabled={customers.loading}
          />

          {!form.values.customerId && (
            <TextInput
              label="Or enter customer name"
              placeholder="Enter customer name"
              mb="md"
              {...form.getInputProps("customerName")}
            />
          )}

          <Group justify="flex-end" mt="md">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Create Reservation</Button>
          </Group>
        </form>
      </div>
    </Modal>
  );
};

export default QuickReservationModal;
