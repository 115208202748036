import React from "react";
import { Paper } from "@mantine/core";
import ReservationForm from "../components/ReservationForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import StyledPageTitle from "../components/StyledPageTitle";

const ReservationsCreatePage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Extract query parameters for pre-filling the form
  const initialValues = {
    courtId: searchParams.get("courtId") || undefined,
    customerId: searchParams.get("customerId") || undefined,
    startTime: searchParams.get("startTime") || undefined,
    endTime: searchParams.get("endTime") || undefined,
  };

  return (
    <>
      <StyledPageTitle mb="sm">Create Reservation</StyledPageTitle>
      <Paper>
        <ReservationForm
          initialValues={initialValues}
          onSubmit={(id) => navigate(`/app/reservations/${id}`)}
        />
      </Paper>
    </>
  );
};

export default ReservationsCreatePage;
