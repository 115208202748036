import React from "react";
import {
  Image,
  Text,
  Container,
  Title,
  SimpleGrid,
  Group,
  Box,
  Grid,
  Divider,
  Paper,
  Badge,
  useMantineTheme,
} from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandLinkedin,
  IconBrandGithub,
  IconMail,
  IconTrophy,
  IconBallTennis,
  IconCode,
} from "@tabler/icons-react";
import classes from "./TeamPage.module.css";

const teamData = [
  {
    name: "Alex Maingot",
    title: "Founder & Lead Engineer",
    bio: "Alex combines his passion for tennis with expertise in software engineering to create innovative solutions for tennis businesses. With over 10 years of programming experience, he leads the technical vision and development of Tennis Shop Guru.",
    image: "/img/cool_person.svg",
    skills: ["Full-Stack Development", "Cloud Architecture", "UX Design"],
    social: [
      { icon: IconBrandGithub, link: "#" },
      { icon: IconBrandLinkedin, link: "#" },
      { icon: IconMail, link: "#" },
    ],
    achievement:
      "Developed the core reservation system that powers Tennis Shop Guru",
    icon: IconCode,
  },
  {
    name: "Gerry Maingot",
    title: "Tennis Operations Director",
    bio: "Gerry brings 35+ years of tennis industry experience to the team. As a former tennis shop owner and USPTA-certified professional, he ensures Tennis Shop Guru addresses the real-world challenges faced by tennis businesses.",
    image: "/img/cool_person.svg",
    skills: ["Tennis Operations", "Customer Relations", "Business Strategy"],
    social: [
      { icon: IconBrandTwitter, link: "#" },
      { icon: IconBrandLinkedin, link: "#" },
      { icon: IconMail, link: "#" },
    ],
    achievement:
      "Helped design workflows that save tennis shops an average of 10 hours per week",
    icon: IconBallTennis,
  },
  {
    name: "Montana Manske",
    title: "Customer Success Manager",
    bio: "Montana ensures that every tennis shop owner has a smooth experience with our platform. Her background in both customer service and tennis retail gives her the perfect blend of skills to help clients maximize their success with Tennis Shop Guru.",
    image: "/img/cool_person.svg",
    skills: ["Client Onboarding", "Training", "Technical Support"],
    social: [
      { icon: IconBrandTwitter, link: "#" },
      { icon: IconBrandLinkedin, link: "#" },
      { icon: IconMail, link: "#" },
    ],
    achievement:
      "Maintains a 98% customer satisfaction rating across all support channels",
    icon: IconTrophy,
  },
];

const TeamPage: React.FC = () => {
  const theme = useMantineTheme();

  const teamCards = teamData.map((member, index) => (
    <Paper
      key={member.name}
      radius="md"
      withBorder
      p="lg"
      className={classes.teamCard}
      style={{
        backgroundColor: "white",
        // transform: `translateY(${index * 15}px)`,
      }}
    >
      <Box className={classes.imageWrapper}>
        <Image
          src={member.image}
          alt={member.name}
          className={classes.avatar}
          width={120}
          height={120}
          radius="xl"
          style={{
            border: `4px solid ${
              index === 0
                ? theme.colors.tsgBlue[5]
                : index === 1
                  ? theme.colors.tsgGreen[5]
                  : theme.colors.tsgBlue[3]
            }`,
          }}
        />
        <Box className={classes.iconBackground}>
          <member.icon size={24} color={theme.white} stroke={1.5} />
        </Box>
      </Box>

      <Box ta="center" mt="sm">
        <Text fz="xl" fw={700} className={classes.memberName}>
          {member.name}
        </Text>
        <Text c="dimmed" fz="sm">
          {member.title}
        </Text>
      </Box>

      <Divider my="md" />

      <Text fz="sm" lineClamp={4} style={{ minHeight: "5rem" }}>
        {member.bio}
      </Text>

      <Box mt="md">
        <Text fw={600} fz="xs" c="dimmed" mb="xs">
          EXPERTISE
        </Text>
        <Group gap={8} mb="md">
          {member.skills.map((skill) => (
            <Badge
              key={skill}
              variant="outline"
              color={
                index === 0 ? "tsgBlue" : index === 1 ? "tsgGreen" : "tsgBlue.4"
              }
              size="sm"
            >
              {skill}
            </Badge>
          ))}
        </Group>
      </Box>

      <Box
        style={{
          backgroundColor: theme.colors.gray[0],
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          marginBottom: theme.spacing.md,
        }}
      >
        <Text fz="xs" fw={500}>
          <Box
            component="span"
            style={{
              color:
                index === 0
                  ? theme.colors.tsgBlue[6]
                  : index === 1
                    ? theme.colors.tsgGreen[6]
                    : theme.colors.tsgBlue[4],
            }}
          >
            ★
          </Box>{" "}
          {member.achievement}
        </Text>
      </Box>

      <Group justify="center" mt="md">
        {member.social.map((social, i) => (
          <Box
            key={i}
            component="a"
            href={social.link}
            target="_blank"
            style={{
              width: 36,
              height: 36,
              borderRadius: "50%",
              backgroundColor:
                index === 0
                  ? theme.colors.tsgBlue[0]
                  : index === 1
                    ? theme.colors.tsgGreen[0]
                    : theme.colors.tsgBlue[1],
              color:
                index === 0
                  ? theme.colors.tsgBlue[6]
                  : index === 1
                    ? theme.colors.tsgGreen[6]
                    : theme.colors.tsgBlue[4],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "all 0.2s ease",
            }}
            className={classes.socialIcon}
          >
            <social.icon size={18} stroke={1.5} />
          </Box>
        ))}
      </Group>
    </Paper>
  ));

  return (
    <>
      {/* Team hero section */}
      <Box
        style={{
          background: `linear-gradient(135deg, ${theme.colors.tsgBlue[7]} 0%, ${theme.colors.tsgBlue[5]} 100%)`,
          color: "white",
          paddingTop: "3rem",
          paddingBottom: "3rem",
          marginBottom: "3rem",
          borderRadius: theme.radius.md,
        }}
      >
        <Container size="lg">
          <Grid align="center">
            <Grid.Col span={{ base: 12, md: 8 }}>
              <Text
                className={classes.supTitle}
                style={{
                  color: theme.colors.tsgGreen[4],
                  letterSpacing: "1px",
                }}
              >
                THE PEOPLE BEHIND TENNIS SHOP GURU
              </Text>
              <Title
                className={classes.title}
                order={1}
                style={{
                  color: "white",
                  marginTop: theme.spacing.md,
                  fontSize: "2.5rem",
                }}
              >
                Meet Our Team of{" "}
                <span className={classes.highlight}>Tennis Tech</span> Experts
              </Title>
              <Text
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                  maxWidth: "700px",
                  fontSize: "1.1rem",
                  lineHeight: 1.6,
                  marginTop: theme.spacing.lg,
                }}
              >
                We're a group of passionate professionals combining deep tennis
                industry knowledge with technical expertise to revolutionize how
                tennis shops operate. Our diverse backgrounds unite around a
                common goal: making your tennis business thrive.
              </Text>
            </Grid.Col>
            <Grid.Col
              span={{ base: 12, md: 4 }}
              style={{ textAlign: "center" }}
            >
              <Image
                src="/cool_person.svg"
                alt="Team"
                width={200}
                style={{ maxWidth: "100%" }}
                visibleFrom="md"
                className={classes.heroImage}
              />
            </Grid.Col>
          </Grid>
        </Container>
      </Box>

      {/* Team cards section */}
      <Container size="lg" pb="xl">
        <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl">
          {teamCards}
        </SimpleGrid>

        <Paper
          withBorder
          p="xl"
          mt="xl"
          radius="md"
          style={{ backgroundColor: theme.colors.gray[0] }}
        >
          <Box ta="center">
            <Title order={3} mb="md" style={{ color: theme.colors.tsgBlue[7] }}>
              Join Our Growing Team!
            </Title>
            <Text>
              We're always looking for talented individuals who are passionate
              about tennis and technology. If you're interested in joining our
              mission to revolutionize tennis shop management, please reach out
              to us.
            </Text>
            <Group justify="center" mt="lg">
              <Badge size="lg" color="tsgBlue">
                Software Engineering
              </Badge>
              <Badge size="lg" color="tsgGreen">
                Tennis Operations
              </Badge>
              <Badge size="lg" color="tsgBlue.3">
                Customer Success
              </Badge>
            </Group>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default TeamPage;
