import { createBrowserRouter } from "react-router-dom";

import PublicLayout from "../layouts/PublicLayout";
import AppLayout from "../layouts/AppLayout";

import ContactPage from "../pages/public/ContactPage";
import DashboardPage from "../pages/DashboardPage";
import FeaturesPage from "../pages/public/FeaturesPage";
import HomePage from "../pages/public/HomePage";
import NotFoundPage from "../pages/error/NotFoundPage";
import SettingsPage from "../pages/SettingsPage";
import TeamPage from "../pages/public/TeamPage";
import TermsPage from "../pages/public/TermsPage";
import PrivacyPolicyPage from "../pages/public/PrivacyPolicyPage";
import ErrorPage from "../pages/error/ErrorPage";
import CustomersPage from "../pages/CustomersPage";
import JobsPage from "../pages/JobsPage";
import EmployeesPage from "../pages/EmployeesPage";
import CustomersCreatePage from "../pages/CustomersCreatePage";
import CustomersDetailsPage from "../pages/CustomersDetailsPage";
import CustomersUpdatePage from "../pages/CustomersUpdatePage";
import EmployeesCreatePage from "../pages/EmployeesCreatePage";
import EmployeesDetailsPage from "../pages/EmployeesDetailsPage";
import EmployeesUpdatePage from "../pages/EmployeesUpdatePage";
import JobsCreatePage from "../pages/JobsCreatePage";
import JobsDetailsPage from "../pages/JobsDetailsPage";
import JobsUpdatePage from "../pages/JobsUpdatePage";
import SignUpPage from "../pages/public/SignUpPage";
import AccountsPage from "../pages/AccountsPage";
import AccountsDetailsPage from "../pages/AccountsDetailsPage";
import AccountsCreatePage from "../pages/AccountsCreatePage";
import AccountsUpdatePage from "../pages/AccountsUpdatePage";
import SignUpConfirmPage from "../pages/public/SignUpConfirmPage";
import AuthLayout from "../layouts/AuthLayout";
import SignUpSuccessPage from "../pages/public/SignUpSuccessPage";
import CourtsFacilityMapPage from "../pages/CourtsFacilityMapPage";
import CourtsPage from "../pages/CourtsPage";
import CourtsCreatePage from "../pages/CourtsCreatePage";
import CourtsDetailsPage from "../pages/CourtsDetailsPage";
import CourtsUpdatePage from "../pages/CourtsUpdatePage";
import PricingPage from "../pages/public/PricingPage";
import WorkShiftsPage from "../pages/WorkShiftsPage";
import WorkShiftsCreatePage from "../pages/WorkShiftsCreatePage";
import WorkShiftsDetailsPage from "../pages/WorkShiftsDetailsPage";
import WorkShiftsUpdatePage from "../pages/WorkShiftsUpdatePage";
import ReservationsPage from "../pages/ReservationsPage";
import ReservationsCreatePage from "../pages/ReservationsCreatePage";
import ReservationsDetailsPage from "../pages/ReservationsDetailsPage";
import ReservationsUpdatePage from "../pages/ReservationsUpdatePage";
import ReportsPage from "../pages/ReportsPage";
import ReportsLayout from "../layouts/ReportsLayout";
import PayrollReportPage from "../pages/reports/PayrollReportPage";
import LoginPage from "../pages/auth/LoginPage";
import LogoutPage from "../pages/auth/LogoutPage";
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage";
import ResetPasswordPage from "../pages/auth/ResetPasswordPage";
import AcceptInvitePage from "../pages/auth/AcceptInvitePage";
import UserProfilePage from "../pages/UserProfilePage";
import { PermissionsProvider } from "../contexts/PermissionsContext";

export const appRouter = createBrowserRouter([
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "/auth/login",
        element: <LoginPage />,
      },
      {
        path: "/auth/logout",
        element: <LogoutPage />,
      },
      {
        path: "/auth/forgot-password",
        element: <ForgotPasswordPage />,
      },
      {
        path: "/auth/reset-password",
        element: <ResetPasswordPage />,
      },
      {
        path: "/auth/accept-invite",
        element: <AcceptInvitePage />,
      },
    ],
  },

  {
    path: "/",
    element: <PublicLayout />,
    children: [
      { path: "", element: <HomePage /> },
      { path: "/team", element: <TeamPage /> },
      { path: "/features", element: <FeaturesPage /> },
      { path: "/pricing", element: <PricingPage /> },
      { path: "/contact", element: <ContactPage /> },
      { path: "/terms", element: <TermsPage /> },
      { path: "/privacy", element: <PrivacyPolicyPage /> },
      { path: "/error", element: <ErrorPage /> },
      { path: "*", element: <NotFoundPage /> },
    ],
  },
  {
    path: "/signup",
    element: <AuthLayout />,
    children: [
      { path: "/signup", element: <SignUpPage /> },
      { path: "/signup/success", element: <SignUpSuccessPage /> },
      { path: "/signup/confirm", element: <SignUpConfirmPage /> },
    ],
  },
  {
    path: "/app",
    element: (
      <PermissionsProvider>
        <AppLayout />
      </PermissionsProvider>
    ),
    children: [
      { path: "/app", element: <DashboardPage /> },
      { path: "/app/customers", element: <CustomersPage /> },
      { path: "/app/customers/create", element: <CustomersCreatePage /> },
      { path: "/app/customers/:id", element: <CustomersDetailsPage /> },
      { path: "/app/customers/:id/update", element: <CustomersUpdatePage /> },
      { path: "/app/jobs", element: <JobsPage /> },
      { path: "/app/jobs/create", element: <JobsCreatePage /> },
      { path: "/app/jobs/:id", element: <JobsDetailsPage /> },
      { path: "/app/jobs/:id/update", element: <JobsUpdatePage /> },
      { path: "/app/employees", element: <EmployeesPage /> },
      { path: "/app/employees/create", element: <EmployeesCreatePage /> },
      { path: "/app/employees/:id", element: <EmployeesDetailsPage /> },
      { path: "/app/employees/:id/update", element: <EmployeesUpdatePage /> },
      { path: "/app/workshifts", element: <WorkShiftsPage /> },
      { path: "/app/workshifts/create", element: <WorkShiftsCreatePage /> },
      { path: "/app/workshifts/:id", element: <WorkShiftsDetailsPage /> },
      { path: "/app/workshifts/:id/update", element: <WorkShiftsUpdatePage /> },
      { path: "/app/courts", element: <CourtsPage /> },
      { path: "/app/courts/create", element: <CourtsCreatePage /> },
      { path: "/app/courts/:id", element: <CourtsDetailsPage /> },
      { path: "/app/courts/:id/update", element: <CourtsUpdatePage /> },
      { path: "/app/courts/map", element: <CourtsFacilityMapPage /> },
      { path: "/app/reservations", element: <ReservationsPage /> },
      { path: "/app/reservations/create", element: <ReservationsCreatePage /> },
      { path: "/app/reservations/:id", element: <ReservationsDetailsPage /> },
      {
        path: "/app/reservations/:id/update",
        element: <ReservationsUpdatePage />,
      },
      { path: "/app/profile", element: <UserProfilePage /> },
      { path: "/app/settings", element: <SettingsPage /> },
      { path: "/app/accounts", element: <AccountsPage /> },
      { path: "/app/accounts/create", element: <AccountsCreatePage /> },
      { path: "/app/accounts/:id", element: <AccountsDetailsPage /> },
      { path: "/app/accounts/:id/update", element: <AccountsUpdatePage /> },
      { path: "/app/reports", element: <ReportsPage /> },
      { path: "/app/*", element: <NotFoundPage /> },
    ],
  },
  {
    path: "/app-reports",
    element: <ReportsLayout />,
    children: [
      { path: "/app-reports/payroll", element: <PayrollReportPage /> },
    ],
  },
]);
