import React from "react";
import { Paper } from "@mantine/core";
import WorkShiftForm from "../components/WorkShiftForm";
import { useNavigate } from "react-router-dom";
import StyledPageTitle from "../components/StyledPageTitle";

const WorkShiftsCreatePage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <StyledPageTitle>Create Work Shift</StyledPageTitle>
      <Paper>
        <WorkShiftForm onSubmit={(id) => navigate(`/app/workshifts/${id}`)} />
      </Paper>
    </>
  );
};

export default WorkShiftsCreatePage;
