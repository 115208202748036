import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CloseButton,
  Group,
  Pagination,
  Paper,
  Skeleton,
  Table,
  TextInput,
  rem,
} from "@mantine/core";
import StyledPageTitle from "../components/StyledPageTitle";
import { IconPlus, IconSearch } from "@tabler/icons-react";

import { CustomerFilter, useListCustomersQuery } from "../graphql";
import DateDisplay from "../components/DateDisplay";
import TableHeaderCell from "../components/TableHeaderCell";
import useListVariables from "../hooks/useListVariables";
import { usePermissions } from "../contexts/PermissionsContext";

const headers = [
  { label: "First Name", field: "firstName" },
  { label: "Last Name", field: "lastName" },
  { label: "Cell Phone", field: "cellPhone" },
  { label: "Home Phone", field: "homePhone" },
  { label: "Work Phone", field: "workPhone" },
  { label: "Email", field: "email" },
  { label: "Last Updated", field: "updatedAt" },
];

const CustomersPage: React.FC = () => {
  const permissions = usePermissions();
  const navigate = useNavigate();
  const listVariables = useListVariables<CustomerFilter>({
    defaultSort: { field: "updatedAt", order: "DESC" },
  });

  const customers = useListCustomersQuery({
    variables: {
      pagination: listVariables.pagination,
      sort: listVariables.sort,
      filter: listVariables.filter,
    },
  });

  return (
    <>
      <Group justify="space-between" mb="sm">
        <StyledPageTitle>Customers</StyledPageTitle>
        <Group>
          <TextInput
            w={300}
            leftSection={<IconSearch />}
            placeholder="Search"
            value={listVariables.filter?.q || ""}
            onChange={(e) =>
              listVariables.setFilter({
                q: e.currentTarget.value || undefined,
              })
            }
            rightSection={
              listVariables.filter?.q && (
                <CloseButton
                  onClick={() => listVariables.setFilter({ q: undefined })}
                />
              )
            }
          />
          <Button
            onClick={() => navigate("/app/customers/create")}
            rightSection={<IconPlus />}
            display={!permissions.can("createCustomer") ? "hidden" : undefined}
          >
            Create
          </Button>
        </Group>
      </Group>
      <Paper w="100%">
        <Table.ScrollContainer minWidth={500}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                {headers.map((header) => (
                  <TableHeaderCell
                    key={header.field}
                    label={header.label}
                    field={header.field}
                    vars={listVariables}
                  />
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {customers.data?.listCustomers.data.map((customer) => (
                <Table.Tr
                  key={customer.id}
                  data-testid="customer-table-row"
                  onClick={() => navigate(`/app/customers/${customer.id}`)}
                  style={{ cursor: "pointer" }}
                >
                  <Table.Td>{customer.firstName}</Table.Td>
                  <Table.Td>{customer.lastName}</Table.Td>
                  <Table.Td>{customer.cellPhone}</Table.Td>
                  <Table.Td>{customer.homePhone}</Table.Td>
                  <Table.Td>{customer.workPhone}</Table.Td>
                  <Table.Td>{customer.email}</Table.Td>
                  <Table.Td>
                    <DateDisplay value={customer.updatedAt} />
                  </Table.Td>
                </Table.Tr>
              ))}
              {customers.loading &&
                Array.from({ length: listVariables.pagination.perPage }).map(
                  (_, index) => (
                    <Table.Tr key={index}>
                      {Array.from({ length: 7 }).map((_, index) => (
                        <Table.Td key={index}>
                          <Skeleton height={22} radius="none" />
                        </Table.Td>
                      ))}
                    </Table.Tr>
                  )
                )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
        <Group justify="space-evenly">
          <Pagination
            hidden={
              !customers.data?.listCustomers.total ||
              customers.data?.listCustomers.total <
                listVariables.pagination.perPage
            }
            mt={rem(20)}
            value={listVariables.pagination.page}
            onChange={listVariables.setPage}
            total={
              (customers.data?.listCustomers.total || 0) /
              listVariables.pagination.perPage
            }
          />
        </Group>
      </Paper>
    </>
  );
};

export default CustomersPage;
