import React from "react";
import {
  Paper,
  Text,
  SimpleGrid,
  Group,
  Button,
  Pagination,
  Table,
  rem,
  ColorSwatch,
} from "@mantine/core";
import StyledPageTitle from "../components/StyledPageTitle";
import StyledSectionTitle from "../components/StyledSectionTitle";
import {
  JobFilter,
  WorkShiftFilter,
  useGetEmployeeQuery,
  useResendInvitationMutation,
} from "../graphql";
import { useNavigate, useParams } from "react-router-dom";
import DateDisplay from "../components/DateDisplay";
import { IconEdit, IconMailForward } from "@tabler/icons-react";
import TableHeaderCell from "../components/TableHeaderCell";
import useListVariables from "../hooks/useListVariables";
import DeleteButton from "../components/DeleteButton";
import moment from "moment";
import { usePermissions } from "../contexts/PermissionsContext";
import { showSuccessNotification } from "../utils/showNotification";

const workshiftHeaders = [
  { label: "Clock In", field: "start" },
  { label: "Clock Out", field: "end" },
  { label: "Status", field: "status" },
  { label: "Employee", field: "employeeId" },
  { label: "Created At", field: "createdId" },
  { label: "Updated At", field: "updatedAt" },
];
const EmployeesDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const permissions = usePermissions();
  const params = useParams<{ id: string }>();
  const jobsListFilters = useListVariables<JobFilter>({
    defaultSort: {
      field: "createdAt",
      order: "DESC",
    },
    prefix: "jobs",
  });
  const workshiftsListFilters = useListVariables<WorkShiftFilter>({
    defaultSort: {
      field: "createdAt",
      order: "DESC",
    },
    prefix: "workshifts",
  });
  const getEmployee = useGetEmployeeQuery({
    variables: {
      id: `${params.id}`,
      jobsFilter: jobsListFilters.filter,
      jobsSort: jobsListFilters.sort,
      jobsPagination: jobsListFilters.pagination,
      workshiftsFilter: workshiftsListFilters.filter,
      workshiftsSort: workshiftsListFilters.sort,
      workshiftsPagination: workshiftsListFilters.pagination,
    },
  });
  const [resendInvitation, { loading: resendLoading }] =
    useResendInvitationMutation({
      onCompleted: () => {
        showSuccessNotification(
          "Invitation email has been resent successfully!"
        );
      },
    });

  const handleResendInvitation = () => {
    if (params.id) {
      resendInvitation({
        variables: {
          id: params.id,
        },
      });
    }
  };

  const employee = getEmployee.data?.getEmployee;
  const jobs = employee?.jobsFinished;
  const workshifts = employee?.workShifts;
  return (
    <>
      <Group justify="space-between" mb="sm">
        <StyledPageTitle>Employee Details</StyledPageTitle>
        <Group>
          {employee?.status === "INVITED" &&
            permissions.can("resendInvitation") && (
              <Button
                rightSection={<IconMailForward />}
                onClick={handleResendInvitation}
                loading={resendLoading}
                color="blue"
              >
                Resend Invitation
              </Button>
            )}
          <Button
            rightSection={<IconEdit />}
            onClick={() => navigate(`/app/employees/${params.id}/update`)}
            display={permissions.can("updateEmployee") ? undefined : "none"}
          >
            Update
          </Button>
          <DeleteButton
            id={params.id}
            onCompleted={() => navigate("/app/employees")}
            type="employee"
          />
        </Group>
      </Group>
      <Paper>
        <SimpleGrid cols={{ sm: 1, md: 2, lg: 3 }}>
          <Text>
            <strong>First Name:</strong> {employee?.firstName}
          </Text>
          <Text>
            <strong>Last Name:</strong> {employee?.lastName}
          </Text>
          <Text>
            <strong>Role:</strong> {employee?.role}
          </Text>
          <Text>
            <strong>Email:</strong> {employee?.email}
          </Text>
          <Text>
            <strong>Status:</strong> {employee?.status}
          </Text>
          <Text component="div">
            <strong>Color:</strong>{" "}
            <ColorSwatch color={employee?.color || "white"} /> {employee?.color}
          </Text>
          <Text>
            <strong>Updated At:</strong>{" "}
            <DateDisplay value={employee?.updatedAt} />
          </Text>
          <Text>
            <strong>Created At:</strong>{" "}
            <DateDisplay value={employee?.createdAt} />
          </Text>
        </SimpleGrid>
      </Paper>
      <Paper mt="md">
        <StyledSectionTitle>Completed Jobs</StyledSectionTitle>
        <Table.ScrollContainer minWidth={500}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <TableHeaderCell
                  label="Name"
                  field="name"
                  vars={jobsListFilters}
                />
                <TableHeaderCell
                  label="String"
                  field="stringName"
                  vars={jobsListFilters}
                />
                <TableHeaderCell
                  label="Racket"
                  field="racket"
                  vars={jobsListFilters}
                />
                <TableHeaderCell
                  label="Tension"
                  field="tension"
                  vars={jobsListFilters}
                />
                <TableHeaderCell
                  label="Gauge"
                  field="gauge"
                  vars={jobsListFilters}
                />
                <TableHeaderCell
                  label="Received On"
                  field="createdAt"
                  vars={jobsListFilters}
                />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {jobs?.data.map((job) => (
                <Table.Tr key={job.id}>
                  <Table.Td>{job.name}</Table.Td>
                  <Table.Td>{job.stringName}</Table.Td>
                  <Table.Td>{job.racket}</Table.Td>
                  <Table.Td>{job.tension}</Table.Td>
                  <Table.Td>{job.gauge}</Table.Td>
                  <Table.Td>
                    <DateDisplay value={job.receivedAt || job.createdAt} />
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
        <Group justify="space-evenly">
          <Pagination
            hidden={
              !jobs?.total || jobs?.total < jobsListFilters.pagination.perPage
            }
            mt={rem(20)}
            value={jobsListFilters.pagination.page}
            onChange={jobsListFilters.setPage}
            total={(jobs?.total || 0) / jobsListFilters.pagination.perPage}
          />
        </Group>
      </Paper>
      <Paper mt="md">
        <StyledSectionTitle>Work Shifts</StyledSectionTitle>
        <Table.ScrollContainer minWidth={500}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                {workshiftHeaders.map((header) => (
                  <TableHeaderCell
                    key={header.field}
                    label={header.label}
                    field={header.field}
                    vars={workshiftsListFilters}
                  />
                ))}
                <Table.Th>Duration</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {workshifts?.data.map((workshift) => (
                <Table.Tr
                  key={workshift.id}
                  onClick={() => navigate(`/app/workshifts/${workshift.id}`)}
                >
                  <Table.Td>
                    <DateDisplay value={workshift.start} />
                  </Table.Td>
                  <Table.Td>
                    <DateDisplay value={workshift.end} />
                  </Table.Td>
                  <Table.Td>{workshift.status}</Table.Td>
                  <Table.Td>
                    {workshift.employee.firstName} {workshift.employee.lastName}
                  </Table.Td>
                  <Table.Td>
                    <DateDisplay value={workshift.createdAt} />
                  </Table.Td>
                  <Table.Td>
                    <DateDisplay value={workshift.updatedAt} />
                  </Table.Td>
                  <Table.Td>
                    {workshift.end &&
                      workshift.start &&
                      `${moment(workshift.end).diff(
                        workshift.start,
                        "hours"
                      )}h  ${moment(workshift.end).diff(
                        workshift.start,
                        "minutes"
                      )}m`}
                  </Table.Td>{" "}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
        <Group justify="space-evenly">
          <Pagination
            hidden={
              !workshifts?.total ||
              workshifts?.total < workshiftsListFilters.pagination.perPage
            }
            mt={rem(20)}
            value={workshiftsListFilters.pagination.page}
            onChange={workshiftsListFilters.setPage}
            total={
              (workshifts?.total || 0) /
              workshiftsListFilters.pagination.perPage
            }
          />
        </Group>
      </Paper>
    </>
  );
};

export default EmployeesDetailsPage;
