import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Paper } from "@mantine/core";
import FacilityMap from "../components/FacilityMap";
import StyledPageTitle from "../components/StyledPageTitle";

const CourtsFacilityMapPage: React.FC = () => {
  return (
    <>
      <StyledPageTitle>Courts</StyledPageTitle>
      <Paper w="100%">
        <DndProvider backend={HTML5Backend}>
          <FacilityMap />
        </DndProvider>
      </Paper>
    </>
  );
};

export default CourtsFacilityMapPage;
