import React from "react";
import {
  Box,
  Button,
  Card,
  Flex,
  Group,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Badge,
  ThemeIcon,
} from "@mantine/core";
import { IconCalendar, IconStarFilled, IconClock } from "@tabler/icons-react";

import { useGetMyAccountBillingInfoQuery, useListPlansQuery } from "../graphql";
import PaymentMethodDisplay from "../components/PaymentMethodDisplay";
import useBillingPortalLink from "../hooks/useBillingPortalLink";
import usePaymentMethodModal from "../hooks/usePaymentMethodModal";
import DateDisplay from "./DateDisplay";

const ManageBillingPanel: React.FC = () => {
  const plansResponse = useListPlansQuery();
  const billingInfo = useGetMyAccountBillingInfoQuery();
  const billingPortal = useBillingPortalLink();
  const { openPaymentMethodModal } = usePaymentMethodModal();

  const plans = React.useMemo(
    () => plansResponse.data?.listPlans || [],
    [plansResponse.data]
  );
  const currentSubscription = React.useMemo(
    () => billingInfo.data?.myAccount?.billingInfo?.currentSubscription,
    [billingInfo.data]
  );
  const paymentMethods =
    billingInfo.data?.myAccount?.billingInfo?.paymentMethods || [];
  const defaultPaymentMethodId =
    billingInfo.data?.myAccount?.billingInfo?.currentSubscription
      ?.defaultPaymentMethod;

  // Format subscription status for display
  const formattedStatus = currentSubscription?.status
    ? currentSubscription.status.charAt(0).toUpperCase() +
      currentSubscription.status.slice(1)
    : "";

  const statusColor =
    currentSubscription?.status === "active" ? "green" : "orange";

  const currentPlan = React.useMemo(() => {
    const currentSubscription =
      billingInfo.data?.myAccount?.billingInfo?.currentSubscription;
    if (!currentSubscription) return null;
    const planId = currentSubscription.plan?.product;
    const priceId = currentSubscription.plan?.id;
    const plan = plans.find((plan) => plan.id === planId);
    const price = plan?.prices.find((price) => price.id === priceId);
    return { ...plan, price };
  }, [plans, billingInfo]);

  return (
    <>
      <SimpleGrid spacing="lg">
        <Flex direction="column">
          <Box flex={2} mb="md">
            <Paper p="md" radius="md" withBorder>
              <Group justify="space-between" mb="xs">
                <Title order={4}>Current Plan</Title>
                {currentSubscription?.status && (
                  <Badge color={statusColor} size="lg">
                    {formattedStatus}
                  </Badge>
                )}
              </Group>

              <Card p="md" radius="md" mb="md">
                <Group justify="space-between">
                  <Group>
                    <ThemeIcon size="lg" radius="xl" color="blue">
                      <IconStarFilled size={20} />
                    </ThemeIcon>
                    <Text fw={700} size="xl">
                      {currentPlan?.name}
                    </Text>
                  </Group>
                  <Text fw={700} size="xl" color="blue">
                    ${currentPlan?.price?.amount}/
                    {currentPlan?.price?.billingInterval.toLowerCase()}
                  </Text>
                </Group>
              </Card>

              <Group mb="xs">
                <ThemeIcon size="md" color="gray" variant="light">
                  <IconCalendar size={16} />
                </ThemeIcon>
                <Text fw={500}>Billing Period</Text>
              </Group>
              <Text ml={30} mb="md">
                <DateDisplay
                  value={currentSubscription?.currentPeriodStart}
                  format="MMM D, YYYY"
                />{" "}
                -{" "}
                <DateDisplay
                  value={currentSubscription?.currentPeriodEnd}
                  format="MMM D, YYYY"
                />
              </Text>

              {(currentSubscription?.trialStart ||
                currentSubscription?.trialEnd) && (
                <>
                  <Group mb="xs">
                    <ThemeIcon size="md" color="blue" variant="light">
                      <IconClock size={16} />
                    </ThemeIcon>
                    <Text fw={500}>Trial Period</Text>
                  </Group>
                  <Text ml={30} mb="md">
                    {currentSubscription?.trialStart && (
                      <>
                        Started:{" "}
                        <DateDisplay
                          value={currentSubscription.trialStart}
                          format="MMM D, YYYY"
                        />
                      </>
                    )}
                    {currentSubscription?.trialStart &&
                      currentSubscription?.trialEnd &&
                      " • "}
                    {currentSubscription?.trialEnd && (
                      <>
                        Ends:{" "}
                        <DateDisplay
                          value={currentSubscription.trialEnd}
                          format="MMM D, YYYY"
                        />
                      </>
                    )}
                  </Text>
                </>
              )}

              {currentSubscription?.cancelAtPeriodEnd && (
                <Badge color="red">Cancels at end of billing period</Badge>
              )}
            </Paper>
          </Box>
          <Box>
            <Button
              loading={billingPortal.loading}
              component="a"
              href={billingPortal.billingPortalLink?.url}
            >
              Manage Subscription
            </Button>
          </Box>
        </Flex>
        <div>
          <Group justify="space-between" mb="md">
            <Title order={4}>Payment Methods</Title>
            <Button onClick={() => openPaymentMethodModal()}>
              Add Payment Method
            </Button>
          </Group>

          <Stack>
            {paymentMethods.map((paymentMethod) => (
              <PaymentMethodDisplay
                key={paymentMethod.id}
                {...paymentMethod}
                isDefault={paymentMethod.id === defaultPaymentMethodId}
              />
            ))}
          </Stack>
        </div>
      </SimpleGrid>
    </>
  );
};

export default ManageBillingPanel;
