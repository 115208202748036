import React from "react";

interface Props {
  amount?: string | null | number;
  currency?: string | null;
}

const CurrencyDisplay: React.FC<Props> = ({ amount, currency }) => {
  const formatted = React.useMemo(() => {
    if (!amount) {
      return "";
    }

    try {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency || "USD",
        minimumFractionDigits: 0,
      }).format(typeof amount === "string" ? parseFloat(amount) : amount);
    } catch (error) {
      console.error("Error formatting currency:", error);
      return "";
    }
  }, [amount, currency]);

  return <>{formatted}</>;
};

export default CurrencyDisplay;
