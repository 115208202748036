import React from "react";
import { Paper, Text, SimpleGrid, Group, Button, Code } from "@mantine/core";
import { useGetAccountQuery } from "../graphql";
import { useNavigate, useParams } from "react-router-dom";
import DateDisplay from "../components/DateDisplay";
import { IconEdit } from "@tabler/icons-react";
import DeleteButton from "../components/DeleteButton";
import StyledPageTitle from "../components/StyledPageTitle";

const AccountsDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const getAccount = useGetAccountQuery({
    variables: {
      id: `${params.id}`,
    },
  });

  const account = getAccount.data?.getAccount;
  return (
    <>
      <Group justify="space-between" mb="sm">
        <StyledPageTitle>Account Details</StyledPageTitle>
        <Group>
          <Button
            rightSection={<IconEdit />}
            onClick={() => navigate(`/app/accounts/${params.id}/update`)}
          >
            Update
          </Button>
          <DeleteButton
            id={params.id}
            onCompleted={() => navigate("/app/accounts")}
            type="account"
          />
        </Group>
      </Group>
      <Paper>
        <SimpleGrid>
          <Text>
            <strong>Name:</strong> {account?.name}
          </Text>
          <Text>
            <strong>Phone:</strong> {account?.phone}
          </Text>
          <Text>
            <strong>Status:</strong> {account?.status}
          </Text>
          <Text>
            <strong>Stripe Customer ID:</strong>{" "}
            {account?.billingInfo?.customer?.id}
          </Text>
          <Text>
            <strong>Notifications Phone Number:</strong>{" "}
            {account?.notificationsPhoneNumber}
          </Text>
          <Text>
            <strong>Job Finished SMS Template:</strong>{" "}
            {account?.jobFinishedSmsTemplate}
          </Text>
          <Text>
            <strong>Business Hours:</strong>{" "}
            <Code>{JSON.stringify(account?.businessHours || {}, null, 2)}</Code>
          </Text>
          <Text>
            <strong>Trial Ends At:</strong>{" "}
            <DateDisplay value={account?.trialEndDate} />
          </Text>
          <Text>
            <strong>Updated At:</strong>{" "}
            <DateDisplay value={account?.updatedAt} />
          </Text>
          <Text>
            <strong>Created At:</strong>{" "}
            <DateDisplay value={account?.createdAt} />
          </Text>
        </SimpleGrid>
      </Paper>
    </>
  );
};

export default AccountsDetailsPage;
