import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
  PasswordInput,
  Button,
  Title,
  Text,
  Anchor,
  Stack,
  Alert,
  ThemeIcon,
} from "@mantine/core";
import { IconAlertCircle, IconCheck } from "@tabler/icons-react";

const ResetPasswordPage: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Extract token from URL query parameters
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get("token");
    if (tokenParam) {
      setToken(tokenParam);
    } else {
      setError("Reset token is missing. Please use the link from your email.");
    }
  }, [location.search]);

  const validatePassword = (password: string): boolean => {
    return password.length >= 8;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    // Validate passwords
    if (!validatePassword(password)) {
      setError("Password must be at least 8 characters long");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setIsLoading(true);

    try {
      const success = await auth.resetPassword(token, password);
      if (success) {
        setIsSuccess(true);
      } else {
        setError("Failed to reset password. The link may have expired.");
      }
    } catch (err) {
      setError("An error occurred. Please try again.");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isSuccess) {
    return (
      <>
        <Title ta="center" fw={900} mb="lg">
          Password reset successful
        </Title>

        <Stack align="center" gap="md">
          <ThemeIcon color="green" size="xl" radius="xl">
            <IconCheck size="1.5rem" />
          </ThemeIcon>

          <Text size="sm" c="dimmed" ta="center">
            Your password has been reset successfully. You can now log in with
            your new password.
          </Text>

          <Button component={Link} to="/auth/login">
            Go to login
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <>
      <Title ta="center" fw={900} mb="lg">
        Reset your password
      </Title>

      <form onSubmit={handleSubmit}>
        {error && (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Error"
            color="red"
            mb="md"
          >
            {error}
          </Alert>
        )}

        <Stack>
          <PasswordInput
            label="New password"
            placeholder="Your new password"
            required
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            description="Password must be at least 8 characters long"
          />

          <PasswordInput
            label="Confirm password"
            placeholder="Confirm your new password"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
          />

          <Button
            type="submit"
            fullWidth
            loading={isLoading}
            disabled={isLoading || !token}
          >
            Reset password
          </Button>
        </Stack>
      </form>

      <Text ta="center" mt="md">
        <Anchor component={Link} to="/auth/login">
          Back to login
        </Anchor>
      </Text>
    </>
  );
};

export default ResetPasswordPage;
