import { Select, SelectProps } from "@mantine/core";
import React from "react";
import { useGetMyAccountBillingInfoQuery } from "../graphql";

const PaymentMethodInput: React.FC<SelectProps> = (props) => {
  const myAccountBillingInfoResponse = useGetMyAccountBillingInfoQuery();
  const paymentMethods =
    myAccountBillingInfoResponse.data?.myAccount?.billingInfo?.paymentMethods ||
    [];

  const pricingOptions = paymentMethods.map((paymentMethod) => ({
    value: paymentMethod.id,
    label: `${paymentMethod.card?.brand.toUpperCase()} ending in ${
      paymentMethod.card?.lastFour
    }`,
  }));

  return (
    <Select
      label="Payment Method"
      allowDeselect={false}
      data={pricingOptions}
      {...props}
    />
  );
};

export default PaymentMethodInput;
