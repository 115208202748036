import React, { useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Group,
  Paper,
  Text,
  Grid,
  Box,
  Stack,
  ActionIcon,
  useMantineTheme,
  Skeleton,
  Center,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import StyledPageTitle from "../components/StyledPageTitle";
import {
  IconPlus,
  IconChevronLeft,
  IconChevronRight,
  IconCalendar,
  IconCalendarDue,
} from "@tabler/icons-react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import {
  ReservationFragment,
  useListCourtsQuery,
  useListReservationsQuery,
  useUpdateReservationMutation,
} from "../graphql";
import { usePermissions } from "../contexts/PermissionsContext";
import moment from "moment";
import QuickReservationModal from "../components/QuickReservationModal";
import DroppableTimeSlot from "../components/DroppableTimeSlot";
import DraggableReservation from "../components/DraggableReservation";

const HOURS = Array.from({ length: 14 }, (_, i) => i + 7); // 7am to 8pm

// DND item types
const ItemTypes = {
  RESERVATION: "reservation",
  NEW_RESERVATION: "new_reservation",
};

// Drag state interface removed - now using React DND

// Interface for drag items
interface ReservationDragItem {
  type: typeof ItemTypes.RESERVATION;
  id: string;
  courtId: string;
  startTime: string;
  endTime: string;
  duration: number; // in minutes
}

interface NewReservationDragItem {
  type: typeof ItemTypes.NEW_RESERVATION;
  courtId: string;
  hour: number;
  minute: number;
}

// Interface for drop result removed - not needed

const ReservationsPage: React.FC = () => {
  const permissions = usePermissions();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const gridRef = useRef<HTMLDivElement>(null);

  // State for calendar view
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Legacy drag state removed - now using React DND

  // State for quick reservation modal
  const [quickReservationModal, setQuickReservationModal] = useState({
    opened: false,
    courtId: "",
    courtName: "",
    startTime: new Date(),
    endTime: new Date(),
  });

  // Update reservation mutation
  const [updateReservation] = useUpdateReservationMutation({
    onCompleted: () => {
      // Refetch reservations after update
      reservations.refetch();
    },
    onError: (error) => {
      console.error("Error updating reservation:", error);
    },
  });
  // Format for display
  const formattedDate = moment(selectedDate).format("dddd, MMMM D, YYYY");

  // Check if selected date is today
  const isToday = useCallback(() => {
    return moment(selectedDate).isSame(moment(), "day");
  }, [selectedDate]);

  // Navigation functions
  const goToToday = () => {
    setSelectedDate(new Date());
  };
  // Navigation functions
  const goToPreviousDay = () => {
    setSelectedDate((prev) => moment(prev).subtract(1, "day").toDate());
  };

  const goToNextDay = () => {
    setSelectedDate((prev) => moment(prev).add(1, "day").toDate());
  };

  const goToPreviousWeek = () => {
    setSelectedDate((prev) => moment(prev).subtract(7, "days").toDate());
  };

  const goToNextWeek = () => {
    setSelectedDate((prev) => moment(prev).add(7, "days").toDate());
  };

  // Get list of courts
  const courts = useListCourtsQuery({
    variables: {
      pagination: { page: 1, perPage: 50 },
      sort: { field: "name", order: "ASC" },
    },
  });

  // Filter for reservations on the selected date
  const startOfDay = moment(selectedDate).startOf("day").toISOString();
  const endOfDay = moment(selectedDate).endOf("day").toISOString();

  // Get reservations for the selected date
  const reservations = useListReservationsQuery({
    variables: {
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: {
        field: "startTime",
        order: "ASC",
      },
      filter: {
        startTime: {
          gte: startOfDay,
          lte: endOfDay,
        },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  // Group reservations by court and time
  const reservationsByCourtAndTime = React.useMemo(() => {
    const result: Record<
      string,
      Record<string, Array<ReservationFragment>>
    > = {};

    if (reservations.data?.listReservations.data) {
      reservations.data.listReservations.data.forEach((reservation) => {
        const courtId = reservation.courtId;
        const startHour = moment(reservation.startTime).hour();

        if (!result[courtId]) {
          result[courtId] = {};
        }

        if (!result[courtId][startHour]) {
          result[courtId][startHour] = [];
        }

        result[courtId][startHour].push(reservation);
      });
    }

    return result;
  }, [reservations.data]);

  // Status color function moved to DraggableReservation component

  // Helper function to get court name by ID
  const getCourtName = useCallback(
    (courtId: string) => {
      const court = courts.data?.listCourts.data.find((c) => c.id === courtId);
      return court ? court.name : "Unknown Court";
    },
    [courts.data]
  );

  // pixelToTime function removed - now using React DND

  // Convert court column and time to Date
  const getDateTime = useCallback(
    (hour: number, minute: number): Date => {
      return moment(selectedDate)
        .hour(hour)
        .minute(minute)
        .second(0)
        .millisecond(0)
        .toDate();
    },
    [selectedDate]
  );

  // Legacy mouse handlers removed - now using React DND

  // Legacy mouse event handlers removed - now using React DND

  // Handle successful reservation creation
  const handleReservationCreated = () => {
    // Close the modal
    setQuickReservationModal({
      opened: false,
      courtId: "",
      courtName: "",
      startTime: new Date(),
      endTime: new Date(),
    });

    // Refetch reservations
    reservations.refetch();
  };

  // Handle drop for both new and existing reservations
  const handleDrop = useCallback(
    (
      item: ReservationDragItem | NewReservationDragItem,
      courtId: string,
      hour: number,
      minute: number
    ) => {
      if (item.type === ItemTypes.RESERVATION) {
        // Handle existing reservation drop (reschedule)
        const reservationItem = item as ReservationDragItem;
        const duration = reservationItem.duration;

        // Preserve the original date of the reservation
        // Extract the date part from the original startTime
        const originalDate = moment(reservationItem.startTime);

        // Create new start time using the original date but with new hour and minute
        const dropTime = originalDate
          .clone()
          .hour(hour)
          .minute(minute)
          .second(0)
          .millisecond(0)
          .toDate();
        const endTime = moment(dropTime).add(duration, "minutes").toDate();

        updateReservation({
          variables: {
            input: {
              id: reservationItem.id,
              courtId: courtId,
              startTime: dropTime.toISOString(),
              endTime: endTime.toISOString(),
            },
          },
        });
      } else {
        // Handle new reservation drop
        // For new reservations, use the selected date
        const dropTime = getDateTime(hour, minute);
        const endTime = moment(dropTime).add(60, "minutes").toDate(); // Default to 1 hour

        setQuickReservationModal({
          opened: true,
          courtId: courtId,
          courtName: getCourtName(courtId),
          startTime: dropTime,
          endTime: endTime,
        });
      }
    },
    [getDateTime, updateReservation, getCourtName]
  );

  // Handle click on a time slot to create a new reservation
  const handleTimeSlotClick = useCallback(
    (courtId: string, hour: number, minute: number) => {
      const startTime = getDateTime(hour, minute);
      const endTime = moment(startTime).add(60, "minutes").toDate(); // Default to 1 hour

      setQuickReservationModal({
        opened: true,
        courtId: courtId,
        courtName: getCourtName(courtId),
        startTime,
        endTime,
      });
    },
    [getCourtName, getDateTime]
  );

  // Handle click on a reservation to view details
  const handleReservationClick = useCallback(
    (id: string) => {
      navigate(`/app/reservations/${id}`);
    },
    [navigate]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Group justify="space-between" mb="sm">
        <StyledPageTitle>Court Reservations</StyledPageTitle>
        <Group>
          <Button
            onClick={() => navigate("/app/reservations/create")}
            rightSection={<IconPlus />}
            display={
              !permissions.can("createReservation") ? "hidden" : undefined
            }
          >
            Create
          </Button>
        </Group>
      </Group>

      {/* Calendar Navigation */}
      <Paper p="md" mb="md">
        <Group justify="space-between" align="center">
          <Group>
            <ActionIcon
              variant="subtle"
              onClick={goToPreviousWeek}
              title="Previous Week"
            >
              <IconChevronLeft size={16} />
              <IconChevronLeft size={16} style={{ marginLeft: -10 }} />
            </ActionIcon>

            <ActionIcon
              variant="subtle"
              onClick={goToPreviousDay}
              title="Previous Day"
            >
              <IconChevronLeft size={16} />
            </ActionIcon>
          </Group>

          <Group>
            <Text fw={500} size="lg">
              {formattedDate}
            </Text>
            <DatePickerInput
              valueFormat="YYYY-MM-DD"
              leftSection={<IconCalendar size={16} />}
              value={selectedDate}
              onChange={(date) => date && setSelectedDate(date)}
              mx="auto"
            />
            {!isToday() && (
              <Button
                variant="outline"
                size="xs"
                leftSection={<IconCalendarDue size={16} />}
                onClick={goToToday}
                title="Go to today"
              >
                Today
              </Button>
            )}
          </Group>

          <Group>
            <ActionIcon variant="subtle" onClick={goToNextDay} title="Next Day">
              <IconChevronRight size={16} />
            </ActionIcon>

            <ActionIcon
              variant="subtle"
              onClick={goToNextWeek}
              title="Next Week"
            >
              <IconChevronRight size={16} />
              <IconChevronRight size={16} style={{ marginLeft: -10 }} />
            </ActionIcon>
          </Group>
        </Group>
      </Paper>

      {/* Calendar Day View */}
      <Paper p="md">
        <Grid ref={gridRef}>
          {/* Time column */}
          <Grid.Col span={1}>
            <Box style={{ height: "40px" }}></Box> {/* Header space */}
            {HOURS.map((hour) => (
              <Box
                key={hour}
                style={{
                  height: "100px",
                  borderTop: `1px solid ${theme.colors.gray[3]}`,
                  padding: "4px",
                }}
              >
                <Text size="xs" c="dimmed">
                  {hour % 12 === 0 ? 12 : hour % 12}
                  {hour < 12 ? "am" : "pm"}
                </Text>
              </Box>
            ))}
          </Grid.Col>

          {/* Court columns */}
          {courts.loading ? (
            <Grid.Col span={11}>
              <Skeleton height={40} mb="sm" />
              {HOURS.map((_hour, idx) => (
                <Skeleton key={idx} height={100} mb="xs" />
              ))}
            </Grid.Col>
          ) : courts.data?.listCourts.data.length === 0 ? (
            <Grid.Col span={11}>
              <Center h={300}>
                <Stack align="center">
                  <Text>No courts found</Text>
                  <Button
                    onClick={() => navigate("/app/courts/create")}
                    rightSection={<IconPlus />}
                  >
                    Create Court
                  </Button>
                </Stack>
              </Center>
            </Grid.Col>
          ) : (
            courts.data?.listCourts.data.map((court) => (
              <Grid.Col
                key={court.id}
                span={11 / (courts.data?.listCourts.data.length || 1)}
              >
                {/* Court header */}
                <Box
                  style={{
                    height: "40px",
                    backgroundColor: theme.colors.gray[1],
                    padding: "8px",
                    borderRadius: theme.radius.sm,
                    marginBottom: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text fw={500}>{court.name}</Text>
                </Box>

                {/* Time slots */}
                {HOURS.map((hour) => {
                  const reservationsForHour =
                    reservationsByCourtAndTime[court.id]?.[hour] || [];

                  return (
                    <DroppableTimeSlot
                      key={hour}
                      courtId={court.id}
                      hour={hour}
                      onDrop={handleDrop}
                      onSlotClick={handleTimeSlotClick}
                      canCreate={permissions.can("createReservation")}
                    >
                      {/* Drag indicator removed - now using React DND's built-in drag preview */}

                      {reservationsForHour.map((reservation) => (
                        <DraggableReservation
                          key={reservation.id}
                          reservation={reservation}
                          onReservationClick={handleReservationClick}
                          canUpdate={permissions.can("updateReservation")}
                        />
                      ))}
                    </DroppableTimeSlot>
                  );
                })}
              </Grid.Col>
            ))
          )}
        </Grid>
      </Paper>

      {/* Quick Reservation Modal */}
      <QuickReservationModal
        opened={quickReservationModal.opened}
        onClose={() =>
          setQuickReservationModal({ ...quickReservationModal, opened: false })
        }
        onSuccess={handleReservationCreated}
        courtId={quickReservationModal.courtId}
        courtName={quickReservationModal.courtName}
        startTime={quickReservationModal.startTime}
        endTime={quickReservationModal.endTime}
      />
    </DndProvider>
  );
};

export default ReservationsPage;
