import React from "react";
import config from "../utils/config";
import { auth } from "../utils/auth";

interface BillingSetupIntent {
  client_secret: string;
}

interface HookReturn {
  setupIntent?: BillingSetupIntent;
  loading: boolean;
  error?: string;
}

function useBillingSetupIntent(): HookReturn {
  const [loading, setLoading] = React.useState(true);
  const [setupIntent, setBillingSetupIntent] =
    React.useState<BillingSetupIntent>();
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    setLoading(true);
    auth.getAccessToken().then((accessToken: string | undefined) => {
      fetch(`${config.API_HOST}/billing/setup-intent`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
        .then((res) => res.json())
        .then((data) => {
          setBillingSetupIntent(data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    });
  }, []);

  return {
    loading,
    setupIntent,
    error,
  };
}

export default useBillingSetupIntent;
