import React from "react";
import { useForm } from "@mantine/form";
import { Button, SimpleGrid, TextInput } from "@mantine/core";

import {
  showErrorNotification,
  showSuccessNotification,
} from "../utils/showNotification";
import { SubscribeToPlanInput, useSubscribeToPlanMutation } from "../graphql";
import PlanPricingInput from "./PlanPricingInput";
import PaymentMethodInput from "./PaymentMethodInput";

interface Props {
  onSuccess?: () => void;
  selectedPlanId: string;
  defaultPaymentMethodId?: string;
}

const SubscribeToPlanForm: React.FC<Props> = ({
  onSuccess,
  selectedPlanId,
  defaultPaymentMethodId,
}) => {
  const [subscribeToPlan, { loading }] = useSubscribeToPlanMutation({
    onCompleted: () => {
      showSuccessNotification("Subscribed to plan!");
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error) => {
      console.error(error);
      showErrorNotification("Failed to subscribe to plan");
    },
  });

  const form = useForm<SubscribeToPlanInput>({
    mode: "uncontrolled",
    initialValues: {
      planId: selectedPlanId,
      paymentMethodId: defaultPaymentMethodId,
    },
  });

  const handleSubmit = (values: SubscribeToPlanInput) => {
    subscribeToPlan({
      variables: {
        input: {
          ...values,
        },
      },
    });
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <SimpleGrid cols={1}>
        <PlanPricingInput required {...form.getInputProps("planId")} />
        <PaymentMethodInput
          required
          {...form.getInputProps("paymentMethodId")}
        />
        <TextInput label="Coupon Code" {...form.getInputProps("couponCode")} />
        <Button type="submit" loading={loading}>
          Subscribe
        </Button>
      </SimpleGrid>
    </form>
  );
};

export default SubscribeToPlanForm;
