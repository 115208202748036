import React from "react";
import moment from "moment";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  NavLink,
  AppShell,
  Burger,
  Button,
  Group,
  rem,
  Text,
  Alert,
  Box,
  Image,
  Avatar,
  Divider,
  useMantineTheme,
  ActionIcon,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconClipboardList,
  IconBallTennis,
  IconCalendar,
  IconClock,
  IconGraph,
  IconHome2,
  IconInfoCircle,
  IconLock,
  IconLogout,
  IconSettings,
  IconUserCog,
  IconUsersGroup,
  IconUser,
} from "@tabler/icons-react";

import { WebsocketProvider } from "../contexts/WebsocketContext";
import { useAuth } from "../contexts/AuthContext";
import { useGetMyAccountQuery } from "../graphql";
import Logo from "../assets/largeColorLogo.png";
import { usePermissions } from "../contexts/PermissionsContext";

const links = [
  {
    link: "/app",
    label: "Home",
    icon: <IconHome2 />,
    permission: undefined,
    roles: [],
  },
  {
    link: "/app/customers",
    label: "Customers",
    icon: <IconUsersGroup />,
    permission: "listCustomers",
    roles: [],
  },
  {
    link: "/app/jobs",
    label: "Jobs",
    icon: <IconClipboardList />,
    permission: "listJobs",
    roles: [],
  },
  {
    link: "/app/employees",
    label: "Employees",
    icon: <IconUserCog />,
    permission: "listEmployees",
    roles: [],
  },
  {
    link: "/app/courts",
    label: "Courts",
    icon: <IconBallTennis />,
    permission: "listCourts",
    roles: [],
  },
  {
    link: "/app/reservations",
    label: "Reservations",
    icon: <IconCalendar />,
    permission: "listReservations",
    roles: [],
  },
  {
    link: "/app/workshifts",
    label: "Time Clock",
    icon: <IconClock />,
    permission: "listWorkShifts",
    roles: [],
  },
  {
    link: "/app/reports",
    label: "Reports",
    icon: <IconGraph />,
    permission: undefined,
    roles: ["ACCOUNT_OWNER", "ACCOUNT_ADMIN"],
  },
  {
    link: "/app/profile",
    label: "My Profile",
    icon: <IconUser />,
    permission: undefined,
    roles: [],
  },
  {
    link: "/app/settings",
    label: "Settings",
    icon: <IconSettings />,
    permission: undefined,
    roles: ["ACCOUNT_OWNER", "ACCOUNT_ADMIN"],
  },
] as const;

const HEADER_HEIGHT = rem(60);
const NAVBAR_WIDTH = rem(200);

const AppLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const [opened, { toggle }] = useDisclosure();
  const [showAlert, setShowAlert] = React.useState(true);
  const permissions = usePermissions();
  const theme = useMantineTheme();

  const myAccount = useGetMyAccountQuery();

  const account = myAccount.data?.myAccount;

  const daysLeftInTrial = account?.trialEndDate
    ? moment(account?.trialEndDate).diff(moment(), "days")
    : undefined;

  const showTrialBanner = account?.status === "TRIAL";

  const trialBannerColor =
    !daysLeftInTrial || daysLeftInTrial > 15
      ? "yellow"
      : daysLeftInTrial > 5
        ? "orange"
        : "red";

  const items = links
    .filter((link) => {
      if (link.permission) return permissions.can(link.permission);
      if (link.roles.length) return permissions.isRole([...link.roles]);
      return true;
    })
    .map((link) => (
      <NavLink
        key={link.link}
        href={link.link}
        leftSection={link.icon}
        mt="md"
        active={location.pathname === link.link}
        color="tsgBlue"
        styles={(theme) => ({
          root: {
            fontWeight: 500,
            color: theme.colors.tsgBlue[7],
            "&[dataActive]": {
              backgroundColor: theme.colors.tsgBlue[0],
              borderLeftColor: theme.colors.tsgBlue[6],
            },
          },
          label: {
            fontSize: "0.9rem",
          },
        })}
        onClick={(event) => {
          event.preventDefault();
          if (opened) {
            toggle();
          }
          navigate(link.link);
        }}
        label={link.label}
      />
    ));

  // Handle authentication redirect in useEffect to avoid state updates during render
  React.useEffect(() => {
    if (!auth.isAuthenticated) {
      // Save the return path and navigate to the login page
      auth.saveReturnPath(`${location.pathname}${location.search}`);
      navigate("/auth/login");
    }
  }, [
    auth.isAuthenticated,
    auth,
    location.pathname,
    location.search,
    navigate,
  ]);

  // If not authenticated, render a loading state
  if (!auth.isAuthenticated) {
    return <></>;
  }

  return (
    <WebsocketProvider>
      <AppShell
        header={{ height: HEADER_HEIGHT }}
        navbar={{
          width: NAVBAR_WIDTH,
          breakpoint: "sm",
          collapsed: { mobile: !opened },
        }}
      >
        <AppShell.Header
          style={{
            backgroundColor: "white",
            boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
            borderBottom: `1px solid ${theme.colors.gray[2]}`,
            zIndex: 100,
          }}
        >
          <Group justify="space-between" h="100%" px="md">
            <Group>
              <Burger
                opened={opened}
                onClick={toggle}
                hiddenFrom="sm"
                size="sm"
                color={theme.colors.tsgBlue[6]}
              />
              <Image
                onClick={() => {
                  navigate("/");
                }}
                w={rem(180)}
                src={Logo}
                alt="Tennis Shop Guru"
                style={{ cursor: "pointer" }}
              />
            </Group>

            {showTrialBanner &&
              daysLeftInTrial &&
              daysLeftInTrial > 0 &&
              showAlert && (
                <Alert
                  icon={<IconInfoCircle size={16} />}
                  color={trialBannerColor}
                  variant="filled"
                  h={40}
                  radius="md"
                  style={{ cursor: "pointer" }}
                  title={
                    <>
                      You have {daysLeftInTrial} days left in trial -{" "}
                      <Box
                        component="span"
                        style={{
                          color: "white",
                          textDecoration: "underline",
                          fontWeight: 600,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/app/settings");
                        }}
                      >
                        Click here
                      </Box>{" "}
                      to select a plan
                    </>
                  }
                  withCloseButton
                  onClose={() => setShowAlert(false)}
                ></Alert>
              )}

            <Group visibleFrom="sm" gap="md">
              <Group gap="xs">
                <Avatar color="tsgBlue" radius="xl">
                  {auth.user?.given_name?.[0]}
                  {auth.user?.family_name?.[0]}
                </Avatar>
                <Box>
                  <Text size="sm" fw={600} lh={1.2} c={theme.colors.tsgBlue[8]}>
                    {auth.user?.given_name} {auth.user?.family_name}
                  </Text>
                  <Text size="xs" c={theme.colors.tsgBlue[6]}>
                    {permissions.isRole(["ACCOUNT_OWNER"])
                      ? "Owner"
                      : permissions.isRole(["ACCOUNT_ADMIN"])
                        ? "Admin"
                        : "Staff"}
                  </Text>
                </Box>
              </Group>
              <Tooltip label="Logout">
                <ActionIcon
                  variant="light"
                  color="tsgBlue"
                  size="lg"
                  onClick={() => auth.logout()}
                  data-test-id="logout-button"
                >
                  <IconLogout size={18} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>
        </AppShell.Header>

        <AppShell.Navbar
          bg="white"
          style={{
            borderRight: `1px solid ${theme.colors.gray[2]}`,
            overflowY: "auto",
          }}
        >
          <AppShell.Section p="md">
            <Box mb="md">
              <Text
                size="xs"
                fw={600}
                c={theme.colors.tsgBlue[7]}
                tt="uppercase"
                mb={6}
              >
                MAIN NAVIGATION
              </Text>
              <Divider />
            </Box>
            {items}
          </AppShell.Section>

          <AppShell.Section p="md">
            <Divider my="sm" />
            <Box hiddenFrom="sm">
              <Group gap="xs" mb="md">
                <Avatar color="tsgBlue" radius="xl" size="sm">
                  {auth.user?.given_name?.[0]}
                  {auth.user?.family_name?.[0]}
                </Avatar>
                <Box>
                  <Text size="sm" fw={600} lh={1.2} c={theme.colors.tsgBlue[8]}>
                    {auth.user?.given_name} {auth.user?.family_name}
                  </Text>
                  <Text size="xs" c={theme.colors.tsgBlue[6]}>
                    {permissions.isRole(["ACCOUNT_OWNER"])
                      ? "Owner"
                      : permissions.isRole(["ACCOUNT_ADMIN"])
                        ? "Admin"
                        : "Staff"}
                  </Text>
                </Box>
              </Group>
              <Button
                variant="light"
                fullWidth
                color="tsgBlue"
                size="xs"
                onClick={() => auth.logout()}
                leftSection={<IconLogout size={14} />}
                data-test-id="logout-button-mobile"
              >
                Logout
              </Button>
            </Box>
            {auth.isAdmin && (
              <>
                <Text
                  size="xs"
                  fw={600}
                  c={theme.colors.tsgBlue[7]}
                  tt="uppercase"
                  mt="xl"
                  mb={6}
                >
                  ADMIN
                </Text>
                <Divider mb="xs" />
                <NavLink
                  href="/app/accounts"
                  leftSection={<IconLock size={16} />}
                  active={location.pathname === "/app/accounts"}
                  color="tsgBlue"
                  styles={(theme) => ({
                    root: {
                      fontWeight: 500,
                      color: theme.colors.tsgBlue[7],
                      "&[dataActive]": {
                        backgroundColor: theme.colors.tsgBlue[0],
                        borderLeftColor: theme.colors.tsgBlue[6],
                      },
                    },
                    label: {
                      fontSize: "0.9rem",
                    },
                  })}
                  onClick={(event) => {
                    event.preventDefault();
                    navigate("/app/accounts");
                  }}
                  label="Accounts"
                />
              </>
            )}
          </AppShell.Section>
        </AppShell.Navbar>

        <AppShell.Main bg="var(--mantine-color-gray-0)">
          <Box
            p="sm"
            mih={`calc(100vh - ${HEADER_HEIGHT})`}
            mah={`calc(100vh - ${HEADER_HEIGHT})`}
            style={{ overflowY: "auto" }}
          >
            <Outlet />
          </Box>
        </AppShell.Main>
      </AppShell>
    </WebsocketProvider>
  );
};

export default AppLayout;
