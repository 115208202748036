import React from "react";
import { Paper, Text, SimpleGrid, Group, Button } from "@mantine/core";
import { useGetWorkShiftQuery } from "../graphql";
import { useNavigate, useParams } from "react-router-dom";
import DateDisplay from "../components/DateDisplay";
import { IconEdit } from "@tabler/icons-react";
import DeleteButton from "../components/DeleteButton";
import { usePermissions } from "../contexts/PermissionsContext";
import moment from "moment";
import StyledPageTitle from "../components/StyledPageTitle";

const WorkShiftsDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const permissions = usePermissions();
  const params = useParams<{ id: string }>();
  const getWorkShift = useGetWorkShiftQuery({
    variables: {
      id: `${params.id}`,
    },
  });

  const workshift = getWorkShift.data?.getWorkShift;
  return (
    <>
      <Group justify="space-between" mb="sm">
        <StyledPageTitle>Work Shift Details</StyledPageTitle>
        <Group>
          <Button
            rightSection={<IconEdit />}
            onClick={() => navigate(`/app/workshifts/${params.id}/update`)}
            display={!permissions.can("updateWorkShift") ? "hidden" : undefined}
          >
            Update
          </Button>
          <DeleteButton
            id={params.id}
            onCompleted={() => navigate("/app/workshifts")}
            type="workshift"
          />
        </Group>
      </Group>
      <Paper>
        <SimpleGrid cols={{ sm: 1, md: 2, lg: 3 }}>
          <Text>
            <strong>Clock In:</strong> <DateDisplay value={workshift?.start} />
          </Text>
          <Text>
            <strong>Clock Out:</strong> <DateDisplay value={workshift?.end} />
          </Text>
          <Text>
            <strong>Duration:</strong>{" "}
            {workshift?.end &&
              workshift?.start &&
              `${moment(workshift?.end).diff(workshift?.start, "hours")}h  ${
                moment(workshift?.end).diff(workshift?.start, "minutes") % 60
              }m`}
          </Text>

          <Text>
            <strong>Status:</strong> {workshift?.status}
          </Text>
          <Text>
            <strong>Employee:</strong> {workshift?.employee?.firstName}{" "}
            {workshift?.employee?.lastName}
          </Text>
          <Text>
            <strong>Updated At:</strong>{" "}
            <DateDisplay value={workshift?.updatedAt} />
          </Text>
          <Text>
            <strong>Created At:</strong>{" "}
            <DateDisplay value={workshift?.createdAt} />
          </Text>
        </SimpleGrid>
      </Paper>
    </>
  );
};

export default WorkShiftsDetailsPage;
