import LogRocket from "logrocket";
import config from "./config";

export interface User {
  sub: string;
  username: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  account_id: string;
  employee_id: string;
  role: string;
}

export interface Token {
  access_token: string;
  refresh_token: string;
  token_type: string;
}

interface ParsedJWT {
  sub: string;
  iss: string;
  exp: number;
  iat: number;
  jti: string;
  username: string;
  email: string;
  account_id?: string;
  employee_id?: string;
  role?: string;
  given_name?: string;
  family_name?: string;
  email_verified?: boolean;
  [key: string]: unknown;
}

const USER_CACHE_KEY = "user";
const TOKEN_CACHE_KEY = "token";
const RETURN_PATH_KEY = "returnPath";
const KEEP_SIGNED_IN_KEY = "keepSignedIn";

const parseJwt = (token: string): ParsedJWT => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export class Auth {
  private _initPromise: Promise<void> | undefined;
  private _parsedToken: ParsedJWT | undefined;
  private _refreshPromise: Promise<boolean> | undefined;

  private async _init() {
    if (this.isAccessTokenExpired()) {
      await this.refreshAccessToken();
    }

    if (this.accessToken && !this.user) {
      console.log("Fetching user info");
      await this.fetchUserInfo();
    }

    if (this.user) {
      console.log("User info:", this.user);
      LogRocket.identify(this.user.sub, {
        name: this.user.given_name + " " + this.user.family_name,
        email: this.user.email,
      });
    }
  }

  /**
   * Initialize the auth object.
   * @returns {Promise<void>}
   */
  async init() {
    if (!this._initPromise) {
      this._initPromise = this._init();
    }
    await this._initPromise;
  }

  /**
   * Get the token data.
   */
  get tokenData(): Token | undefined {
    const token = localStorage.getItem(TOKEN_CACHE_KEY);
    if (token !== null && token !== "null") {
      return JSON.parse(token) as Token;
    }
    const sessionToken = sessionStorage.getItem(TOKEN_CACHE_KEY);
    if (sessionToken !== null && sessionToken !== "null") {
      return JSON.parse(sessionToken) as Token;
    }
    return undefined;
  }

  /**
   * Get the access token.
   */
  get accessToken(): string | undefined {
    return this.tokenData?.access_token;
  }

  /**
   * Get the parsed token.
   */
  get parsedToken(): ParsedJWT | undefined {
    if (this._parsedToken) {
      return this._parsedToken;
    }
    if (this.accessToken) {
      this._parsedToken = parseJwt(this.accessToken);
      return this._parsedToken;
    }
    return undefined;
  }

  /**
   * Verify if the user is an admin.
   */
  get isAdmin() {
    return this.parsedToken?.role === "admin";
  }

  /**
   * Verify if the user is authenticated without initializing the auth object.
   * This is the quick way to check if the user is authenticated.
   */
  get isAuthenticated() {
    return !!this.accessToken;
  }

  /**
   * Verify if the user is authenticated after initializing the auth object.
   * This is the recommended way to check if the user is authenticated.
   */
  async isAuthenticatedAsync() {
    await this.init();
    return this.isAuthenticated;
  }

  /**
   * Verify if the access token is expired.
   */
  isAccessTokenExpired() {
    if (this.parsedToken) {
      const expiresAt = new Date(this.parsedToken.exp * 1000);
      const expiresInSeconds = (expiresAt.getTime() - Date.now()) / 1000;
      console.log("Access token expires in seconds:", expiresInSeconds);
      return expiresAt < new Date(Date.now());
    }
    return true;
  }

  /**
   * Get the user info that is returned from the identity provider.
   */
  get user() {
    const userInfo = localStorage.getItem(USER_CACHE_KEY);
    if (userInfo) {
      return JSON.parse(userInfo) as User;
    }
    const sessionUser = sessionStorage.getItem(USER_CACHE_KEY);
    if (sessionUser) {
      return JSON.parse(sessionUser) as User;
    }
    return undefined;
  }

  /**
   * Get the user id. The user id is from the user info sub field.
   */
  get userId() {
    return this.user?.sub;
  }

  /**
   * Check if the user has chosen to stay signed in
   */
  get keepSignedIn() {
    return localStorage.getItem(KEEP_SIGNED_IN_KEY) === "true";
  }

  /**
   * Set whether to keep the user signed in
   */
  set keepSignedIn(value: boolean) {
    localStorage.setItem(KEEP_SIGNED_IN_KEY, value ? "true" : "false");
  }

  private saveToken(token: Token) {
    // If not keeping signed in, use sessionStorage instead of localStorage
    if (!this.keepSignedIn) {
      sessionStorage.setItem(TOKEN_CACHE_KEY, JSON.stringify(token));
    } else {
      localStorage.setItem(TOKEN_CACHE_KEY, JSON.stringify(token));
    }
    this._parsedToken = parseJwt(token.access_token);
  }

  private saveUserInfo(user: User) {
    // If not keeping signed in, use sessionStorage instead of localStorage
    if (!this.keepSignedIn) {
      sessionStorage.setItem(USER_CACHE_KEY, JSON.stringify(user));
      return;
    }

    localStorage.setItem(USER_CACHE_KEY, JSON.stringify(user));
  }

  /**
   * Get the access token. If the access token is expired, it will refresh the access token.
   */
  async getAccessToken(): Promise<string | undefined> {
    if (this.isAccessTokenExpired()) {
      await this.refreshAccessToken();
    }
    return this.accessToken;
  }

  /**
   * Login the user with email and password.
   */
  async login(
    email: string,
    password: string,
    keepSignedIn: boolean = false
  ): Promise<boolean> {
    try {
      this.keepSignedIn = keepSignedIn;

      const response = await fetch(`${config.API_HOST}/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.detail || "Login failed");
      }

      const data = await response.json();

      this.saveToken({
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        token_type: data.token_type,
      });

      // Parse the token to get user info
      this._parsedToken = parseJwt(data.access_token);

      // Create user object from token data
      const user: User = {
        sub: this._parsedToken.sub,
        username: this._parsedToken.username || this._parsedToken.email,
        email: this._parsedToken.email,
        email_verified: this._parsedToken.email_verified || false,
        family_name: this._parsedToken.family_name || "",
        given_name: this._parsedToken.given_name || "",
        account_id: this._parsedToken.account_id || "",
        employee_id: this._parsedToken.employee_id || "",
        role: this._parsedToken.role || "",
      };

      this.saveUserInfo(user);

      return true;
    } catch (error) {
      console.error("Login error:", error);
      return false;
    }
  }

  /**
   * Logout the user.
   */
  logout() {
    this._parsedToken = undefined;

    if (this.keepSignedIn) {
      localStorage.removeItem(USER_CACHE_KEY);
      localStorage.removeItem(TOKEN_CACHE_KEY);
    } else {
      sessionStorage.removeItem(USER_CACHE_KEY);
      sessionStorage.removeItem(TOKEN_CACHE_KEY);
    }

    // Keep the keepSignedIn preference
    window.location.href = "/auth/logout";
  }

  async _refreshAccessToken(): Promise<boolean> {
    const refreshToken = this.tokenData?.refresh_token;
    if (typeof refreshToken !== "string") {
      return false;
    }

    // Check if the access token is really expired
    if (!this.isAccessTokenExpired()) {
      return true;
    }

    try {
      const response = await fetch(`${config.API_HOST}/auth/refresh`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refresh_token: refreshToken }),
      });

      if (!response.ok) {
        const data = await response.json();
        console.error("Token refresh failed:", data.detail);

        // If the refresh token is invalid, log the user out
        if (response.status === 401) {
          this.logout();
        }

        return false;
      }

      const data = await response.json();

      this.saveToken({
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        token_type: data.token_type,
      });

      return true;
    } catch (error) {
      console.error("Token refresh error:", error);
      return false;
    }
  }

  /**
   * Refresh the access token using the refresh token.
   */
  async refreshAccessToken(): Promise<boolean> {
    if (this._refreshPromise) {
      const response = await this._refreshPromise;
      return response;
    }

    this._refreshPromise = this._refreshAccessToken();
    const response = await this._refreshPromise;
    this._refreshPromise = undefined;
    return response;
  }

  /**
   * Request a password reset for the given email.
   */
  async requestPasswordReset(email: string): Promise<boolean> {
    try {
      const response = await fetch(
        `${config.API_HOST}/auth/request-password-reset`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        }
      );

      // Even if the email doesn't exist, the API returns 200 for security reasons
      return response.ok;
    } catch (error) {
      console.error("Password reset request error:", error);
      return false;
    }
  }

  /**
   * Reset password with token.
   */
  async resetPassword(token: string, newPassword: string): Promise<boolean> {
    try {
      const response = await fetch(`${config.API_HOST}/auth/reset-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token, new_password: newPassword }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.detail || "Password reset failed");
      }

      return true;
    } catch (error) {
      console.error("Password reset error:", error);
      return false;
    }
  }

  /**
   * Change the user's password.
   */
  async changePassword(
    currentPassword: string,
    newPassword: string
  ): Promise<boolean> {
    try {
      const token = await this.getAccessToken();
      if (!token) {
        throw new Error("Not authenticated");
      }

      const response = await fetch(`${config.API_HOST}/auth/change-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          current_password: currentPassword,
          new_password: newPassword,
        }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.detail || "Password change failed");
      }

      return true;
    } catch (error) {
      console.error("Password change error:", error);
      return false;
    }
  }

  /**
   * Verify email with token.
   */
  async verifyEmail(token: string): Promise<boolean> {
    try {
      const response = await fetch(`${config.API_HOST}/auth/verify-email`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.detail || "Email verification failed");
      }

      // If the user is logged in, update the email_verified status
      if (this.user) {
        const user = { ...this.user, email_verified: true };
        this.saveUserInfo(user);
      }

      return true;
    } catch (error) {
      console.error("Email verification error:", error);
      return false;
    }
  }

  /**
   * Fetch user info from the token.
   */
  private async fetchUserInfo(): Promise<User | undefined> {
    try {
      if (!this.accessToken) {
        throw new Error("No access token");
      }

      // Parse the token to get user info
      this._parsedToken = parseJwt(this.accessToken);

      // Create user object from token data
      const user: User = {
        sub: this._parsedToken.sub,
        username: this._parsedToken.username || this._parsedToken.email,
        email: this._parsedToken.email,
        email_verified: this._parsedToken.email_verified || false,
        family_name: this._parsedToken.family_name || "",
        given_name: this._parsedToken.given_name || "",
        account_id: this._parsedToken.account_id || "",
        employee_id: this._parsedToken.employee_id || "",
        role: this._parsedToken.role || "",
      };

      this.saveUserInfo(user);
      return user;
    } catch (error) {
      console.error("Error fetching user info:", error);
      return undefined;
    }
  }

  /**
   * Save the return path for after login.
   */
  saveReturnPath(path: string) {
    localStorage.setItem(RETURN_PATH_KEY, path);
  }

  /**
   * Get the return path for after login.
   */
  getReturnPath(): string {
    const path = localStorage.getItem(RETURN_PATH_KEY) || "/app";
    localStorage.removeItem(RETURN_PATH_KEY);
    return path;
  }
}

export const auth = new Auth();
