import React, { FormEventHandler } from "react";
import { Button, SimpleGrid } from "@mantine/core";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import { StripeAddressElementChangeEvent } from "@stripe/stripe-js";

interface Props {
  returnToUrl: string;
  paymentMethodId?: string;
  cols?: number;
  buttonText?: string;
}

const PaymentMethodForm: React.FC<Props> = ({
  returnToUrl,
  paymentMethodId,
  cols,
  buttonText,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = React.useState(false);
  const [addressState, setAddressState] = React.useState<
    StripeAddressElementChangeEvent | undefined
  >(undefined);

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    try {
      await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: returnToUrl,
          payment_method: paymentMethodId,
          payment_method_data: {
            billing_details: {
              name: addressState?.value.name,
            },
          },
        },
      });
    } catch (error) {
      console.error("Error confirming setup:", error);
    }
    setLoading(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <SimpleGrid cols={{ sm: 1, md: cols || 2 }}>
          <PaymentElement options={{}} id="payment-element" />
          <AddressElement
            options={{ mode: "billing" }}
            id="address-element"
            onChange={setAddressState}
          />
        </SimpleGrid>
        <Button
          id="btn-save-payment-method"
          fullWidth
          mt="md"
          type="submit"
          disabled={!stripe}
          loading={loading}
        >
          {buttonText || "Save"}
        </Button>
      </form>
    </div>
  );
};

export default PaymentMethodForm;
