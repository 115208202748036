/* eslint-disable react-refresh/only-export-components */
import React from "react";
import { Query, Mutation, useGetMeQuery } from "../graphql";
import { useAuth } from "./AuthContext";
import { LoadingOverlay } from "@mantine/core";

type Permission =
  | keyof Query
  | keyof Mutation
  | "listReservations"
  | "getReservation"
  | "createReservation"
  | "updateReservation"
  | "deleteReservation"
  | "listReservationSeries"
  | "getReservationSeries"
  | "createReservationSeries"
  | "updateReservationSeries"
  | "deleteReservationSeries";

interface PermissionsContextState {
  can: (permission: Permission) => boolean;
  isRole: (role: string | string[]) => boolean;
}

const PermissionsContext = React.createContext<PermissionsContextState>({
  can: () => false,
  isRole: () => false,
});

export const PermissionsProvider: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const meResponse = useGetMeQuery();
  const { isAuthenticated, isAdmin } = useAuth();

  const me = meResponse.data?.me;

  React.useEffect(() => {
    if (isAuthenticated && !me && !meResponse.loading) {
      meResponse.refetch();
    }
  }, [isAuthenticated, me, meResponse]);

  const can = (permission: Permission) => {
    if (!me) return false;
    if (isAdmin) return true;
    return me.permissions.includes(permission);
  };

  const isRole = (role: string | string[]) => {
    if (!me) return false;
    if (isAdmin) return true;
    if (Array.isArray(role)) return role.some((r) => me.role === r);
    return me.role === role;
  };

  if (isAuthenticated && !me) {
    return <LoadingOverlay visible />;
  }

  return (
    <PermissionsContext.Provider value={{ can, isRole }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => React.useContext(PermissionsContext);
