import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Paper, Text, SimpleGrid, Group, Button } from "@mantine/core";
import StyledPageTitle from "../components/StyledPageTitle";
import { IconEdit } from "@tabler/icons-react";

import { useGetCourtQuery } from "../graphql";
import DateDisplay from "../components/DateDisplay";
import DeleteButton from "../components/DeleteButton";
import { usePermissions } from "../contexts/PermissionsContext";

const CourtsDetailsPage: React.FC = () => {
  const permissions = usePermissions();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const getCourt = useGetCourtQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: `${params.id}`,
    },
  });

  const court = getCourt.data?.getCourt;

  return (
    <>
      <Group justify="space-between" mb="sm">
        <StyledPageTitle>Court Details</StyledPageTitle>
        <Group>
          <Button
            rightSection={<IconEdit />}
            display={!permissions.can("updateCourt") ? "hidden" : undefined}
            onClick={() => navigate(`/app/courts/${params.id}/update`)}
          >
            Update
          </Button>
          <DeleteButton
            id={params.id}
            onCompleted={() => navigate("/app/courts")}
            type="court"
          />
        </Group>
      </Group>
      <Paper>
        <SimpleGrid cols={{ sm: 1, md: 2, lg: 3 }}>
          <Text>
            <strong>Name:</strong> {court?.name}
          </Text>
          <Text>
            <strong>Surface:</strong> {court?.surface}
          </Text>
          <Text>
            <strong>Coverage:</strong> {court?.coverage}
          </Text>
          <Text>
            <strong>Color:</strong> {court?.color}
          </Text>
          <Text>
            <strong>Location X:</strong> {court?.location.x}
          </Text>
          <Text>
            <strong>Location Y:</strong> {court?.location.y}
          </Text>
          <Text>
            <strong>Rotation:</strong> {court?.location.rotation}°
          </Text>
          <Text>
            <strong>Updated At:</strong>{" "}
            <DateDisplay value={court?.updatedAt} />
          </Text>
          <Text>
            <strong>Created At:</strong>{" "}
            <DateDisplay value={court?.createdAt} />
          </Text>
        </SimpleGrid>
      </Paper>
    </>
  );
};

export default CourtsDetailsPage;
