import { modals } from "@mantine/modals";

import SubscribeToPlanForm from "../components/SubscribeToPlanForm";

interface Args {
  onCancel?: () => void | Promise<void>;
}

function useSubscribeToPlanModal(args: Args = {}) {
  const openSubscribeToPlanModal = (
    planId: string,
    defaultPaymentMethodId?: string
  ) => {
    const modalId = `subscribe-to-plan-${planId}`;

    modals.open({
      title: "Subscribe to Plan",
      size: "lg",
      id: modalId,
      children: (
        <SubscribeToPlanForm
          selectedPlanId={planId}
          defaultPaymentMethodId={defaultPaymentMethodId}
          onSuccess={() => modals.close(modalId)}
        />
      ),
      onClose: args.onCancel,
    });
  };

  return { openSubscribeToPlanModal };
}

export default useSubscribeToPlanModal;
