import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import {
  Title,
  Text,
  Box,
  Divider,
  useMantineTheme,
  Alert,
  Stepper,
  SimpleGrid,
  Card,
  Badge,
  Group,
  Button,
  List,
  ThemeIcon,
  rem,
  Anchor,
} from "@mantine/core";
import {
  IconAlertCircle,
  IconCheck,
  IconCircleCheck,
} from "@tabler/icons-react";

import { SignUpResult, useListPlansQuery } from "../../graphql";
import SignUpForm from "../../components/SignUpForm";
import PaymentMethodForm from "../../components/PaymentMethodForm";
import config from "../../utils/config";
import { useLocalStorage } from "@mantine/hooks";
import CurrencyDisplay from "../../components/CurrencyDisplay";

const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

const SignUpPage: React.FC = () => {
  const theme = useMantineTheme();
  const plansResponse = useListPlansQuery();
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedPriceId, setSelectedPriceId] = React.useState<string>("");
  const [signUpResult, setSignUpResult] = useLocalStorage<
    SignUpResult | undefined
  >({
    key: "signUpResult",
    defaultValue: undefined,
  });

  const plans = plansResponse.data?.listPlans || [];

  React.useEffect(() => {
    if (signUpResult && activeStep === 0) {
      setActiveStep(1);
    }
  }, [signUpResult, activeStep]);

  const handleSignUpSuccess = (result: SignUpResult) => {
    setSignUpResult(result);
    setActiveStep(1);
  };

  const handlePlanSelect = (priceId: string) => {
    setSelectedPriceId(priceId);
  };

  const handleSubmitPlan = () => {
    if (selectedPriceId) {
      setActiveStep(2);
    }
  };

  // Type definition for plan metadata
  interface PlanMetadata {
    popular?: boolean;
    [key: string]: unknown;
  }

  return (
    <Box>
      <Box>
        <Title order={1} c={theme.colors.tsgBlue[7]}>
          Join Tennis Shop Guru
        </Title>
        <Text size="lg" fw={500} c="dimmed">
          Streamline your tennis shop operations today!
        </Text>
      </Box>

      <Divider my="md" />
      <Stepper active={activeStep} mt="xl" mb="xl">
        <Stepper.Step
          label="Account Information"
          description="Create your account"
        >
          <SignUpForm onSuccess={handleSignUpSuccess} />
        </Stepper.Step>

        <Stepper.Step
          label="Select a Plan"
          description="Choose your subscription"
        >
          <Box mt="xl">
            <Text fw={500} size="sm" mb="xs">
              Select a Plan for Your 30-Day Trial
            </Text>
            <SimpleGrid
              cols={{
                base: 1,
                sm: plans.length > 1 ? 2 : 1,
                md: plans.length > 2 ? 3 : plans.length,
              }}
              spacing="lg"
            >
              {plans.map((plan) => (
                <Card
                  key={plan.id}
                  shadow="sm"
                  padding="lg"
                  radius="md"
                  withBorder
                >
                  <Card.Section withBorder inheritPadding py="xs">
                    <Group justify="space-between">
                      <Text fw={700} size="lg" c={theme.colors.tsgBlue[7]}>
                        {plan.name}
                      </Text>
                      {(plan.metadata as PlanMetadata)?.popular && (
                        <Badge color={theme.colors.tsgBlue[7]} variant="filled">
                          Popular
                        </Badge>
                      )}
                    </Group>
                  </Card.Section>

                  <Text size="sm" c="dimmed" mt="md" mb="md">
                    {plan.description}
                  </Text>

                  <List
                    spacing="xs"
                    size="sm"
                    center
                    icon={
                      <ThemeIcon
                        color={theme.colors.tsgBlue[7]}
                        size={24}
                        radius="xl"
                      >
                        <IconCheck
                          style={{ width: rem(16), height: rem(16) }}
                        />
                      </ThemeIcon>
                    }
                    mb="xl"
                  >
                    {plan.features.map((feature) => (
                      <List.Item key={feature.id}>
                        <Text size="sm">
                          {feature.shortDescription || feature.name}
                        </Text>
                      </List.Item>
                    ))}
                  </List>

                  <Card.Section withBorder inheritPadding py="md" mt="auto">
                    {plan.prices.map((price) => (
                      <Box key={price.id} mb={8}>
                        <Group justify="space-between" mb={4}>
                          <Text fw={700} size="xl">
                            <CurrencyDisplay
                              amount={price.amount}
                              currency={price.currency}
                            />
                          </Text>
                          <Text size="sm" c="dimmed">
                            /{price.billingInterval}
                          </Text>
                        </Group>
                        <Button
                          fullWidth
                          color={theme.colors.tsgBlue[7]}
                          onClick={() => handlePlanSelect(price.id)}
                          leftSection={
                            selectedPriceId === price.id ? (
                              <IconCircleCheck size={20} />
                            ) : null
                          }
                          variant={
                            selectedPriceId === price.id ? "filled" : "outline"
                          }
                        >
                          {selectedPriceId === price.id
                            ? "Selected"
                            : "Select Plan"}
                        </Button>
                      </Box>
                    ))}
                  </Card.Section>
                </Card>
              ))}
            </SimpleGrid>

            {plans.length === 0 && (
              <Alert
                icon={<IconAlertCircle size={16} />}
                title="No Plans Available"
                color="red"
              >
                There are currently no subscription plans available. Please try
                again later.
              </Alert>
            )}
          </Box>
          <Button
            mt="xl"
            fullWidth
            color={theme.colors.tsgBlue[7]}
            onClick={handleSubmitPlan}
            disabled={!selectedPriceId}
          >
            {selectedPriceId ? "Continue to Billing" : "Select a Plan"}
          </Button>
        </Stepper.Step>
        <Stepper.Step
          label="Setup Billing"
          description="Enter your payment details"
        >
          <Box mt="xl">
            <Text fw={500} size="sm" mb="xs">
              Enter Your Payment Information
            </Text>
            {signUpResult && (
              <Elements
                options={{ clientSecret: signUpResult.billingSecret }}
                stripe={stripePromise}
              >
                <PaymentMethodForm
                  cols={1}
                  returnToUrl={`${window.location.origin}/signup/success?accountId=${signUpResult.accountId}&planId=${selectedPriceId}`}
                />
              </Elements>
            )}
          </Box>
        </Stepper.Step>
      </Stepper>
      <Text ta="center" size="sm" c="dimmed">
        By signing up, you agree to our{" "}
        <Link
          to="/terms"
          style={{ color: theme.colors.tsgBlue[7], textDecoration: "none" }}
        >
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link
          to="/privacy"
          style={{ color: theme.colors.tsgBlue[7], textDecoration: "none" }}
        >
          Privacy Policy
        </Link>
      </Text>
      <Text ta="center" size="sm" c="dimmed">
        Need to start over?{" "}
        <Anchor
          onClick={() => {
            setActiveStep(0);
            setSignUpResult(undefined);
          }}
        >
          Click here to reset
        </Anchor>
      </Text>
    </Box>
  );
};

export default SignUpPage;
