import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CloseButton,
  Group,
  Pagination,
  Paper,
  Skeleton,
  Table,
  TextInput,
  rem,
} from "@mantine/core";
import StyledPageTitle from "../components/StyledPageTitle";
import { IconPlus, IconSearch } from "@tabler/icons-react";
import { JobFilter, useListJobsQuery } from "../graphql";
import DateDisplay from "../components/DateDisplay";
import useListVariables from "../hooks/useListVariables";
import TableHeaderCell from "../components/TableHeaderCell";
import { usePermissions } from "../contexts/PermissionsContext";

const headers = [
  { label: "Job Name", field: "name" },
  { label: "Customer Name" },
  { label: "String", field: "stringName" },
  { label: "Racket", field: "racket" },
  { label: "Tension", field: "tension" },
  { label: "Gauge", field: "gauge" },
  { label: "Status", field: "status" },
  { label: "Recieved At", field: "receivedAt" },
  { label: "Finished At", field: "finishedAt" },
  { label: "Finished By" },
  // { label: "Created At", field: "createdAt" },
  // { label: "Updated At", field: "updatedAt" },
];

const JobsPage: React.FC = () => {
  const navigate = useNavigate();
  const permissions = usePermissions();
  const listVariables = useListVariables<JobFilter>({
    defaultSort: { field: "updatedAt", order: "DESC" },
  });

  const jobs = useListJobsQuery({
    variables: {
      pagination: listVariables.pagination,
      sort: listVariables.sort,
      filter: listVariables.filter,
    },
  });

  return (
    <>
      <Group justify="space-between" mb="sm">
        <StyledPageTitle>Jobs</StyledPageTitle>
        <Group gap="sm">
          <TextInput
            w={300}
            leftSection={<IconSearch />}
            placeholder="Search"
            value={listVariables.filter?.q || ""}
            onChange={(e) =>
              listVariables.setFilter({
                q: e.currentTarget.value || undefined,
              })
            }
            rightSection={
              listVariables.filter?.q && (
                <CloseButton
                  onClick={() => listVariables.setFilter({ q: undefined })}
                />
              )
            }
          />
          <Button
            rightSection={<IconPlus />}
            onClick={() => navigate("/app/jobs/create")}
            display={!permissions.can("createJob") ? "hidden" : undefined}
          >
            Create
          </Button>
        </Group>
      </Group>
      <Paper w="100%">
        <Table.ScrollContainer minWidth={500}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                {headers.map((header) => (
                  <TableHeaderCell
                    key={header.label}
                    label={header.label}
                    field={header.field}
                    vars={listVariables}
                  />
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {jobs.data?.listJobs.data.map((job) => (
                <Table.Tr
                  key={job.id}
                  data-testid="job-table-row"
                  onClick={() => navigate(`/app/jobs/${job.id}`)}
                  style={{ cursor: "pointer" }}
                >
                  <Table.Td>{job.name}</Table.Td>
                  <Table.Td>
                    {job.customer?.firstName} {job.customer?.lastName}
                  </Table.Td>
                  <Table.Td>{job.stringName}</Table.Td>
                  <Table.Td>{job.racket}</Table.Td>
                  <Table.Td>{job.tension}</Table.Td>
                  <Table.Td>{job.gauge}</Table.Td>
                  <Table.Td>{job.status}</Table.Td>
                  <Table.Td>
                    <DateDisplay value={job.receivedAt} />
                  </Table.Td>
                  <Table.Td>
                    <DateDisplay value={job.finishedAt} />
                  </Table.Td>
                  {/* <Table.Td>
                    <DateDisplay value={job.createdAt} />
                  </Table.Td>
                  <Table.Td>
                    <DateDisplay value={job.updatedAt} />
                  </Table.Td> */}
                  <Table.Td>
                    {job.finishedBy?.firstName} {job.finishedBy?.lastName}
                  </Table.Td>
                </Table.Tr>
              ))}
              {jobs.loading &&
                Array.from({ length: listVariables.pagination.perPage }).map(
                  (_, index) => (
                    <Table.Tr key={index}>
                      {Array.from({ length: 10 }).map((_, index) => (
                        <Table.Td key={index}>
                          <Skeleton height={22} radius="none" />
                        </Table.Td>
                      ))}
                    </Table.Tr>
                  )
                )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
        <Group justify="space-evenly">
          <Pagination
            hidden={
              !jobs.data?.listJobs.total ||
              jobs.data?.listJobs.total < listVariables.pagination.perPage
            }
            mt={rem(20)}
            value={listVariables.pagination.page}
            onChange={listVariables.setPage}
            total={
              (jobs.data?.listJobs.total || 0) /
              listVariables.pagination.perPage
            }
          />
        </Group>
      </Paper>
    </>
  );
};

export default JobsPage;
