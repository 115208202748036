import React from "react";
import {
  Button,
  SimpleGrid,
  TextInput,
  Textarea,
  Divider,
  Box,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { AccountFragment, useSaveAccountSettingsMutation } from "../graphql";
import { showSuccessNotification } from "../utils/showNotification";
import BusinessHoursSettings, { BusinessHour } from "./BusinessHoursSettings";
import {
  fromGraphQLFormat,
  toGraphQLFormat,
} from "../utils/businessHoursAdapter";

interface FormData {
  name: string;
  phone: string;
  jobFinishedSmsTemplate: string;
  businessHours: BusinessHour[];
}

interface Props {
  account: AccountFragment;
  onComplete?: () => void;
}

const AccountSettingsForm: React.FC<Props> = ({ account, onComplete }) => {
  const form = useForm<FormData>({
    initialValues: {
      name: account.name,
      phone: account.phone,
      jobFinishedSmsTemplate: account.jobFinishedSmsTemplate || "",
      businessHours: fromGraphQLFormat(account.businessHours),
    },
  });

  const [saveAccountSettings, saveAccountSettingsResponse] =
    useSaveAccountSettingsMutation({
      onCompleted: () => {
        showSuccessNotification("Account settings saved successfully");
        onComplete?.();
      },
    });

  const handleSubmit = (data: FormData) => {
    saveAccountSettings({
      variables: {
        input: {
          name: data.name,
          phone: data.phone,
          jobFinishedSmsTemplate: data.jobFinishedSmsTemplate,
          businessHours: toGraphQLFormat(data.businessHours),
        },
      },
    });
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <SimpleGrid>
        <TextInput
          label="Company Name"
          required
          {...form.getInputProps("name")}
        />
        <TextInput
          label="Phone Number"
          required
          {...form.getInputProps("phone")}
        />
        <Textarea
          label="Customer Job Notification Message"
          required
          {...form.getInputProps("jobFinishedSmsTemplate")}
        />
      </SimpleGrid>

      <Divider my="lg" />

      <Box mb="lg">
        <BusinessHoursSettings
          value={form.values.businessHours}
          onChange={(hours) => form.setFieldValue("businessHours", hours)}
        />
      </Box>

      <Button
        mt="lg"
        type="submit"
        loading={saveAccountSettingsResponse.loading}
        color="tsgGreen.6"
      >
        Save Settings
      </Button>
    </form>
  );
};

export default AccountSettingsForm;
