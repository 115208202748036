import React from "react";
import {
  Container,
  Text,
  Paper,
  List,
  Box,
  Divider,
  Group,
} from "@mantine/core";
import { useMantineTheme } from "@mantine/core";
import StyledPageTitle from "../../components/StyledPageTitle";
import StyledSectionTitle from "../../components/StyledSectionTitle";

const TermsPage: React.FC = () => {
  const theme = useMantineTheme();

  return (
    <Container size="lg" py="xl">
      <StyledPageTitle mb="lg">Terms of Service</StyledPageTitle>

      <Paper withBorder shadow="md" p="xl" radius="md" mb="lg">
        <Text mb="md">Last Updated: March 2, 2025</Text>

        <Text mb="lg">
          Welcome to TennisShopGuru. These Terms of Service ("Terms") govern
          your use of our website and cloud-based tennis shop management
          software (collectively, the "Service") operated by TennisShopGuru,
          Inc.
        </Text>

        <Text mb="lg">
          By accessing or using the Service, you agree to be bound by these
          Terms. If you disagree with any part of the terms, you may not access
          the Service.
        </Text>

        <StyledSectionTitle mb="md">1. Accounts</StyledSectionTitle>
        <Text mb="md">
          When you create an account with us, you must provide accurate,
          complete, and current information. Failure to do so constitutes a
          breach of the Terms, which may result in immediate termination of your
          account.
        </Text>
        <Text mb="lg">
          You are responsible for safeguarding the password and for all
          activities that occur under your account. You agree to immediately
          notify TennisShopGuru of any unauthorized use of your account or any
          other breach of security.
        </Text>

        <StyledSectionTitle mb="md">
          2. Subscription and Payments
        </StyledSectionTitle>
        <Text mb="md">
          TennisShopGuru offers subscription-based services. By selecting a
          subscription plan, you agree to pay the subscription fees indicated
          for that service.
        </Text>
        <Text mb="lg">
          Payments are processed securely through our payment partners. All
          subscription fees are non-refundable except as expressly set forth in
          our Refund Policy.
        </Text>

        <StyledSectionTitle mb="md">3. User Content</StyledSectionTitle>
        <Text mb="md">
          Our Service allows you to post, store, and share content, including
          customer data, employee information, and shop management data ("User
          Content").
        </Text>
        <Text mb="lg">
          You retain all rights to your User Content, but grant TennisShopGuru a
          license to host, store, and display this content to provide the
          Service. You are solely responsible for the accuracy and legality of
          your User Content.
        </Text>

        <StyledSectionTitle mb="md">
          4. Prohibited Activities
        </StyledSectionTitle>
        <Text mb="md">When using our Service, you agree not to:</Text>
        <List spacing="xs" mb="lg" type="ordered">
          <List.Item>
            Use the Service in any way that could disable, overburden, or impair
            the Service
          </List.Item>
          <List.Item>
            Use any robot, spider, or other automated device to access the
            Service
          </List.Item>
          <List.Item>
            Transmit any viruses, malware, or other types of malicious code
          </List.Item>
          <List.Item>
            Attempt to gain unauthorized access to any portion of the Service
          </List.Item>
          <List.Item>
            Use the Service for any illegal or unauthorized purpose
          </List.Item>
          <List.Item>
            Infringe upon or violate our intellectual property rights or the
            rights of others
          </List.Item>
        </List>

        <StyledSectionTitle mb="md">5. Data Privacy</StyledSectionTitle>
        <Text mb="lg">
          We take data privacy seriously. Our use of your personal information
          is governed by our Privacy Policy. By using the Service, you consent
          to our collection and use of data as described in the Privacy Policy.
        </Text>

        <StyledSectionTitle mb="md">
          6. Service Availability and Support
        </StyledSectionTitle>
        <Text mb="lg">
          While we strive to provide a reliable service, we cannot guarantee
          that the Service will be available at all times. We may occasionally
          need to perform maintenance, and we will make reasonable efforts to
          notify users in advance of planned downtime.
        </Text>

        <StyledSectionTitle mb="md">7. Termination</StyledSectionTitle>
        <Text mb="lg">
          We may terminate or suspend your account immediately, without prior
          notice or liability, for any reason, including without limitation if
          you breach the Terms. Upon termination, your right to use the Service
          will immediately cease.
        </Text>

        <StyledSectionTitle mb="md">
          8. Limitation of Liability
        </StyledSectionTitle>
        <Text mb="lg">
          In no event shall TennisShopGuru, its directors, employees, partners,
          agents, suppliers, or affiliates, be liable for any indirect,
          incidental, special, consequential or punitive damages, including
          without limitation, loss of profits, data, or other intangible losses,
          resulting from your use of the Service.
        </Text>

        <StyledSectionTitle mb="md">9. Changes to Terms</StyledSectionTitle>
        <Text mb="lg">
          We reserve the right to modify or replace these Terms at any time.
          Material changes will be notified to users at least 14 days before
          they take effect. By continuing to access or use our Service after
          those revisions become effective, you agree to be bound by the revised
          terms.
        </Text>

        <StyledSectionTitle mb="md">10. Contact Us</StyledSectionTitle>
        <Text mb="lg">
          If you have any questions about these Terms, please contact us at:
          <Box
            mt="xs"
            style={{
              color: theme.colors.tsgBlue[7],
              fontWeight: 600,
            }}
          >
            support@tennisshopguru.com
          </Box>
        </Text>

        <Divider my="lg" />

        <Group justify="center">
          <Text size="sm" c="dimmed">
            © {new Date().getFullYear()} TennisShopGuru, Inc. All rights
            reserved.
          </Text>
        </Group>
      </Paper>
    </Container>
  );
};

export default TermsPage;
