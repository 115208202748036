import React from "react";
import {
  Container,
  Text,
  Paper,
  List,
  Box,
  Divider,
  Group,
} from "@mantine/core";
import { useMantineTheme } from "@mantine/core";
import StyledPageTitle from "../../components/StyledPageTitle";
import StyledSectionTitle from "../../components/StyledSectionTitle";

const PrivacyPolicyPage: React.FC = () => {
  const theme = useMantineTheme();

  return (
    <Container size="lg" py="xl">
      <StyledPageTitle mb="lg">Privacy Policy</StyledPageTitle>

      <Paper withBorder shadow="md" p="xl" radius="md" mb="lg">
        <Text mb="md">Last Updated: March 2, 2025</Text>

        <Text mb="lg">
          TennisShopGuru ("we", "our", or "us") is committed to protecting your
          privacy. This Privacy Policy explains how we collect, use, disclose,
          and safeguard your information when you use our website and
          cloud-based tennis shop management software (collectively, the
          "Service").
        </Text>

        <Text mb="lg">
          Please read this privacy policy carefully. By accessing or using our
          Service, you acknowledge that you have read, understood, and agree to
          be bound by all the terms outlined in this privacy policy. If you do
          not agree, please discontinue use of our Service immediately.
        </Text>

        <StyledSectionTitle mb="md">
          1. Information We Collect
        </StyledSectionTitle>
        <Text mb="md">
          We collect information that you provide directly to us, information we
          collect automatically when you use the Service, and information from
          third-party sources.
        </Text>
        <List spacing="xs" mb="md">
          <List.Item>
            <strong>Personal Information:</strong> Name, email address,
            telephone number, company information, billing details, and any
            other information you provide when creating an account or using our
            services.
          </List.Item>
          <List.Item>
            <strong>Customer Data:</strong> Information about your customers
            that you input into the system, including names, contact
            information, service histories, and preferences.
          </List.Item>
          <List.Item>
            <strong>Employee Data:</strong> Information about your employees
            that you input, including schedules, work shifts, and performance
            data.
          </List.Item>
          <List.Item>
            <strong>Usage Data:</strong> Information about how you and your
            authorized users access and use the Service, including browser type,
            IP address, pages viewed, time spent on pages, links clicked, and
            conversion information.
          </List.Item>
        </List>
        <Text mb="lg">
          We use cookies and similar tracking technologies to track activity on
          our Service and hold certain information. You can instruct your
          browser to refuse all cookies or to indicate when a cookie is being
          sent.
        </Text>

        <StyledSectionTitle mb="md">
          2. How We Use Your Information
        </StyledSectionTitle>
        <Text mb="md">
          We use the information we collect for various purposes, including:
        </Text>
        <List spacing="xs" mb="lg">
          <List.Item>To provide, maintain, and improve our Service</List.Item>
          <List.Item>
            To process and complete transactions and send related information
          </List.Item>
          <List.Item>
            To send administrative messages like confirmations, technical
            notices, updates, and security alerts
          </List.Item>
          <List.Item>
            To respond to your comments, questions, and requests
          </List.Item>
          <List.Item>To provide customer support and assistance</List.Item>
          <List.Item>
            To communicate with you about products, services, offers, and events
          </List.Item>
          <List.Item>
            To monitor and analyze trends, usage, and activities in connection
            with our Service
          </List.Item>
          <List.Item>
            To detect, investigate, and prevent fraudulent transactions and
            other illegal activities
          </List.Item>
          <List.Item>
            To personalize and improve the Service and provide content or
            features that match user profiles or interests
          </List.Item>
        </List>

        <StyledSectionTitle mb="md">
          3. Data Storage and Security
        </StyledSectionTitle>
        <Text mb="lg">
          We implement appropriate security measures to protect the security of
          your information. However, please be aware that no security measures
          are perfect or impenetrable. We cannot guarantee that your information
          will not be accessed, viewed, disclosed, altered, or destroyed by
          breach of any of our physical, technical, or managerial safeguards.
        </Text>

        <StyledSectionTitle mb="md">4. Data Retention</StyledSectionTitle>
        <Text mb="lg">
          We will retain your information for as long as your account is active
          or as needed to provide you with our services. We will retain and use
          your information as necessary to comply with our legal obligations,
          resolve disputes, and enforce our agreements. Upon request, we will
          delete or anonymize your information so that it no longer identifies
          you, unless we are legally required to retain certain information.
        </Text>

        <StyledSectionTitle mb="md">
          5. Disclosure of Your Information
        </StyledSectionTitle>
        <Text mb="md">
          We may share information about you in the following situations:
        </Text>
        <List spacing="xs" mb="lg">
          <List.Item>
            <strong>With Service Providers:</strong> We may share your
            information with third-party vendors, consultants, and other service
            providers who need access to such information to carry out work on
            our behalf.
          </List.Item>
          <List.Item>
            <strong>For Business Transfers:</strong> If we are involved in a
            merger, acquisition, or sale of all or a portion of our assets, your
            information may be transferred as part of that transaction.
          </List.Item>
          <List.Item>
            <strong>For Legal Purposes:</strong> We may disclose your
            information if we believe that such action is necessary to comply
            with the law, protect our rights, or respond to a legal request.
          </List.Item>
          <List.Item>
            <strong>With Your Consent:</strong> We may share your information
            with third parties when we have your consent to do so.
          </List.Item>
        </List>

        <StyledSectionTitle mb="md">
          6. Your Rights and Choices
        </StyledSectionTitle>
        <Text mb="lg">
          You have certain rights regarding your personal information. You may
          access, update, or delete your information by contacting us directly.
          You may also opt out of receiving promotional communications from us
          by following the unsubscribe instructions in such communications or by
          contacting us.
        </Text>

        <StyledSectionTitle mb="md">7. Children's Privacy</StyledSectionTitle>
        <Text mb="lg">
          Our Service is not directed to children under the age of 13. We do not
          knowingly collect personally identifiable information from children
          under 13. If you are a parent or guardian and you believe your child
          has provided us with personal information, please contact us so that
          we can take appropriate action.
        </Text>

        <StyledSectionTitle mb="md">
          8. International Data Transfers
        </StyledSectionTitle>
        <Text mb="lg">
          Your information may be transferred to, and maintained on, computers
          located outside of your state, province, country, or other
          governmental jurisdiction where data protection laws may differ. If
          you are located outside the United States and choose to provide
          information to us, please note that we transfer the information to the
          United States and process it there.
        </Text>

        <StyledSectionTitle mb="md">
          9. Changes to This Privacy Policy
        </StyledSectionTitle>
        <Text mb="lg">
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page and
          updating the "Last Updated" date. You are advised to review this
          Privacy Policy periodically for any changes. Changes to this Privacy
          Policy are effective when they are posted on this page.
        </Text>

        <StyledSectionTitle mb="md">10. Contact Us</StyledSectionTitle>
        <Text mb="lg">
          If you have any questions about this Privacy Policy, please contact us
          at:
          <Box
            mt="xs"
            style={{
              color: theme.colors.tsgBlue[7],
              fontWeight: 600,
            }}
          >
            privacy@tennisshopguru.com
          </Box>
        </Text>

        <Divider my="lg" />

        <Group justify="center">
          <Text size="sm" c="dimmed">
            © {new Date().getFullYear()} TennisShopGuru, Inc. All rights
            reserved.
          </Text>
        </Group>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicyPage;
