import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Title, Text, Stack, ThemeIcon, Group } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useAuth } from "../../contexts/AuthContext";

const LogoutPage: React.FC = () => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated) {
      auth.logout();
    }
  }, [auth]);

  return (
    <>
      <Title ta="center" fw={900} mb="lg">
        You've been logged out
      </Title>

      <Stack align="center" gap="md">
        <ThemeIcon color="green" size="xl" radius="xl">
          <IconCheck size="1.5rem" />
        </ThemeIcon>

        <Text size="sm" c="dimmed" ta="center">
          Thank you for using Tennis Shop Guru. You have been successfully
          logged out.
        </Text>

        <Group>
          <Button
            component={Link}
            to="/"
            variant="outline"
            data-test-id="homepage-button"
          >
            Go to homepage
          </Button>
          <Button component={Link} to="/auth/login" data-test-id="login-button">
            Log in again
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default LogoutPage;
