import {
  ActionIcon,
  Box,
  // Button,
  Center,
  Group,
  LoadingOverlay,
  Menu,
  Paper,
  Text,
} from "@mantine/core";
import {
  IconBrandMastercard,
  IconBrandVisa,
  IconCreditCard,
  IconDotsVertical,
} from "@tabler/icons-react";
import React from "react";
import {
  useRemovePaymentMethodMutation,
  useSetDefaultPaymentMethodMutation,
} from "../graphql";

interface Props {
  id: string;
  card?: {
    brand: string;
    lastFour: string;
    expMonth: number;
    expYear: number;
  } | null;
  isOnlyPaymentMethod?: boolean;
  isDefault?: boolean;
  showUpdateButton?: boolean;
}

const PaymentMethodDisplay: React.FC<Props> = ({
  id,
  isDefault,
  isOnlyPaymentMethod,
  card,
}) => {
  const { brand = "", lastFour, expMonth, expYear } = card || {};
  const [removePaymentMethod, removeResponse] = useRemovePaymentMethodMutation({
    refetchQueries: ["GetMyAccountBillingInfo"],
  });
  const [setDefaultPaymentMethod, setDefaultResponse] =
    useSetDefaultPaymentMethodMutation({
      refetchQueries: ["GetMyAccountBillingInfo"],
    });
  const iconSize = 30;
  let icon = <IconCreditCard size={iconSize} />;

  if (brand === "visa") {
    icon = <IconBrandVisa size={iconSize} />;
  } else if (brand === "mastercard") {
    icon = <IconBrandMastercard size={iconSize} />;
  }

  const loading = removeResponse.loading || setDefaultResponse.loading;

  const prettyBrand = brand.charAt(0).toUpperCase() + brand.slice(1);
  return (
    <Paper p="xs" pos="relative">
      <LoadingOverlay visible={loading} />
      <Group justify="space-between">
        <Group>
          <Box mr="md">
            <Center>{icon}</Center>
          </Box>
          <Text>{prettyBrand}</Text>
          <Text>•••• {lastFour}</Text>
          <Text>
            {expMonth}/{expYear}
          </Text>
          {isDefault && (
            <Text>
              <strong>Default</strong>
            </Text>
          )}
        </Group>
        <Menu trigger="hover">
          <Menu.Target>
            <ActionIcon variant="subtle">
              <IconDotsVertical size={20} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              disabled={isDefault}
              onClick={() =>
                setDefaultPaymentMethod({ variables: { paymentMethodId: id } })
              }
            >
              Set as default
            </Menu.Item>
            <Menu.Item
              disabled={isOnlyPaymentMethod}
              onClick={() =>
                removePaymentMethod({ variables: { paymentMethodId: id } })
              }
            >
              Remove
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </Paper>
  );
};

export default PaymentMethodDisplay;
